import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.colors.primary,
    color: theme.colors.text.fifth,
  },
  listWrapper: {
    overflow: 'auto',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export {
  useStyles,
};