import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../../constants/routes';
import { SummaryPartsView } from '../../../containers/SummaryPartsView';
import { SummaryProductInfoView } from '../../../containers/SummaryProductInfoView';
import { SummaryPeopleView } from '../../../containers/SummaryPeopleView';
import { SummaryTipsView } from '../../../containers/SummaryTipsView';
import { SummaryConstructionTime } from '../../../containers/SummaryConstructionTime';
import { SummaryToolsView } from '../../../containers/SummaryToolsView';

const getRoutePath = (path) => `${UI_ROUTES.summary}${path}`;

const nestedRoutes = [
  {
    path: UI_ROUTES.summary,
    exact: true,
    components: SummaryProductInfoView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.parts),
    exact: true,
    components: SummaryPartsView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.tools),
    exact: true,
    components: SummaryToolsView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.people),
    exact: true,
    components: SummaryPeopleView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.constructionTime),
    exact: true,
    components: SummaryConstructionTime,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.tips),
    exact: true,
    components: SummaryTipsView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.updates),
    exact: true,
    components: SummaryProductInfoView,
  },
];

export { nestedRoutes };