import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  mainContent: {
    position: 'relative',
    minHeight: '100%',
    width: props => props.isFullscreen ? '100%' : '72.8%',
    background: '#FFF',
    borderRadius: '20px',
    display: props => props.isZoomView ? 'none' : 'block',
  },
});

export {
  useStyles,
};