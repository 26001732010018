import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

const MediaButton = ({ icon: Icon, viewBox, onClick, disabled, ...rest }) => {
  const classes = useStyles();

  return (
    <IconButton
      data-testid='media-button'
      onClick={onClick}
      classes={{ root: classes.button, disabled: classes.disabled }}
      disableRipple
      disableFocusRipple
      disabled={disabled}
      {...rest}
    >
      <SvgIcon component={Icon} viewBox={viewBox} classes={{ root: classes.icon }} />
    </IconButton>
  );
};

MediaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
  disabled: PropTypes.bool,
  viewBox: PropTypes.string.isRequired,
};

export {
  MediaButton,
};
