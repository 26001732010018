import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

import { FormatUtils } from '../../utils/FormatUtils';

const AudioDurationDisplay = ({ duration, current}) => {

  const classes = useStyles();

  return (
    <div className={classes.durationDisplay} data-testid='audio-duration'>
      <p>
        {FormatUtils.formatTimestampToAudio(current)}
      </p>
      <p>
        {FormatUtils.formatTimestampToAudio(duration)}
      </p>
    </div>
  )
}

AudioDurationDisplay.defaultProps = {
  duration: 0,
  current: 0,
}

AudioDurationDisplay.propTypes = {
  duration: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
}

export {
  AudioDurationDisplay,
};
