import { CONTEXT_MENU_ROUTES } from '../../constants/routes';

import { ReactComponent as OpenBoxIcon } from '../../assets/icons/open-box.svg';
import { ReactComponent as PuzzlePieceIcon } from '../../assets/icons/puzzle-piece.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as BulbIcon } from '../../assets/icons/bulb.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';

const summaryMenuItems = [
  { label: 'Produktinfo', path: '', icon: OpenBoxIcon, viewBox: '0 0 50 50' },
  { label: 'Teile', path: CONTEXT_MENU_ROUTES.parts, icon: PuzzlePieceIcon, viewBox: '0 0 44 44' },
  { label: 'Werkzeug', path: CONTEXT_MENU_ROUTES.tools, icon: SupportIcon, viewBox: '0 0 40 40' },
  { label: 'Personen', path: CONTEXT_MENU_ROUTES.people, icon: UsersIcon, viewBox: '0 0 40 35' },
  { label: 'Aufbauzeit', path: CONTEXT_MENU_ROUTES.constructionTime, icon: ClockIcon, viewBox: '0 0 50 50' },
  { label: 'Tipps', path: CONTEXT_MENU_ROUTES.tips, icon: BulbIcon, viewBox: '0 0 35 50' },
  { label: 'Updates', path: CONTEXT_MENU_ROUTES.updates, icon: RefreshIcon, viewBox: '0 0 35 38', disabled: true },
];

const scrollOptions = {
  block: 'nearest',
  behavior: 'smooth',
};

export {
  summaryMenuItems,
  scrollOptions,
};
