import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: props => props.color || theme.colors.thirty,
    width: '30px',
    height: '30px',
    fontSize: '30px',
    padding: '0',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      width: '35px',
      height: '35px',
      fontSize: '35px',
    },
    [theme.breakpoints.up('extraXl')]: {
      width: '40px',
      height: '40px',
      fontSize: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '45px',
      height: '45px',
      fontSize: '45px',
    },
  },
  svgIcon: {
    fontSize: props => `${props.fontSize || 1}em`,
  },
  disabled: {
    '& svg': {
      color: '#fff',
    },
  },
}));

export {
  useStyles,
};
