import { all, takeLatest, put, call } from 'redux-saga/effects';

import { ProductService } from '../../services/ProductService';
import { appActions } from '../app/actions';
import { productActions } from './actions';

function* fetchProduct({ payload: { productId } }) {
  try {
    let result = yield call(ProductService.get, productId);
    yield put(productActions.fetchProductSuccess(result.data));
  } catch (error) {
    yield put(appActions.setError(error));
  }
}

export default function* productSaga() {
  yield all([
    takeLatest(productActions.FETCH_PRODUCT, fetchProduct),
  ]);
}
