import { createMuiTheme } from '@material-ui/core/styles';

const breakpoints = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1280,
  extraLg: 1440,
  xl: 1600,
  extraXl: 1920,
};

const textColors = {
  primary: '#000000',
  secondary: '#000000',
  thirty: 'grey',
  quaternary: '#787c84',
  fifth: '#fff'
};

const theme = createMuiTheme({
  colors: {
    primary: '#6266ea',
    secondary: '#8185FC',
    thirty: '#6266ea', //video icons
    text: {
      primary: textColors.primary,
      secondary: textColors.secondary,
      thirty: textColors.thirty,
      quaternary: textColors.quaternary,
      fifth: textColors.fifth
    },
    background: {
      primary: '#f7f8fc',
      secondary: 'rgba(98, 102, 234, .1)',
      thirty: '#ffffff',
      quaternary: '#5D6FED',
      header: '#f0f1f6',
      card: '#f7f8fc',
      chapter: '#f0f1f6',
      tableBullet: '#e5e6ef',
      highlighter: '#eff0fd',
    },
    outline: '#f0f1f6',
  },
  breakpoints: {
    values: breakpoints,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      focusRipple: true,
    },
  },
  spacing: 10,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '12px',
          [`@media (min-width: ${breakpoints.lg}px)`]: {
            fontSize: '13px',
          },
          [`@media (min-width: ${breakpoints.extraLg}px)`]: {
            fontSize: '14px',
          },
          [`@media (min-width: ${breakpoints.xl}px)`]: {
            fontSize: '16px',
          },
        },
        body: {
          backgroundColor: 'unset',
          fontSize: 'inherit',
          maxWidth: '1920px',
          margin: '0 auto',
          paddingRight: '0 !important',
          overflowX: 'auto !important',
          overflowY:'overlay !important',
          color: textColors.primary,
          height: '100%',
          '& *::-webkit-scrollbar': {
            width: '3px',
          },
          '& *::-webkit-scrollbar-track': {
            borderRadius: '45px',
            padding: '10px 0',
          },
          '& *::-webkit-scrollbar-thumb': {
            width: '3px',
            backgroundColor: '#787c84',
            borderRadius: '45px',
          },
          '& *': {
            scrollbarWidth: 'thin',
            scrollbarColor: '#c1c1c1 transparent',
          },
        },
      },
    },
  },
});

export {
  theme,
};