import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: '#FFF',
    transform: props => props.centered ? 'translateY(-10px) !important' : 'translate(-10px,-10px) !important',
    boxShadow: props => props.audioPlayer ? '0px 0px 5px 0px rgba(34, 60, 80, 0.54)': 'none',
    borderRadius: '7px',
    width: '170px',
    boxSizing: 'border-box',
    overflow: 'unset',
    [theme.breakpoints.down('md')]: {
      transform: props => !props.centered && props.subtitle && 'translate(-30px,-10px) !important',
    },
    [theme.breakpoints.up('xl')]: {
      width: '200px',
    },
  },
  wrapper: {
    padding: props => props.centered ? '11px 13px 0 10px' : '10px',
    width: '170px',
    borderRadius: '7px',
    borderBottom: '7px solid white', 
    zIndex: 10,
    [theme.breakpoints.up('xl')]: {
      width: '200px',
    },
  },
  tail: {
    width: '10px',
    height: '10px',
    boxShadow: props => props.audioPlayer ? '2px 2px 5px -1px rgba(34, 60, 80, 0.54)' : 'none',
    borderRadius: '3px',
    background: '#FFF',
    display: 'block',
    position: 'absolute',
    bottom: '-3.5px',
    zIndex: -1,
    left: props => props.centered ? props.right ? 'calc(100% - 18px)' : '50%' : '18px',
    transform: props => props.centered ? 'translateX(-50%) rotate(45deg)' : 'rotate(45deg)',
    [theme.breakpoints.up('lg')]: {
      left: props => props.centered ? props.right ? 'calc(100% - 20px)' : '50%' : '20px',
      transform: props => props.centered ? 'translateX(-50%) rotate(45deg)' : 'rotate(45deg)',
    },
    [theme.breakpoints.up('extraXl')]: {
      left: props => props.centered ? props.right ? 'calc(100% - 30px)' : '50%' : '30px',
      transform: props => props.centered ? 'translateX(-50%) rotate(45deg)' : 'rotate(45deg)',
    },
    [theme.breakpoints.up('xl')]: {
      left: props => props.centered ? props.right ? 'calc(100% - 25px)' : '50%' : '25px',
      transform: props => props.centered ? 'translateX(-50%) rotate(45deg)' : 'rotate(45deg)',
    },
    [theme.breakpoints.down('md')]: {
      left: props => props.subtitle && !props.centered && '45px',
    },
  }
}));

export { 
  useStyles, 
};
