import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';

import { MediaButton } from '../Buttons';
import { CheckboxField } from '../CheckboxInput/index';
import { InputNumberField } from '../InputNumber';
import { ListCell } from './SparePartsListCell';
import { ReactComponent as TrashBinIcon } from '../../assets/icons/trash-bin.svg';

import { useStyles as useListStyles } from '../ListView/styles';
import { listColumns } from './assets';
import { useStyles } from './styles';
import { PARTS_SIZES } from '../../constants/parts';
import { SparePartsUtils } from '../../utils/SparePartsUtils';

const LIMIT = 10;

const SparePartsList = ({
  prefix,
  values,
  sizeFilter,
  onPartClick,
  setFieldValue,
}) => {
  const classes = useStyles();
  const sharedListClasses = useListStyles();
  const [formValues, setFormValues] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [enabledRemoveButtons, setRemoveButtonsEnabled] = useState([]);

  const enableRemoveButtons = (fieldPrefix) => {
    if (!enabledRemoveButtons.includes(fieldPrefix)) {
      setRemoveButtonsEnabled(state => [...state, fieldPrefix]);
    }
  };

  const disableRemoveButtons = (fieldPrefix) => {
    setRemoveButtonsEnabled(state => state.filter(item => item !== fieldPrefix));
  };

  useEffect(() => {
    const formValues = SparePartsUtils.getFormValues(values, sizeFilter);

    const items = formValues.slice(0, LIMIT);
    setFormValues(items);
    setHasMore(items.length < formValues.length);
    setPage(0);
  }, [sizeFilter]);


  const loadListNextPage = () => {
    const parts = SparePartsUtils.getFormValues(values, sizeFilter);

    if (formValues.length >= parts.length) {
      setHasMore(false);
      return;
    }

    const currentPageRange = (page + 1) * LIMIT;
    const nextPageRange = currentPageRange + LIMIT;
    const items = parts.slice(currentPageRange, nextPageRange);

    setFormValues(state => [...state, ...items]);
    setPage(page + 1);
  };

  const getColumnContent = (part, index, { formField, value, field, fieldType, valueGetter }) => {
    //if all parts are displayed we need to minus count of small parts from indexes for big parts,
    //because index of field array of big parts start from zero
    //we should set the same names for fields independently of sizeFilter value
    const fieldIndex = sizeFilter === PARTS_SIZES.ALL && part.size === PARTS_SIZES.BIG
      ? index - values.parts.small.length
      : index;

    const fieldPrefix = `${prefix}.${part.size}.${fieldIndex}`;

    const fieldProps = {
      fieldPrefix,
      name: field,
      value,
      setFieldValue,
      enableRemoveButtons,
      disableRemoveButtons,
    };

    if (formField && fieldType === 'CHECKBOX') {
      return <CheckboxField part={part} {...fieldProps} />;
    }

    if (formField && fieldType === 'INPUT_NUMBER') {
      return <InputNumberField min={0} max={20} {...fieldProps} />;
    }

    if (fieldType === 'DELETE_CONTROL') {
      return (
        <MediaButton
          icon={TrashBinIcon}
          className={classes.deleteButton}
          disabled={!enabledRemoveButtons.includes(fieldPrefix)}
          viewBox="0 0 20 20"
          onClick={(e) => {
            e.stopPropagation();

            setFieldValue(`${fieldPrefix}.orderReason`, '');
            setFieldValue(`${fieldPrefix}.partQuantity`, 0);
          }}
        />
      );
    }

    const fieldValue = valueGetter ? valueGetter(part.thumbnail) : part[field];
    return <div className={classes.textContent}>{fieldValue}</div>;
  };

  return (
    <InfiniteScroll
      dataLength={formValues.length}
      next={loadListNextPage}
      hasMore={hasMore}
      height="100%"
      loader={<></>}
    >
      <ul>
        {formValues.map((part, index) => (
          <Grid
            container
            component="li"
            key={part.id}
            classes={{ root: clsx(sharedListClasses.listItem, classes.listItem) }}
            justify="space-between"
            className="row"
            spacing={2}
            onClick={() => onPartClick(part.id)}
          >
            {listColumns.map(({ field, ...columnProps }, i) => (
              <ListCell key={`${field}.${i}`} {...columnProps}>
                {getColumnContent(part, index, { ...columnProps, field })}
              </ListCell>
            ))}
          </Grid>))}
      </ul>
    </InfiniteScroll>
  );
};

SparePartsList.propTypes = {
  values: PropTypes.shape({
    parts: PropTypes.shape({
      small: PropTypes.arrayOf(PropTypes.object).isRequired,
      big: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
  onPartClick: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  sizeFilter: PropTypes.string.isRequired,
};

export {
  SparePartsList,
};