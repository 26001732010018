import { all, takeLatest, put, call } from 'redux-saga/effects';

import { toolsActions } from './actions';
import { appActions } from '../app/actions';

import { ToolsService } from '../../services/ToolsSetvice';

function* load({ requestData, productId }, onSuccessAction) {
  const { page = 1, limit = 10 } = requestData;

  try {
    const { data, meta } = yield call(ToolsService.get, productId, requestData);

    yield put(onSuccessAction({ items: data, page, limit }, meta.page.totalItems));
  } catch (error) {
    yield put(appActions.setError(error));
    yield put(toolsActions.listLoadFail());
  }
}

function* listLoad({ payload }) {
  yield call(load, payload, toolsActions.listRefresh);
}

function* listLoadNextPage({ payload }) {
  yield call(load, payload, toolsActions.listUpdate);
}

export default function* toolsSaga() {
	yield all([
		takeLatest(toolsActions.LIST_LOAD, listLoad),
		takeLatest(toolsActions.LIST_LOAD_NEXT_PAGE, listLoadNextPage),
	]);
}
