import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  aspectRatioBox: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: props => `${(props.height / props.width) * 100}%`,
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
});

export {
  useStyles,
};