import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  productInfoContainer: {
    height: '100%',
    padding: '0 40px',
    [theme.breakpoints.up('xl')]: {
      padding: '0 80px 100px 40px',
    },
  },
  productInfoContent: {
    height: '100%',
    margin: 'auto 0',
    [theme.breakpoints.up('xl')]: {
      margin: '20px 0 auto',
    },
  },
  leftColumn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '50%',
    margin: '0 35px 0 0',
    borderRadius: '20px',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.up('extraLg')]: {
      maxWidth: '43%',
      margin: '0 55px 0 0',
    },
  },
  rightColumn: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  thumbnailHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      borderRadius: '20px',
    },
  },
  productName: {
    margin: '0 0 15px',
    fontSize: '1.875rem',
    fontWeight: 700,
    lineHeight: 1,
    [theme.breakpoints.up('lg')]: {
      margin: '0 0 25px',
    },
  },
  productDescription: {
    margin: 0,
    fontSize: '1.125rem',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.375rem',
    },
  },
  productProgram: {
    display: 'inline-block',
    margin: '0 0 15px',
    color: theme.colors.primary,
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: 1,
  },
}));

export {
  useStyles,
};