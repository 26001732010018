import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectSidebarState } from '../../../redux/product/selectors';

import { useStyles } from './style';

import { PAGE_TYPES } from '../../../constants/pdf';

const PDFPage = ({ pageType, currentPdfIndex, imageSrc, isSliderActive, onPageClick }) => {
  const isSidebarDisabled = useSelector(selectSidebarState);
  const classes = useStyles({ isSliderActive, isSidebarDisabled });
  const classesPrefix = pageType === PAGE_TYPES.CURRENT_PAGE ? 'current' : 'thumbnail';

  const handleClick = (e) => {
    if (onPageClick && typeof onPageClick === 'function') {
      if (isSidebarDisabled) {
        e.preventDefault();
        return;
      }

      onPageClick(currentPdfIndex);
    }
  };

  return (
    <div className={classes[`${classesPrefix}PageWrapper`]}>
      <img
        className={classes[`${classesPrefix}Page`]}
        src={imageSrc}
        alt={`page-${currentPdfIndex + 1}`}
        onClick={handleClick}
      />
      <div
        className={classes[`${classesPrefix}PageNumber`]}
        onClick={handleClick}
      >
        {currentPdfIndex + 1}
      </div>
    </div>
  );
};

PDFPage.propTypes = {
  pageType: PropTypes.string.isRequired,
  currentPdfIndex: PropTypes.number.isRequired,
  imageSrc: PropTypes.string.isRequired,
  isSliderActive: PropTypes.bool,
  onPageClick: PropTypes.func,
};

export {
  PDFPage,
};