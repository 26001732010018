import { createSelector } from 'reselect';

const pdf = ({ PDF }) => PDF;

const pdfUI = ({ PDF }) => PDF.ui;

const selectPdfPages = createSelector(
  [pdf],
  (pdf) => ({
    currentPdfIndex: pdf.page,
    pdfsLength: pdf.allPdfs.length,
    allPdfs: pdf.allPdfs,
  }),
);

const selectCurrentPageNumber = createSelector(
  [pdf],
  ({ page }) => page,
);

const selectPreviousPageNumber = createSelector(
  [pdf],
  ({ previousPage }) => previousPage,
);

const selectFollowingThumbnails = createSelector(
  [pdf],
  (pdf) => pdf.followingThumbnails,
);

const selectSliderState = createSelector(
  [pdfUI],
  (pdfUI) => pdfUI.isSliderActive,
);

const selectPdfTimestamps = createSelector(
  [pdf],
  (pdf) => pdf.allPdfs.map(({ startTime, endTime }) => ({
    startTime,
    endTime,
  })),
);

const selectPdfConstructionTimeList = createSelector(
  [pdf],
  (pdf) => pdf.allPdfs.map(document => document.constructionTimes),
);

const selectCurrentPage = createSelector(
  [pdf],
  ({ page, allPdfs }) => allPdfs[page],
);

const selectPdfTimestampsLoadingState = createSelector(
  [pdf],
  ({ loading }) => loading,
);

const selectZoomViewState = createSelector(
  [pdfUI],
  (pdfUI) => pdfUI.isZoomView,
);

const selectFullscreenZoomState = createSelector(
  [pdfUI],
  (pdfUI) => pdfUI.isFullscreenZoom,
);

export {
  selectCurrentPageNumber,
  selectPdfPages,
  selectFollowingThumbnails,
  selectSliderState,
  selectPdfTimestamps,
  selectPdfConstructionTimeList,
  selectCurrentPage,
  selectPreviousPageNumber,
  selectPdfTimestampsLoadingState,
  selectZoomViewState,
  selectFullscreenZoomState,
};
