import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';

import { RoundButton } from '../../Buttons';
import { PDFPage } from '../PDFPage';

import { selectSidebarState } from '../../../redux/product/selectors';
import { PAGE_TYPES } from '../../../constants/pdf';
import { useStyles } from './style';

import {ReactComponent as ZoomImg} from '../../../assets/icons/zoom.svg';

const CurrentPDFPage = ({ currentPdf, currentPdfIndex, pdfsLength, changeCurrentPage, handleZoomIn }) => {
  const isSidebarDisabled = useSelector(selectSidebarState);
  const classes = useStyles({isSidebarDisabled});

  return currentPdf ? (
    <Grid container justify="space-between" alignItems="center" wrap="nowrap">
      <RoundButton
        icon={ChevronLeftIcon}
        disabled={currentPdfIndex <= 0 || isSidebarDisabled}
        onClick={() => changeCurrentPage(currentPdfIndex - 1)}
      />
      <div className={classes.pageWrapper} onClick={handleZoomIn}>
        <PDFPage
          pageType={PAGE_TYPES.CURRENT_PAGE}
          currentPdfIndex={currentPdfIndex}
          imageSrc={currentPdf.thumbnail}
        />
        <IconButton classes={{root: clsx(classes.iconButton)}} data-testid='zoom-button'>
          <SvgIcon classes={{ root: classes.svgIcon }} component={ZoomImg} viewBox='0 0 48 48'/>
        </IconButton>
      </div>
      <RoundButton
        icon={ChevronRightIcon}
        disabled={currentPdfIndex + 1 >= pdfsLength || isSidebarDisabled}
        onClick={() => changeCurrentPage(currentPdfIndex + 1)}
      />
    </Grid>
  ) : null;
};

CurrentPDFPage.propTypes = {
  currentPdf: PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
    thumbnail: PropTypes.string.isRequired,
  }),
  currentPdfIndex: PropTypes.number.isRequired,
  pdfsLength: PropTypes.number.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
};

export {
  CurrentPDFPage,
};