import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { PDFZoomPage } from './PDFZoomPage';
import { RoundButton, BackButton } from '../Buttons';

import { useStyles } from '../PDFZoom/styles';
import { useSideBySideStyles } from './styles';

const PDFZoomSideBySide = ({
  currentPdfIndex,
  changeCurrentPage,
  pdfsLength,
  allPdfs,
  handleZoomIn,
  onExitClick,
}) => {
  const sharedClasses = useStyles();
  const classes = useSideBySideStyles();

  const currentPageIndex = currentPdfIndex + 1 < pdfsLength ? currentPdfIndex : currentPdfIndex - 1;
  const nextPageIndex = currentPdfIndex + 1 < pdfsLength ? currentPdfIndex + 1 : currentPdfIndex;

  const currentPage = allPdfs[currentPageIndex];
  const nextPage = allPdfs[nextPageIndex];

  return (
    <>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap" className={classes.zoomContainer} style={{height: 'min-content',}}>
        <RoundButton
          icon={ChevronLeftIcon}
          disabled={currentPageIndex <= 0}
          onClick={() => changeCurrentPage(currentPdfIndex - 1)}
        />
        <Grid className={classes.pagesContainer} container justify="center" alignItems="center" wrap="nowrap">
          <PDFZoomPage
            src={currentPage.source}
            pageIndex={currentPageIndex}
            onClick={handleZoomIn}
          />
          <PDFZoomPage
            src={nextPage.source}
            name={nextPage.name}
            pageIndex={nextPageIndex}
            onClick={handleZoomIn}
          />
        </Grid>
        <RoundButton
          icon={ChevronRightIcon}
          disabled={nextPageIndex + 1 >= pdfsLength}
          onClick={() => changeCurrentPage(currentPdfIndex + 1)}
        />
      </Grid>
      <BackButton position={sharedClasses.exitButton} onClick={onExitClick} />
    </>
  );
};

PDFZoomSideBySide.propTypes = {
  currentPdfIndex: PropTypes.number.isRequired,
  pdfsLength: PropTypes.number.isRequired,
  allPdfs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.number,
    source: PropTypes.string,
  })).isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
  onExitClick: PropTypes.func.isRequired,
};

export {
  PDFZoomSideBySide,
};