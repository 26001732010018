import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  audioPlayer: {
    margin: '30px 0',
  },
  controls: {
    position: 'relative',
  },
  playerName: {
    fontSize: '1.188rem',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: '25px',
  },
  tipsName: {
    fontSize: '2.188rem',
    marginBottom: '50px',
  },
  iconButton: {
    width: '30px',
    height: '30px',
    fontSize: '30px',
    padding: '0',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      width: '35px',
      height: '35px',
      fontSize: '35px',
      padding: '7px',
    },
    [theme.breakpoints.up('extraXl')]: {
      width: '40px',
      height: '40px',
      fontSize: '40px',
      padding: '7px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '45px',
      height: '45px',
      fontSize: '45px',
      padding: '7px',
    },
  },
  svgIcon: {
    fontSize: '0.5em',
    '& path': {
      fill: props => props.repeat ? theme.colors.primary : '#000',
    },
  },
  iconButtonDisabled: {
    
  },
}));

export {
  useStyles,
};
