const SMALL = 'small';
const BIG = 'big';
const ALL = 'all';

const PARTS_SIZES = {
  SMALL,
  BIG,
  ALL,
};

const PARTS_SIZE_LABELS = {
  [SMALL]: 'Beschlagteile',
  [BIG]: 'Bauteile',
  [ALL]: 'Alle Teile',
};

export {
  PARTS_SIZES,
  PARTS_SIZE_LABELS,
};