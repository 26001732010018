import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { useSelector } from 'react-redux';
import { selectZoomViewState } from '../../../redux/pdf/selectors';
import { PDFZoomView } from '../../../containers/PDFZoomView';
import { AspectRatioBox } from '../../AspectRatioBox';

const MainContent = ({ isFullscreen, children }) => {
  const isZoomView = useSelector(selectZoomViewState);
  const classes = useStyles({ isFullscreen, isZoomView });

  return (
    <>
      <main className={classes.mainContent}>
        <AspectRatioBox width={16} height={9}>
          {children}
        </AspectRatioBox>  
      </main>
      {isZoomView && <PDFZoomView/>}
    </>
  );
};

MainContent.defaultProps = {
  isFullscreen: false,
};

MainContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isFullscreen: PropTypes.bool,
};

export {
  MainContent,
};
