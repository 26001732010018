import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectLoadingState } from '../../../redux/tools/selectors';

import { CloseTabButton } from '../CloseTabButton';
import { ProductTabContent } from '../ProductTabContent';
import { SectionTitle } from '../../SectionTitle';
import { Select } from '../../Select';
import { GridView } from '../../GridView';
import { ListView } from '../../ListView';

import { FormatUtils } from '../../../utils/FormatUtils';
import { viewModes } from '../../../constants/viewModes';
import { listColumns, viewModesList } from './assets';
import { ToolsDetailsModal } from '../ToolsDetailsModal';

const Tools = ({
  items,
  totalItems,
  hasMoreItems,
  currentTab,
  currentPdf,
  currentChapter,
  loadList,
  loadListNextPage,
  renderEscButton,
  productId,
  page,
}) => {
  const loading = useSelector(selectLoadingState);
  const [viewMode, setViewMode] = useState(viewModes.GRID);
  const [isModalOpen, setModalState] = useState(false);
  const [selectedToolsId, setSelectedToolsId] = useState('');

  useEffect(() => {
    loadList({ page: 1 });
  }, [currentTab, currentPdf, currentChapter]);

  const handleLoadNextPage = () => {
    loadListNextPage({ page: page + 1 });
  };

  const title = (
    <SectionTitle
      title="Werkzeug"
      subtitle={!loading ? FormatUtils.formatListItemsTotalCount(totalItems) : ''} />
  );

  const controls = (
    <>
      <Select items={viewModesList} disabled={!items.length} selectedValue={viewMode} setSelectValue={setViewMode} />
      {renderEscButton && <CloseTabButton productId={productId} />}
    </>
  );

  const closeModal = () => setModalState(false);

  const onToolsClick = (value) => {
    setSelectedToolsId(value);
    setModalState(true);
  };

  const selectedTools = useMemo(() => items.find(({ id }) => id === selectedToolsId), [items, selectedToolsId]);

  const props = {
    items,
    hasMoreItems,
    isModalOpen,
    loadListNextPage: handleLoadNextPage,
    onItemClick: onToolsClick,
  };

  return (
    <ProductTabContent title={title} controls={controls} loading={loading}>
      {viewMode === viewModes.GRID && <GridView {...props} />}
      {viewMode === viewModes.LIST && <ListView columns={listColumns} {...props} />}
      <div>
        {selectedTools && <ToolsDetailsModal
          isOpen={isModalOpen}
          tools={selectedTools}
          closeModal={closeModal}
        />}
      </div>
    </ProductTabContent>
  );
};

Tools.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  productId: PropTypes.string.isRequired,
  renderEscButton: PropTypes.bool,
  totalItems: PropTypes.number.isRequired,
  loadList: PropTypes.func.isRequired,
  loadListNextPage: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  currentTab: PropTypes.string,
  currentPdf: PropTypes.number,
  currentChapter: PropTypes.number,
};

export {
  Tools,
};
