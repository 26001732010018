const changePlayerState = (player) => {

  if (player.paused) {
    return player.play();
  } 

  return player.pause();
}

const popoverInitState = {
  volume: false,
};

export {
  changePlayerState,
  popoverInitState,
};
