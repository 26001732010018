import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute !important',
    boxShadow: 'none',
  },
  paper: {
    position: 'relative',
    width: '100%',
    maxWidth: 'unset',
    height: 'calc(100% - 46px)',
    margin: '43px 63px',
    padding: '20px',
    borderRadius: '20px',
    [theme.breakpoints.up('extraLg')]: {
      height: 'calc(100% - 106px)',
      margin: '53px 93px',
    },
    [theme.breakpoints.up('extraXl')]: {
      height: 'calc(100% - 166px)',
      margin: '83px 103px',
    },
  },
  backDrop: {
    background: 'none',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.1)',
    }
  },
}));

export {
  useStyles,
};