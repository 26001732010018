import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlayerTips } from '../../components/ProductTabs/PlayerTips';

import { tipsActions } from '../../redux/tips/actions';
import { selectExpandedTopics, selectTips, selectTipsTotalCount, selectTopics } from '../../redux/tips/selectors';
import { selectCurrentTab } from '../../redux/product/selectors';
import { selectCurrentPage, selectCurrentPageNumber,  } from '../../redux/pdf/selectors';
import { selectCurrentChapter, } from '../../redux/chapters/selectors';
import { selectCurrentChapterNumber, } from '../../redux/chapters/selectors';
import { TABS } from '../../constants/tabs';
import { useIsolatedPlayerState } from '../../hooks/useIsolatedPlayerState';
import { useParams } from 'react-router-dom';

const PlayerTipsView = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const totalTipsCount = useSelector(selectTipsTotalCount);
    const topics = useSelector(selectTopics);
    const tips = useSelector(selectTips);
    const currentTab = useSelector(selectCurrentTab);
    const currentPdfIndex = useSelector(selectCurrentPageNumber);
    const currentChapterIndex = useSelector(selectCurrentChapterNumber);
    const currentPdf = useSelector(selectCurrentPage);
    const currentChapter = useSelector(selectCurrentChapter);
    const expandedTopics = useSelector(selectExpandedTopics);
    useIsolatedPlayerState();

    const getFilters = () => {
      const filters = {};

      if (currentTab === TABS.PDF) {
        filters.pdf = currentPdf.id;
      } else {
        filters.chapter = currentChapter.id;
      }

      return filters;
    };

    useEffect(() => {
      dispatch(tipsActions.listTopicsLoad(id, { filters: getFilters() }, expandedTopics));
    }, [currentPdfIndex, currentChapterIndex, currentTab]);

    const toggleTopic = (topic) => {
      if (expandedTopics.includes(topic)) {
        dispatch(tipsActions.collapseTopic(topic));
        return;
      }

      dispatch(tipsActions.expandTopic(topic));
      dispatch(tipsActions.listTipsLoad(id, {
        filters: {
          ...getFilters(),
          topic,
        },
      }));
    };

    return (
      <PlayerTips
        renderEscButton
        productId={id}
        topics={topics}
        tips={tips}
        totalTipsCount={totalTipsCount}
        expandedTopics={expandedTopics}
        toggleTopic={toggleTopic}
      />
    );
  }
;

export {
  PlayerTipsView,
};
