import { StoreUtils } from '../../utils/StoreUtils';

const prefix = '[Chapters]';
const { createAction } = StoreUtils;

const TYPES = {
  CHANGE_CURRENT_CHAPTER: `${prefix} change-current-chapter`,
  RELOAD_CHAPTERS: `${prefix} reload-chapters`,
  REFRESH_CHAPTERS: `${prefix} refresh-chapters`,
  REFRESH_CHAPTERS_FAIL: `${prefix} refresh-chapters-fail`,
  SET_PREVIOUS_CHAPTER: `${prefix} set-previous-chapter`,
};

export const chaptersActions = {
  ...TYPES,
  changeCurrentChapter: createAction(TYPES.CHANGE_CURRENT_CHAPTER, 'chapter'),
  reloadChapters: createAction(TYPES.RELOAD_CHAPTERS, 'productId', 'requestData'),
  refreshChapters: createAction(TYPES.REFRESH_CHAPTERS, 'chapters'),
  refreshChaptersFail: createAction(TYPES.REFRESH_CHAPTERS_FAIL, 'error'),
  setPreviousChapter: createAction(TYPES.SET_PREVIOUS_CHAPTER, 'chapter'),
};
