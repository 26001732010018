import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { SpareParts } from '../../components/ProductTabs/SpareParts';
import { PARTS_SIZES } from '../../constants/parts';

import {
  selectLoadingState,
  selectSparePartsBySize,
  selectSparePartsFormValues,
} from '../../redux/spareParts/selectors';
import { sparePartsActions } from '../../redux/spareParts/actions';
import { UI_ROUTES } from '../../constants/routes';

const SparePartsView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const values = useSelector(selectSparePartsFormValues);
  const loading = useSelector(selectLoadingState);
  const [sizeFilter, setSizeFilterValue] = useState(PARTS_SIZES.ALL);
  const partsCount = useSelector(selectSparePartsBySize);

  useEffect(() => {
    dispatch(sparePartsActions.listLoad(id, { page: 1, limit: 0, filters: { size: PARTS_SIZES.ALL } }));
  }, [id]);

  const onBackButtonClick = useCallback(() => {
    history.push(generatePath(UI_ROUTES.videoPlayer, { id }));
  }, []);

  return (
    <SpareParts
      productId={id}
      loading={loading}
      values={values}
      partsCount={partsCount}
      sizeFilter={sizeFilter}
      setSizeFilterValue={setSizeFilterValue}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export {
  SparePartsView,
};
