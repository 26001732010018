import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    [theme.breakpoints.up('xl')]: {
      width: 35,
      height: 22,
    },
  },
  switchBase: {
    padding: 3,
    color: theme.colors.secondary,
    [theme.breakpoints.up('xl')]: {
      padding: 4,
    },
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.colors.secondary,
      },
    },
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
    [theme.breakpoints.up('xl')]: {
      width: 14,
      height: 14,
    },
  },
  track: {
    borderRadius: 19,
    opacity: 1,
    backgroundColor: '#e4e4e5',
  },
  checked: {},
}));

export { useStyles };