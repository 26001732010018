import React from 'react';
import PropTypes from 'prop-types';

import { PartsSizeSelect } from '../../PartsSizeSelect';
import { SparePartsForm } from '../../SparePartsForm';
import { ProductTabContent } from '../ProductTabContent';
import { SectionTitle } from '../../SectionTitle';
import { BackButton } from '../../Buttons';

import { PARTS_SIZE_LABELS } from '../../../constants/parts';

const SpareParts = ({
  values,
  loading,
  partsCount,
  sizeFilter,
  setSizeFilterValue,
  onBackButtonClick,
}) => {
  const title = <SectionTitle title="Ersatzteile" subtitle={PARTS_SIZE_LABELS[sizeFilter]} />;
  const controls = (
    <>
      <PartsSizeSelect selectedValue={sizeFilter} setSelectValue={setSizeFilterValue} partsCount={partsCount} />
      <BackButton onClick={onBackButtonClick} />
    </>
  );

  return (
    <ProductTabContent title={title} controls={controls} loading={loading}>
      <SparePartsForm values={values} sizeFilter={sizeFilter} />
    </ProductTabContent>
  );
};

SpareParts.propTypes = {
  values: PropTypes.shape({
    small: PropTypes.arrayOf(PropTypes.object).isRequired,
    big: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  productId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  sizeFilter: PropTypes.string.isRequired,
  partsCount: PropTypes.object.isRequired,
  setSizeFilterValue: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
};

export {
  SpareParts,
};
