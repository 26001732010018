import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute !important',
    boxShadow: 'none',
  },
  paper: {
    position: 'relative',
    minWidth: '460px',
    width: '34%',
    padding: '20px',
    borderRadius: '20px',
    overflow: 'hidden',
  },
  backDrop: {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  header: {
    fontSize: '2.118rem',
    margin: 0,
    marginBottom: '20px',
  }
}));

export {
  useStyles,
};
