const DIFFICULTY_LEVELS = {
  LOW: 'low',
  MIDDLE: 'middle',
  HIGH: 'high',
};

const DIFFICULTY_LEVELS_LABELS = {
  [DIFFICULTY_LEVELS.LOW]: 'Niedrig',
  [DIFFICULTY_LEVELS.MIDDLE]: 'Mittel',
  [DIFFICULTY_LEVELS.HIGH]: 'Hoch',
};

const TOTAL_LEVELS_COUNT = 3;

export {
  DIFFICULTY_LEVELS_LABELS,
  DIFFICULTY_LEVELS,
  TOTAL_LEVELS_COUNT,
};