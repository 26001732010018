import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { CloseButton } from '../../Buttons/CloseButton/CloseButton';
import { Modal } from '../../Modal';
import { AspectRatioBox } from '../../AspectRatioBox';

import { useStyles } from './styles';

const ToolsDetailsModal = ({ isOpen, tools, closeModal }) => {
  const classes = useStyles();

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Grid container wrap="nowrap" classes={{ root: classes.modalContent }}>
        <div className={classes.leftColumn}>
          <AspectRatioBox width={1} height={1}>
            <div className={classes.thumbnailHolder}>
              <img src={tools.thumbnail} alt={tools.name}/>
            </div>
          </AspectRatioBox>
        </div>
        <CloseButton onClick={closeModal} position={classes.buttonPosition}/>
        <Grid container direction="column" wrap="nowrap">
          <div className={classes.toolsProperty}>
            <h3 className={classes.toolName}>{tools.name}</h3>
          </div>
          <div className={classes.toolsProperty}>
              <span>{tools.description}</span>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

ToolsDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  tools: PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
};

export {
  ToolsDetailsModal,
};
