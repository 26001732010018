import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './style';

const RoundButton = ({ icon: Icon, onClick, disabled }) => {
  const classes = useStyles();

  return (
    <IconButton type="button" classes={{ root: classes.iconButton, label: classes.iconLabel }} disableRipple onClick={onClick} disabled={disabled}>
      <Icon classes={{ root: classes.svgIcon }}/>
    </IconButton>
  );
};

RoundButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
  disabled: PropTypes.bool,
};

export {
  RoundButton,
};