import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chapters } from '../../components/Chapters';
import { KEYS_CODE, WINDOW_EVENTS } from '../../constants/player';

import { chaptersActions } from '../../redux/chapters/actions';
import { selectChapters } from '../../redux/chapters/selectors';

const ChaptersView = () => {
  const dispatch = useDispatch();
  const { currentChapter, allChapters, chaptersLength } = useSelector(selectChapters);

  const changeCurrentChapter = useCallback((chapter) => {
    dispatch(chaptersActions.changeCurrentChapter(chapter));
  }, [dispatch]);

  const onKeyDown = useCallback((e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      switch (e.code) {
        case KEYS_CODE.arrowUp:
          dispatch(chaptersActions.changeCurrentChapter(currentChapter - 1 <= 0 ? 0 : currentChapter - 1));
          break;
        case KEYS_CODE.arrowDown:
          dispatch(chaptersActions.changeCurrentChapter(currentChapter + 1 >= chaptersLength -1 ? chaptersLength - 1: currentChapter + 1));
          break;
      }
    }
  }, [currentChapter, chaptersLength,  dispatch, chaptersActions])



  useEffect(() => {
    window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    return () => {
      window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
  }, [ onKeyDown])

  return (
    <Chapters
      currentChapter={currentChapter}
      allChapters={allChapters}
      chaptersLength={chaptersLength}
      changeCurrentChapter={changeCurrentChapter}
    />
  );
};

export {
  ChaptersView,
};
