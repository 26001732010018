import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

import { ListCell } from '../ListView/ListCell';

import { useStyles as useListStyles } from '../ListView/styles';
import { useStyles } from './styles';

const listItemProps = {
  container: true,
  spacing: 1,
  wrap: 'nowrap',
  justify: 'space-between',
};

const PeopleList = ({ items, columns, checkIsItemSelected, selectedItemRef, onIndexClick, onItemClick }) => {
  const sharedListClasses = useListStyles();
  const classes = useStyles();
  const defaultColumnWidth = `${(100 / columns.length)}%`;

  const renderListItems = (item, index) => {
    const isItemSelected = checkIsItemSelected ? checkIsItemSelected(index) : false;

    return (
      <Grid
        key={`${item.name}-${index}`}
        ref={isItemSelected ? selectedItemRef : null}
        component="li"
        classes={{
          root: clsx(sharedListClasses.listItem, {
            [classes.selected]: isItemSelected,
            [classes.hover]: onItemClick,
          }),
        }}
        onClick={onItemClick ? onItemClick(index) : null}
        {...listItemProps}
      >
        {columns.map(({ field, valueGetter, width, ...props }, index) => {
            const value = item[field];

            const parsedValue = valueGetter ? valueGetter(value, {...item, selected: isItemSelected}) : value;
            return <ListCell
              item
              key={`${item.name}${field}-${index}`}
              width={width || defaultColumnWidth}
              value={parsedValue}
              onClick={(field === 'pdfPage' || field === 'chapterIndex') && onIndexClick ? onIndexClick(value - 1) : null}
              {...props}
            />;
          },
        )}
      </Grid>
    );
  };

  return (
    <Grid container className={sharedListClasses.listViewContainer} direction="column">
      <Grid
        classes={{ root: sharedListClasses.listHeader }}
        {...listItemProps}
      >
        {columns.map(({ label, width, ...props }, index) => (
          <ListCell
            item
            key={index}
            width={width || defaultColumnWidth}
            value={label}
            {...props}
          />
        ))}
      </Grid>
      <div className={clsx(sharedListClasses.listWrapper, classes.listWrapper)}>
        <ul>
          {items.map(renderListItems)}
        </ul>
      </div>
    </Grid>
  );
};

PeopleList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    chapterId: PropTypes.number,
    pdfPage: PropTypes.number,
    name: PropTypes.string.isRequired,
    numberOfPeople: PropTypes.number,
    difficultyLevel: PropTypes.string,
    orderDependency: PropTypes.bool,
  })).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    width: PropTypes.string,
    valueGetter: PropTypes.func,
    align: PropTypes.oneOf(['center', 'flex-end']),
  })).isRequired,
  selectedItemRef: PropTypes.func,
  checkIsItemSelected: PropTypes.func,
  onItemClick: PropTypes.func,
  onIndexClick: PropTypes.func,
};

export {
  PeopleList,
};