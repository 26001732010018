import React from 'react';
import PropTypes from 'prop-types';

import { CurrentPDFPage } from './CurrentPDFPage';
import { Slider } from './Slider';
import { FollowingThumbnails } from './FollowingThumbnails';

import { useStyles } from './style';

const PDF = ({
  currentPdfIndex,
  allPdfs,
  pdfsLength,
  isSliderActive,
  followingThumbnails,
  setSliderState,
  changeCurrentPage,
  handleZoomIn,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.pdfWrapper}>
      <CurrentPDFPage
        currentPdf={allPdfs[currentPdfIndex]}
        currentPdfIndex={currentPdfIndex}
        pdfsLength={pdfsLength}
        changeCurrentPage={changeCurrentPage}
        handleZoomIn={handleZoomIn}
      />
      <Slider
        currentPdfIndex={currentPdfIndex}
        pdfsLength={pdfsLength}
        isSliderActive={isSliderActive}
        setSliderState={setSliderState}
        changeCurrentPage={changeCurrentPage}
      />
      <FollowingThumbnails
        allPdfs={allPdfs}
        isSliderActive={isSliderActive}
        changeCurrentPage={changeCurrentPage}
        followingThumbnails={followingThumbnails}
      />
    </div>
  );
};

PDF.propTypes = {
  currentPdfIndex: PropTypes.number.isRequired,
  pdfsLength: PropTypes.number.isRequired,
  allPdfs: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    thumbnail: PropTypes.string,
  })).isRequired,
  isSliderActive: PropTypes.bool.isRequired,
  followingThumbnails: PropTypes.arrayOf(PropTypes.number).isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  setSliderState: PropTypes.func.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
};

export {
  PDF,
};