import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    width: '50px',
    height: '50px',
    fontSize: '50px',
    transform: 'translateX(50%) translateY(-50%)',
    [theme.breakpoints.down('lg')]: {
      width: '40px',
      height: '40px',
      fontSize: '31px',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  svgIcon: {
    fontSize: '1.1em',
    '& path': {
      fill: props => props.isSidebarDisabled ? theme.colors.primary + '80' : theme.colors.primary,
    }
  },
  pageWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxHeight: '425px',
    paddingBottom: '93%',
    margin: '0 15px',
    background: '#fff',
    borderRadius: '26px',
    [theme.breakpoints.up('lg')]: {
      margin: '0 20px',
    },
    [theme.breakpoints.up('xl')]: {
      margin: '0 28px',
    },
    '&:hover': {
      cursor: props => props.isSidebarDisabled ? 'default' : 'pointer',
    },
  },
}));

export {
  useStyles,
};