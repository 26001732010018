import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '2.813rem 0 auto',
  },
  slider: {
    margin: '0 25px',
    padding: '0',
  },
  sliderRail: {
    height: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  sliderTrack: {
    height: '5px',
    background: 'none',
  },
  sliderThumb: {
    top: '50%',
    width: '26px',
    height: '25px',
    margin: '0 0 0 -19px',
    boxShadow: '0 10px 18px #e6e3ff',
    borderRadius: '19px',
    backgroundColor: theme.colors.primary,
    transform: 'translateY(-50%)',
    '&:hover, &:focus': {
      boxShadow: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      width: '33px',
      height: '32px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '38px',
      height: '37px',
    },
  },
  sliderRangeValue: {
    fontSize: '1.125rem',
    fontWeight: 500,
    '&:hover': {
      cursor: props =>  props.isSidebarDisabled ? 'default' : 'pointer'
    }
  },
  sliderDisabled: {
    '&$sliderThumb': {
      position: 'relative',
      width: '26px',
      height: '25px',
      margin: '0 0 0 -19px',
      [theme.breakpoints.up('lg')]: {
        width: '33px',
        height: '32px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '38px',
        height: '37px',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .5)',
      }
    },
  },
}));

export {
  useStyles,
};