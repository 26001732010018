import { PARTS_SIZES } from '../../constants/parts';

class SparePartsUtils {
  static generateInitialFormValue(values) {
    return {
      parts: {
        small: this.populateDefaultValues(values.small),
        big: this.populateDefaultValues(values.big),
      },
    };
  }

  static populateDefaultValues(values) {
    return values.map(item => ({ ...item, orderReason: '', partQuantity: 0 }));
  }

  static getFormValues(values, sizeFilter) {
    if (sizeFilter === PARTS_SIZES.ALL) {
      return [...values.parts.small, ...values.parts.big];
    }

    return sizeFilter === PARTS_SIZES.SMALL ? values.parts.small : values.parts.big;
  }

  static getFormValuesBySize(values) {

    return {
      [PARTS_SIZES.SMALL]: this.filterValuesBySize(values, PARTS_SIZES.SMALL),
      [PARTS_SIZES.BIG]: this.filterValuesBySize(values, PARTS_SIZES.BIG),
    };
  }

  static filterValuesBySize(values, size) {
    return values.filter(item => item.size === size);
  }


  static getOrderItemsCount(values) {
    return [...values.small, ...values.big].reduce((count, item) => item.partQuantity
      ? count + item.partQuantity
      : count
      , 0);
  }
}

export { SparePartsUtils };
