import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { SelectButton } from '../Buttons/SelectButton';

import { useStyles } from './styles';
import { SelectTextButton } from '../Buttons';

const Select = ({ items, disabled, selectedValue, setSelectValue, background }) => {
  const classes = useStyles({ background });

  return (
    <Grid container classes={{ root: classes.selectContainer }}>
      {items.map(({ value, icon: Icon, text }) =>
        Icon ? <SelectButton
          key={value}
          disabled={disabled}
          selected={selectedValue === value}
          value={value}
          icon={Icon}
          setSelectValue={setSelectValue}
        /> : <SelectTextButton
          key={value}
          selected={selectedValue === value}
          value={value}
          text={text}
          setSelectValue={setSelectValue}
        />
      )}
    </Grid>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string.isRequired,
  setSelectValue: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    icon: PropTypes.elementType,
  })).isRequired,
  background: PropTypes.string,
};

export {
  Select,
};