import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Slider from '@material-ui/core/Slider';

import { useStyles } from './styles';

import { DIFFICULTY_LEVEL_VALUES } from './assets';
import { TOTAL_LEVELS_COUNT } from '../../constants/people';


const DifficultyLevelProgress = ({ label, value, selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.progressWrapper}>
      <span>{label}</span>
      <Slider
        min={0}
        max={TOTAL_LEVELS_COUNT}
        value={DIFFICULTY_LEVEL_VALUES[value]}
        step={1}
        classes={{
          root: clsx(classes.root, { [classes.selected]: selected }),
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail,
        }}
        disabled
      />
    </div>
  );
};

DifficultyLevelProgress.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export {
  DifficultyLevelProgress,
};
