import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Parts } from '../../components/ProductTabs/Parts';

import { selectPartsBySize, selectPartsList } from '../../redux/parts/selectors';
import { partsActions } from '../../redux/parts/actions';
import { selectCurrentTab } from '../../redux/product/selectors';
import { selectCurrentPage, selectCurrentPageNumber } from '../../redux/pdf/selectors';
import { selectCurrentChapter, selectCurrentChapterNumber } from '../../redux/chapters/selectors';
import { TABS } from '../../constants/tabs';

import { useIsolatedPlayerState } from '../../hooks/useIsolatedPlayerState';
import { useParams } from 'react-router-dom';

const PlayerPartsView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { items, page, hasMoreItems, totalItems, allItems } = useSelector(selectPartsList);
  const currentTab = useSelector(selectCurrentTab);
  const currentPdf = useSelector(selectCurrentPage);
  const currentChapter = useSelector(selectCurrentChapter);
  const currentPdfIndex = useSelector(selectCurrentPageNumber);
  const currentChapterIndex = useSelector(selectCurrentChapterNumber);
  const partsCount = useSelector(selectPartsBySize);
  useIsolatedPlayerState();

  const loadList = (loadAction) => (requestParams) => {
      const filters = { ...requestParams.filters };

      if (currentTab === TABS.PDF) {
        filters.pdf = currentPdf.id;
      } else {
        filters.chapter = currentChapter.id;
      }

      dispatch(loadAction(id, { ...requestParams, filters }));
  };

  return (
    <Parts
      renderEscButton
      productId={id}
      items={items}
      page={page}
      totalItems={totalItems}
      allItems={allItems}
      partsCount={partsCount}
      hasMoreItems={hasMoreItems}
      currentTab={currentTab}
      currentPdf={currentPdfIndex}
      currentChapter={currentChapterIndex}
      loadList={loadList(partsActions.listLoad)}
      loadListNextPage={loadList(partsActions.listLoadNextPage)}
    />
  );
};

export {
  PlayerPartsView,
};
