import { appActions } from './actions';

const initState = {
  errors: [],
};

export default function appReducer(state = initState, { type, payload }) {
  switch (type) {
    case appActions.SET_ERROR: {
      return {
        ...state,
        errors: [
            ...state.errors,
            payload.error
        ]
      };
    }
    case appActions.CLOSE_ERROR: {
      return {
        ...state,
        errors: state.errors.map(error => (
          (error.options.key === payload.key)
            ? { ...error, dismissed: true }
            : { ...error }
        )),
      };
    }
    case appActions.DELETE_ERROR: {
      return {
        ...state,
        errors: state.errors.filter(
            error => error.options.key !== payload.key,
        ),
      };
    }
    default: {
      return state;
    }
  }
}