import React from 'react';
import { viewModes } from '../../../constants/viewModes';

import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';

import { ListImage } from '../../ListImage';

const viewModesList = [
  { value: viewModes.GRID, icon: GridIcon },
  { value: viewModes.LIST, icon: ListIcon },
];

const listColumns = [
  {
    field: 'thumbnail',
    label: 'Bild',
    width: '15%',
    // eslint-disable-next-line react/display-name
    valueGetter: (value, item) => <ListImage imgSrc={value} alt={item.name} />,
  },
  { field: 'name', label: 'Name', width: '35%' },
  { field: 'partNumber', label: 'Bezeichnung', width: '13%' },
  { field: 'quantity', label: 'Anzahl', width: '13%',   valueGetter: (value) => `${value}x`  },
  { field: 'dimensions', label: 'Maße',width: '24%'  },
];

export {
  viewModesList,
  listColumns,
};
