import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chapterWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '10px',
    [theme.breakpoints.up('extraLg')]: {
      padding: '15px 20px',
    },
  },
  selected: {
    background: theme.colors.primary,
    borderRadius: '15px',
    '& $chapterName, & $chapterDuration': {
      color: '#fff',
    },
    '& $chapterNumber': {
      color: '#fff',
      fontWeight: 600,
      background: 'rgba(255, 255, 255, .5)',
      opacity: 1,
    },
    '& $chapterThumbnail': {
      filter: 'none',
    },
    '& $chapterInfoWrapper': {
      opacity: 1,
    },
  },
  chapterNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20px',
    height: '20px',
    color: '#787c84',
    background: theme.colors.background.tableBullet,
    borderRadius: '50%',
    fontWeight: 500,
    opacity: props => props.isSidebarDisabled ? '0.5' : 1,
    [theme.breakpoints.up('lg')]: {
      minWidth: '25px',
      height: '25px',
    },
    [theme.breakpoints.up('extraLg')]: {
      minWidth: '35px',
      height: '35px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '40px',
      height: '40px',
    },
  },
  chapterThumbnailWrapper: {
    position: 'relative',
    width: '100%',
    maxHeight: '90px',
    margin: '0 10px',
    paddingBottom: '23.2%',
    [theme.breakpoints.up('xl')]: {
      margin: '0 20px',
    },
  },
  chapterThumbnail: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    filter: 'grayscale(100%)',
    transform: 'translateY(-50%)',
    borderRadius: '10px',
    [theme.breakpoints.up('extraLg')]: {
      borderRadius: '15px',
    },
  },
  chapterName: {
    margin: '0 0 9px',
    fontSize: '1.125rem',
    fontWeight: 600,
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 0 13px',
    },
  },
  chapterDuration: {
    color: '#787c84',
  },
  chapterInfoWrapper: {
    minWidth: '50%',
    opacity: props => props.isSidebarDisabled ? '0.5' : 1,
    [theme.breakpoints.up('lg')]: {
      minWidth: '45%',
    },
    [theme.breakpoints.up('extraLg')]: {
      minWidth: '41%',
    },
  },
}));

export {
  useStyles,
};
