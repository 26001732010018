import { makeStyles } from '@material-ui/core/styles';

const pageWrapper = {
  display: 'inline-block',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
};

const pageStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid',
  width: '100%',
  height: '100%',
  background: '#fff',
  fontSize: '0.875rem',
};

const pageNumberStyles = {
  position: 'absolute',
  bottom: '3.5px',
  left: '50%',
  width: '100%',
  padding: '2px 0',
  borderRadius: '16px',
  fontWeight: '500',
  transform: 'translate(-50%, 50%)',
  textAlign: 'center',
  lineHeight: '1.2',
  overflow: 'hidden',
  zIndex: 1,
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(255, 255, 255, .5)',
  borderRadius: '15px',
};

const useStyles = makeStyles((theme) => ({
    currentPageWrapper: pageWrapper,
    thumbnailPageWrapper: {
      ...pageWrapper,
      '&:after': {
        content: props => props.isSliderActive ? '" "' : '',
        ...overlayStyle,
      },
    },
    currentPage: {
      ...pageStyle,
      borderRadius: '26px',
      borderWidth: '5px',
      borderColor: theme.colors.outline,
      [theme.breakpoints.up('extraLg')]: {
        borderWidth: '7px',
      },
    },
    thumbnailPage: {
      ...pageStyle,
      borderRadius: '15px',
      borderWidth: '3px',
      borderColor: theme.colors.outline,
      '&:hover': {
        cursor: props => props.isSidebarDisabled ? 'default' : 'pointer',
      },
    },
    currentPageNumber: {
      ...pageNumberStyles,
      maxWidth: '40px',
      color: theme.colors.primary,
      background: theme.colors.background.chapter,
      fontSize: '1.125rem',
      [theme.breakpoints.up('extraLg')]: {
        padding: '5px 0',
        maxWidth: '50px',
      },
    },
    thumbnailPageNumber: {
      ...pageNumberStyles,
      maxWidth: '30px',
      color: '#f0f1f6',
      background: theme.colors.primary,
      fontSize: '0.875rem',
      opacity: props => props.isSidebarDisabled ? 0.5 : 1,
      '&:after': {
        content: props => props.isSliderActive ? '" "' : '',
        ...overlayStyle,
      },
      '&:hover': {
        cursor: props => props.isSidebarDisabled ? 'default' : 'pointer',
      },
      [theme.breakpoints.up('extraLg')]: {
        padding: '5px 0',
        maxWidth: '40px',
      },
    },
  }))
;

export {
  useStyles,
};