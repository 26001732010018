import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import { useStyles } from './styles';

const InputNumber = ({ min, max, onChange, field }) => {
  const classes = useStyles();

  return (
    <div className={classes.inputWrapper} onClick={e => e.stopPropagation()}>
      <IconButton
        classes={{ root: classes.button }}
        onClick={() => onChange(field.value - 1)}
        disabled={!field.value || field.value === min}
      >
        <ArrowLeftIcon/>
      </IconButton>
      <input className={classes.input} {...field} disabled/>
      <IconButton
        classes={{ root: classes.button }}
        onClick={() => onChange(field.value + 1)}
        disabled={!field.value || field.value === max}
      >
        <ArrowRightIcon/>
      </IconButton>
    </div>
  );
};

InputNumber.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  field: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export {
  InputNumber,
};