import { createSelector } from 'reselect';

const products = ({ Products }) => Products;

const selectProductsList = createSelector(
  [products],
  (products) =>  ({
      items: products.list,
      page: products.page,
      hasMoreItems: (products.page * products.limit) < products.totalItems,
      totalItems: products.totalItems,
    })
);

const selectLoadingState = createSelector(
  [products],
  (products) => products.loading,
);

export {
  selectProductsList,
  selectLoadingState,
};
