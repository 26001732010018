const perspectiveMenuItems = [
  { label: 'Hauptspur', perspective: 'main'},
  { label: 'Nahaufnahme', perspective: 'closeLook' },
  { label: 'Vogelperspektive', perspective: 'topShot' },
  { label: 'Weitwinkel', perspective: 'wideAngle' },
];

const POPOVER_NAME = 'perspective';

export {
  perspectiveMenuItems,
  POPOVER_NAME,
};
