import { createSelector } from 'reselect';
import { PARTS_SIZES } from '../../constants/parts';

const spareParts = ({ SpareParts }) => SpareParts;

const selectSparePartsFormValues = createSelector(
  [spareParts],
  (spareParts) => spareParts.values,
);

const selectLoadingState = createSelector(
  [spareParts],
  (spareParts) => spareParts.loading,
);

const selectSparePartsBySize = createSelector(
  [spareParts],
  (spareParts) => ({
    [PARTS_SIZES.BIG]: spareParts.bigParts,
    [PARTS_SIZES.SMALL]: spareParts.smallParts,
  }),
);

export {
  selectLoadingState,
  selectSparePartsFormValues,
  selectSparePartsBySize,
};