import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles ({
  content: {
    position: 'relative',
    height: '100%',
    padding: '25px 0 25px 20px',
    '& img': {
      width: '100%'
    }
  },
});

export {
  useStyles,
};