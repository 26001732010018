import { StoreUtils } from '../../utils';

const prefix = '[SpareParts]';
const { createAction } = StoreUtils;

const TYPES = {
	LIST_LOAD: `${prefix} list-load`,
	LIST_REFRESH: `${prefix} list-refresh`,
	LIST_LOAD_FAIL: `${prefix} list-load-fail`,
};

export const sparePartsActions = {
	...TYPES,
	listLoad: createAction(TYPES.LIST_LOAD, 'videoId', 'requestData'),
	listRefresh: createAction(TYPES.LIST_REFRESH, 'values', 'smallParts', 'bigParts'),
	listLoadFail: createAction(TYPES.LIST_LOAD_FAIL),
};
