import React from 'react';
import { PROFI_LEVELS, PROFI_LEVELS_VIEW_MODE } from '../../../constants/constructionTime';
import { ConstructionTimeProgress } from '../../ConstructionTimeProgress';

const listColumns = [
  { field: 'name', width: '10%' },
  {
    field: 'progress',
    width: '58%',
    // eslint-disable-next-line react/display-name
    valueGetter: (value, name) => <ConstructionTimeProgress value={value} name={name}/>,
  },
  { field: 'timePassed', label: 'Erledigt', width: '10%', },
  { field: 'timeRest', label: 'Rest', width: '12%', },
];

const profiLevelsButtons = [
  { value: PROFI_LEVELS_VIEW_MODE.BEGINNER, text: PROFI_LEVELS.beginner, },
  { value: PROFI_LEVELS_VIEW_MODE.HANDYMAN, text: PROFI_LEVELS.handyman, },
  { value: PROFI_LEVELS_VIEW_MODE.PROFI, text: PROFI_LEVELS.professional, },
]

export {
  listColumns,
  profiLevelsButtons,
};
