import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

const Routes = ({ routes, children }) => {

  return (
    <Switch>
      {routes.map(elem => {
        const { components: Components, nestedRoutes, ...route } = elem;

        return (
          <Route key={route.path} {...route} render={(props) => <Components routes={nestedRoutes} {...props} />}/>
        );
      })}
      {children}
    </Switch>
  );
};

Routes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    components: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  })),
  children: PropTypes.node,
};

export {
  Routes,
};
