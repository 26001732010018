import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './styles';

const ConstructionTimeGlobalInfo = ({ globalInfo }) => {
  const classes = useStyles();

  const renderListItems = (item, i) => (
    <Grid item key={i}>
      <Grid container direction='column'>
        <Grid item classes={{ root: classes.label }}>
          {item.label}
        </Grid>
        <Grid item>
          <Grid container justify='space-between'>
            <Grid item classes={{ root: classes.name }}>
              {item.name}
            </Grid>
            <Grid item classes={{ root: classes.duration }}>
              {item.duration}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      classes={{ root: classes.container }}
    >
      {globalInfo?.map(renderListItems)}
    </Grid>
  );
};

ConstructionTimeGlobalInfo.propTypes = {
  globalInfo: PropTypes.array.isRequired,
};

export {
  ConstructionTimeGlobalInfo,
};
