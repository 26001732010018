import { UI_ROUTES } from './constants/routes';

import { VideoPlayerPage } from './pages/Product/VideoPlayer';
import { nestedRoutes as videoPlayerNestedRoutes } from './pages/Product/VideoPlayer/assets';
import { SummaryPage } from './pages/Product/Summary';
import { nestedRoutes as summaryNestedRoutes } from './pages/Product/Summary/assets';
import { SparePartsPage } from './pages/Product/SpareParts';
import { AssemblyVideos } from './pages/AssemblyVideos';

export const routes = [
  {
    path: UI_ROUTES.moreAssemblyVideo,
    components: AssemblyVideos,
  },
  {
    path: UI_ROUTES.spareParts,
    exact: true,
    components: SparePartsPage,
  },
  {
    path: UI_ROUTES.summary,
    components: SummaryPage,
    nestedRoutes: summaryNestedRoutes,
  },
  {
    path: UI_ROUTES.videoPlayer,
    components: VideoPlayerPage,
    nestedRoutes: videoPlayerNestedRoutes,
  },
];