import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const SectionTitle = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <h3 className={classes.title}>
      {title}
      {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
    </h3>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export {
  SectionTitle,
};