import React from 'react';

import { IndexNumber } from '../../IndexNumber';
import { DifficultyLevelProgress } from '../../DifficultyLevelProgress';

import { DIFFICULTY_LEVELS_LABELS } from '../../../constants/people';

const listColumns = [
  {
    field: 'pdfPage',
    label: 'PDF-Seite',
    maxWidth: '115px',
    align: 'center',
    width: '18.75%',
    // eslint-disable-next-line react/display-name
    valueGetter: (value, { selected }) => <IndexNumber value={value} selected={selected} colorSchema="blue"/>,
  },
  { field: 'name', label: 'Kapitel', width: '25%' },
  { field: 'numberOfPeople', label: 'Anzahl Personen', width: '18.75%', maxWidth: '160px', align: 'center' },
  {
    field: 'difficultyLevel',
    label: 'Schwierigkeitsgrad',
    maxWidth: '176px',
    width: '18.75%',
    // eslint-disable-next-line react/display-name
    valueGetter: (value, { selected }) => <DifficultyLevelProgress
      label={DIFFICULTY_LEVELS_LABELS[value]}
      selected={selected} value={value}
    />,
  },
  {
    field: 'orderDependency',
    label: 'Reihenfolge zwingend',
    align: 'center',
    width: '18.75%',
    maxWidth: '210px',
    valueGetter: (value) => value ? 'Ja' : 'Nein',
  },
];

export {
  listColumns,
};
