import { all, takeLatest, put, call } from 'redux-saga/effects';

import { PartsService } from '../../services/PartsService';
import { appActions } from '../app/actions';
import { partsActions } from './actions';

function* load({ requestData = {}, productId }, onSuccessAction) {
  const { page = 1, limit = 10 } = requestData;

  try {
    const {
      data,
      meta,
    } = yield call(PartsService.get, productId, requestData);

    yield put(onSuccessAction({ items: data, page, limit }, meta));
  } catch (error) {
    yield put(appActions.setError(error));
    yield put(partsActions.listLoadFail());
  }
}

function* listLoad({ payload }) {
  yield call(load, payload, partsActions.listRefresh);
}

function* listLoadNextPage({ payload }) {
  yield call(load, payload, partsActions.listUpdate);
}

export default function* partsSaga() {
  yield all([
    takeLatest(partsActions.LIST_LOAD, listLoad),
    takeLatest(partsActions.LIST_LOAD_NEXT_PAGE, listLoadNextPage),
  ]);
}

export const sagas = {
  load,
  listLoad,
  listLoadNextPage,
};
