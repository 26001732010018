import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';

import { useStyles } from './styles';

const CheckboxInput = ({ field, value, handleChange, ...props }) => {
  const { icon, checkedIcon, ...classes } = useStyles();

  const onChange = () => {
    handleChange(field.value)
  };

  return (
    <Checkbox
      disableRipple
      checkedIcon={<CheckIcon className={clsx(icon, checkedIcon)}/>}
      icon={<span className={icon}/>}
      {...field}
      value={value}
      checked={field.value === value}
      classes={classes}
      onChange={onChange}
      {...props}/>
  );
};

CheckboxInput.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  form: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};

export {
  CheckboxInput,
};