import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

import { EscButton } from '../Buttons';
import { TipsVideoView } from '../../containers/TipsVideoView';

const VideoTip = ({ name, videoSrc, onClose }) => {
  const { backDrop, header, ...classes } = useStyles();

  return <Dialog
    classes={classes}
    open={true}
    onClose={onClose}
    BackdropProps={{
      classes: {
        root: backDrop,
      },
    }}
    onEscapeKeyDown={onClose}
  >
    <Grid container direction="column">
      <Grid container justify='space-between'>
        <Typography variant="body1" classes={{root: header}}>{name}</Typography>
        <EscButton onClick={onClose} />
      </Grid>
      <TipsVideoView videoSrc={videoSrc}/>
    </Grid>
  </Dialog>;
}

VideoTip.propTypes = {
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
};

export {
  VideoTip,
};
