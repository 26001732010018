import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mainContent: {
    height: '100%',
    padding: '0 40px 0 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
  imgContainer: {
    width: 'calc(50% - 20px)',
    height: '100%',
    marginRight: '20px',
    borderRadius: '20px',
    '& img': {
      minWidth: '100%',
      minHeight: '100%',
      width: '100%',
      height: '100%',
    },
  },
  topBar: {
    minHeight: '56.25%',
    padding: '5px 0 0',
  },
}));

export {
  useStyles,
};
