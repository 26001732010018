import { StoreUtils } from '../../utils/StoreUtils';

const prefix = '[PDF]';
const { createAction } = StoreUtils;

const TYPES = {
  CHANGE_CURRENT_PAGE: `${prefix} change-current-page`,
  UPDATE_FOLLOWING_THUMBNAILS: `${prefix} update-following-thumbnails`,
  SET_SLIDER_STATE: `${prefix} set-slider-state`,
  RELOAD_PDF_TIMESTAMPS: `${prefix} reload-pdf-timestamps`,
  REFRESH_PDF_TIMESTAMPS: `${prefix} refresh-pdf-timestamps`,
  REFRESH_PDF_TIMESTAMPS_FAIL: `${prefix} refresh-pdf-timestamps-fail`,
  SET_PREVIOUS_PDF_PAGE: `${prefix} set-previous-pdf-page`,
  TOGGLE_ZOOM_VIEW_STATE: `${prefix} toggle-zoom-view-state`,
  TOOGLE_FULLSCREEN_ZOOM_STATE: `${prefix} toggle-fullscreen-zoom-state`
};

export const pdfActions = {
  ...TYPES,
  changeCurrentPage: createAction(TYPES.CHANGE_CURRENT_PAGE, 'page'),
  updateFollowingThumbnails: createAction(TYPES.UPDATE_FOLLOWING_THUMBNAILS, 'followingThumbnails'),
  setSliderState: createAction(TYPES.SET_SLIDER_STATE, 'isSliderActive'),
  reloadPdfTimestamps: createAction(TYPES.RELOAD_PDF_TIMESTAMPS, 'productId', 'requestData'),
  refreshPdfTimestamps: createAction(TYPES.REFRESH_PDF_TIMESTAMPS, 'timestamps'),
  refreshPdfTimestampsFail: createAction(TYPES.REFRESH_PDF_TIMESTAMPS_FAIL, 'error'),
  setPreviousPdfPage: createAction(TYPES.SET_PREVIOUS_PDF_PAGE, 'page'),
  toggleZoomViewState: createAction(TYPES.TOGGLE_ZOOM_VIEW_STATE, 'isZoomView'),
  toggleFullscreenZoomState: createAction(TYPES.TOOGLE_FULLSCREEN_ZOOM_STATE, 'isFullscreenZoom'),
};
