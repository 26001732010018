import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    margin: 0,
    fontSize: '1.875rem',
    fontWeight: 700,
  },
  subtitle: {
    color: '#787C84',
    fontSize: '1.563rem',
    fontWeight: 500,
    margin: '0 0 0 15px',
  },
});

export {
  useStyles,
};