import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';

import { useStyles } from './styles';

const ConstructionTimeProgress = ({ value, name }) => {
  const progressValue = useMemo(() => typeof value === 'number' ? value : 0, [value])
  const classes = useStyles({name, value});

  return (
    <div className={classes.wrapper}>
      <LinearProgress 
        value={progressValue} 
        variant='determinate' 
        classes={{ 
          root: classes.root, 
          bar: classes.bar,
        }}
      />
      <p className={clsx(classes.info, typeof value === 'number' && classes.percent)}>
        {
          typeof value === 'number' ? `${value}%` : value
        }
      </p>
    </div>
  )
}

ConstructionTimeProgress.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
}

export {
  ConstructionTimeProgress,
};
