import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PDF } from '../../components/PDF';
import { selectSidebarState } from '../../redux/product/selectors';

import { PDFUtils } from '../../utils/PDFUtils';
import { pdfActions } from '../../redux/pdf/actions';
import {
  selectPdfPages,
  selectFollowingThumbnails,
  selectSliderState,
} from '../../redux/pdf/selectors';
import { KEYS_CODE, WINDOW_EVENTS } from '../../constants/player';

const PDFView = () => {
  const dispatch = useDispatch();
  const followingThumbnails = useSelector(selectFollowingThumbnails);
  const isSliderActive = useSelector(selectSliderState);
  const isSidebarDisabled = useSelector(selectSidebarState);
  const { currentPdfIndex, pdfsLength, allPdfs } = useSelector(selectPdfPages);

  useEffect(() => {
    if (!isSliderActive) {
      dispatch(pdfActions.updateFollowingThumbnails(
        PDFUtils.getFollowingThumbnailIndexes(currentPdfIndex, pdfsLength),
      ));
    }
  }, [currentPdfIndex, pdfsLength, isSliderActive]);

  const setSliderState = useCallback((state) => {
    dispatch(pdfActions.setSliderState(state));
  }, [dispatch]);

  const changeCurrentPage = useCallback((page) => {
    dispatch(pdfActions.changeCurrentPage(page));
  }, [dispatch, pdfsLength]);

  const handleZoomIn = useCallback((e) => {
    if (isSidebarDisabled) {
      e.preventDefault();
      return;
    }

    dispatch(pdfActions.toggleZoomViewState(true));
    dispatch(pdfActions.setPreviousPdfPage(currentPdfIndex));
  }, [dispatch, currentPdfIndex, isSidebarDisabled]);

  const onKeyDown = useCallback((e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      switch (e.code) {
        case KEYS_CODE.arrowLeft:
          dispatch(pdfActions.changeCurrentPage(currentPdfIndex - 1 <= 0 ? 0 : currentPdfIndex - 1));
          break;
        case KEYS_CODE.arrowRight:
          dispatch(pdfActions.changeCurrentPage(currentPdfIndex + 1 >= pdfsLength - 1 ? pdfsLength - 1 : currentPdfIndex + 1));
          break;
      }
    }
  }, [currentPdfIndex, pdfsLength, dispatch, pdfActions]);

  useEffect(() => {
    window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    return () => {
      window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <PDF
      currentPdfIndex={currentPdfIndex}
      pdfsLength={pdfsLength}
      allPdfs={allPdfs}
      isSliderActive={isSliderActive}
      followingThumbnails={followingThumbnails}
      changeCurrentPage={changeCurrentPage}
      setSliderState={setSliderState}
      handleZoomIn={handleZoomIn}
    />
  );
};

export {
  PDFView,
};
