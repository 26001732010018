import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const ListImage = ({ imgSrc, alt, ...styleProps }) => {
  const classes = useStyles(styleProps);

  return (
    <div className={classes.imgHolder}>
      <img src={imgSrc} alt={alt}/>
    </div>
  );
};

ListImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export {
  ListImage,
};
