import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  menuWrapper: {
    height: '100%',
    padding: '20px 20px 20px 10px',
    background: theme.colors.background.chapter,
    borderRadius: '15px',
    flexDirection: 'column',
    flexBasis: '0px',
    flexGrow: 1,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#c1c1c1 transparent',
    [theme.breakpoints.up('extraLg')]: {
      padding: '30px 30px 30px 10px',
    },
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  listItemLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '10px',
    borderRadius: '15px',
    textDecoration: 'none',
    color: theme.colors.text.primary,
    fontSize: '1.125rem',
    fontWeight: 600,
    [theme.breakpoints.up('extraLg')]: {
      padding: '15px 20px',
    },
    ['@media screen and  (max-height: 765px) and (max-width: 1600px)']: {
      fontSize: '1rem',
    },
  },
  listItemLinkActive: {
    background: theme.colors.primary,
    color: '#fff',
    '& $listItemNumber': {
      color: '#fff',
      fontWeight: 600,
      background: 'rgba(255, 255, 255, .5)',
      opacity: 1,
    },
    '& $iconWrapper': {
      background: '#fff',
      '& svg': {
        '& path': {
          fill: theme.colors.primary,
        },
      },
    },
  },
  disabled: {
    opacity: 0.4,
  },
  listItemNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20px',
    height: '20px',
    color: '#787c84',
    background: theme.colors.background.tableBullet,
    borderRadius: '50%',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      minWidth: '25px',
      height: '25px',
    },
    [theme.breakpoints.up('extraLg')]: {
      minWidth: '35px',
      height: '35px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '40px',
      height: '40px',
    },
    ['@media screen and  (max-height: 765px) and (max-width: 1600px)']: {
      minWidth: '25px',
      height: '25px',
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    margin: '0 15px',
    borderRadius: '10px',
    background: theme.colors.background.primary,
    [theme.breakpoints.up('lg')]: {
      width: '60px',
      height: '60px',
    },

    [theme.breakpoints.up('extraLg')]: {
      width: '70px',
      height: '70px',
      margin: '0 25px 0 20px',
      borderRadius: '15px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '90px',
      height: '90px',
    },
    ['@media screen and  (max-height: 765px) and (max-width: 1600px)']: {
      width: '55px',
      height: '55px',
      margin: '0 10px',
      minWidth: 'unset',
    },
    ['@media screen and  (max-height: 765px) and (max-width: 1336px)']: {
      width: '40px',
      height: '40px',
      margin: '0 10px',
      minWidth: 'unset',
    },
    '& svg': {
      fontSize: '2.5rem',
      '& path': {
        fill: '#787c84',
      },
      ['@media screen and  (max-height: 765px) and (max-width: 1536px)']: {
        fontSize: '2rem',
      },
      ['@media screen and  (max-height: 765px) and (max-width: 1336px)']: {
        fontSize: '1.8rem',
      },
    },
  },
}));

export {
  useStyles,
};