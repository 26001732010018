import { pdfActions } from './actions';

export const initState = {
  page: 0,
  followingThumbnails: [],
  allPdfs: [],
  previousPage: 0,
  ui: {
    isSliderActive: false,
    isZoomView: false,
    isFullscreenZoom: false,
  },
  loading: false,
};

export default function pdfReducer(state = initState, { type, payload }) {
  switch (type) {
    case pdfActions.CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        page: payload.page,
      };
    }
    case pdfActions.UPDATE_FOLLOWING_THUMBNAILS: {
      return {
        ...state,
        followingThumbnails: payload.followingThumbnails,
      };
    }
    case pdfActions.SET_SLIDER_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSliderActive: payload.isSliderActive,
        },
      };
    }
    case pdfActions.TOGGLE_ZOOM_VIEW_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isZoomView: payload.isZoomView,
        },
      };
    }
    case pdfActions.RELOAD_PDF_TIMESTAMPS: {
      return {
        ...state,
        loading: true,
      };
    }
    case pdfActions.REFRESH_PDF_TIMESTAMPS: {
      return {
        ...state,
        allPdfs: payload.timestamps,
        loading: false,
      };
    }
    case pdfActions.REFRESH_PDF_TIMESTAMPS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case pdfActions.SET_PREVIOUS_PDF_PAGE: {
      return {
        ...state,
        previousPage: payload.page,
      };
    }
    case pdfActions.TOOGLE_FULLSCREEN_ZOOM_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isFullscreenZoom: payload.isFullscreenZoom,
        },
      };
    }
    default: {
      return state;
    }
  }
}
