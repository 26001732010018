import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chaptersWrapper: {
    display: 'flex',
    height: '100%',
    padding: '10px 15px 10px 10px',
    flexDirection: 'column',
    flexBasis: '0px',
    flexGrow: 1,
    borderRadius: '15px',
    background: theme.colors.background.chapter,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.up('extraLg')]: {
      padding: '15px 20px 15px 10px',
    },
    '&::after': {
      content: '""',
      padding: '1px',
    },
  },
  chaptersList: {
    width: '100%',
    padding: 0,
  },
  chaptersListItem: {
    width: '100%',
    padding: 0,
    '&:hover': {
      cursor: props => props.isSidebarDisabled ? 'default' : 'pointer',
    },
  },
}));

export {
  useStyles,
};
