import { playerActions } from './actions';

const initState = {
  lastCurrentTime: 0,
  lastAudioTrackId: '',
  lastTextTrackId: '',
  lastVideoTrackId: 'main',
  volume: 1,
  ui: {
    isPlayerFullScreen: false,
    isBurgerMenuOpen: false,
    refreshState: false,
  }
};

export default function playerReducer (state = initState, { type, payload }) {
  switch (type) {
    case playerActions.SET_CURRENT_TIME: {
      return {
        ...state,
        lastCurrentTime: payload.currentTime,
      };
    }
    case playerActions.SET_VOLUME: {
      return {
        ...state,
        volume: payload.volumeLevel,
      };
    }
    case playerActions.SET_AUDIO_TRACK_ID: {
      return {
        ...state,
        lastAudioTrackId: payload.audioTrackId,
      };
    }
    case playerActions.SET_TEXT_TRACK_ID: {
      return {
        ...state,
        lastTextTrackId: payload.textTrackId,
      };
    }
    case playerActions.SET_VIDEO_TRACK_ID: {
      return {
        ...state,
        lastVideoTrackId: payload.videoTrackId,
      };
    }
    case playerActions.TOGGLE_FULLSCREEN_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isPlayerFullScreen: payload.isPlayerFullscreen,
        }
      }
    }
    case playerActions.TOGGLE_BURGER_MENU_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isBurgerMenuOpen: payload.isBurgerMenuOpen,
        }
      }
    }
    case playerActions.REFRESH_PLAYER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          refreshState: payload.refreshState
        }
      }
    }
    case playerActions.RESET_PLAYER_STATE: {
      return {
        ...initState,
        volume: state.volume,
        ui: {
          ...state.ui,
        }, 
      }
    }
    case playerActions.RESET_CURRENT_TIME: {
      return {
        ...state,
        lastCurrentTime: 0,
      }
    }
    default: {
      return state;
    }
  }
}
