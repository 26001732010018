import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AspectRatioBox } from '../AspectRatioBox';
import { useStyles } from './styles';

const GridView = ({
  items,
  isModalOpen,
  hasMoreItems,
  loadListNextPage,
  listItemClassName,
  onItemClick,
}) => {
  const classes = useStyles({ isModalOpen });

  return (
    <div className={classes.overlay}>
      <InfiniteScroll
        dataLength={items.length}
        next={loadListNextPage}
        hasMore={hasMoreItems}
        height="100%"
        loader={<></>}
      >
        <Grid container classes={{ root: classes.list }} component="ul" spacing={2}>
          {items.map(({ id, thumbnail, name }, index) => (
            <Grid
              item
              key={`${id}-${index}`}
              xs={3}
              component="li"
              classes={{ root: classes.listItem }}
            >
              <div onClick={() => onItemClick(id)}>
                <AspectRatioBox height={1} width={1}>
                  <div className={clsx(classes.listItemContent, listItemClassName)}>
                    <img src={thumbnail} alt={name}/>
                  </div>
                </AspectRatioBox>
              </div>
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

GridView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  loadListNextPage: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  listItemClassName: PropTypes.string,
};

export {
  GridView,
};