import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './styles';

import { EscButton } from '../Buttons';
import { TipsAudioView } from '../../containers/TipsAudioView';

const AudioTip = ({ audio, onClose }) => {
  const { backDrop, ...classes } = useStyles();

  return <Dialog
    classes={classes}
    open={true}
    onClose={onClose}
    BackdropProps={{
      classes: {
        root: backDrop,
      },
    }}
    onEscapeKeyDown={onClose}
  >
    <Grid container direction="column">
      <Grid container justify='flex-end'>
        <EscButton onClick={onClose} />
      </Grid>
      <TipsAudioView audio={audio}/>
    </Grid>
  </Dialog>;
}

AudioTip.propTypes = {
  onClose: PropTypes.func.isRequired,
  audio: PropTypes.string.isRequired,
};

export {
  AudioTip,
};

