import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';

//import { EscButton } from '../../Buttons';
import { UI_ROUTES } from '../../../constants/routes';
import { BackButton } from '../../Buttons';

const CloseTabButton = ({ productId, onClick }) => {
  const history = useHistory();

  const onEscClick = useCallback(() => {
    if(onClick) onClick();
    history.push(generatePath(UI_ROUTES.videoPlayer, { id: productId }));
  }, [productId]);

  return <BackButton onClick={onEscClick}/>;
};

CloseTabButton.propTypes = {
  productId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export {
  CloseTabButton,
};
