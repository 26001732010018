import React, { useCallback }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';

import { TotalConstructionTime } from '../../components/ProductTabs/TotalConstructionTime';

import { selectChapterConstructionTimeList, selectChapters } from '../../redux/chapters/selectors';
import { pdfActions } from '../../redux/pdf/actions';
import { selectPdfTimestamps } from '../../redux/pdf/selectors';
import { playerActions } from '../../redux/player/actions';
import { chaptersActions } from '../../redux/chapters/actions';

import { UI_ROUTES } from '../../constants/routes';
import { productActions } from '../../redux/product/actions';
import { TABS } from '../../constants/tabs';
import { VideoSidebarUtils } from '../../utils/VideoSidebarUtils';

const SummaryConstructionTime = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { allChapters } = useSelector(selectChapters);
  const chapterConstructionTimeList  = useSelector(selectChapterConstructionTimeList);
  const pdfTimestamps = useSelector(selectPdfTimestamps);

  const onIndexClick = useCallback((index) => () => {
    const newChapter = allChapters[index];
    const newPdf = VideoSidebarUtils.findFirstPdfInChapter(pdfTimestamps, newChapter);

    dispatch(productActions.switchTab(TABS.VIDEO_KAPITEL));
    dispatch(chaptersActions.changeCurrentChapter(index));
    dispatch(chaptersActions.setPreviousChapter(index));
    dispatch(pdfActions.changeCurrentPage(newPdf));
    dispatch(pdfActions.setPreviousPdfPage(0));
    dispatch(playerActions.resetCurrentTime());
    history.push(generatePath(UI_ROUTES.videoPlayer, { id }));
  }, [dispatch, history, allChapters]);

  return (
    <TotalConstructionTime
      allChapters={allChapters}
      chapterConstructionTimeList={chapterConstructionTimeList}
      pdfTimestamps={pdfTimestamps}
      onIndexClick={onIndexClick}
    />
  )
}

export {
  SummaryConstructionTime,
};
