import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PARTS_SIZES } from '../../../constants/parts';

import { PartsSizeSelect } from '../../PartsSizeSelect';
import { CloseTabButton } from '../CloseTabButton';
import { PartsDetailsModal } from '../PartsDetailsModal';
import { ProductTabContent } from '../ProductTabContent';
import { SectionTitle } from '../../SectionTitle';
import { Select } from '../../Select';
import { GridView } from '../../GridView';
import { ListView } from '../../ListView';

import { FormatUtils } from '../../../utils/FormatUtils';
import { viewModes } from '../../../constants/viewModes';
import { viewModesList, listColumns } from './assets';
import { selectLoadingState } from '../../../redux/parts/selectors';
import { useStyles } from './styles';
import { useUpdateEffect } from '../../../hooks/useUpdateEffect';

const Parts = ({
  items,
  totalItems,
  allItems,
  hasMoreItems,
  productId,
  page,
  partsCount,
  loadList,
  loadListNextPage,
  renderEscButton,
  currentTab,
  currentPdf,
  currentChapter,
}) => {
  const loading = useSelector(selectLoadingState);
  const [viewMode, setViewMode] = useState(viewModes.GRID);
  const [isModalOpen, setModalState] = useState(false);
  const [selectedPartId, setSelectedPart] = useState('');
  const [sizeFilter, setSizeFilterValue] = useState(PARTS_SIZES.ALL);
  const classes = useStyles();
  const isControlsDisabled = !allItems;

  useEffect(() => {
    loadList({ page: 1, filters: { size: sizeFilter } });
  }, [currentTab, currentPdf, currentChapter]);

  useUpdateEffect(() => {
    loadList({ page: 1, filters: { size: sizeFilter } });
  }, [sizeFilter]);

  const handleLoadNextPage = () => {
    loadListNextPage({ page: page + 1, filters: { size: sizeFilter } });
  };

  const title = (
    <SectionTitle
      title="Teile"
      subtitle={!loading ? FormatUtils.formatPartsListTotalCount(totalItems, sizeFilter) : ''} />
  );
  const controls = (
    <>
      <PartsSizeSelect selectedValue={sizeFilter} disabled={isControlsDisabled} setSelectValue={setSizeFilterValue} partsCount={partsCount} />
      <Select items={viewModesList} disabled={isControlsDisabled} selectedValue={viewMode} setSelectValue={setViewMode} />
      {renderEscButton && <CloseTabButton productId={productId} />}
    </>
  );

  const closeModal = () => setModalState(false);

  const onPartClick = (value) => {
    setSelectedPart(value);
    setModalState(true);
  };

  const props = {
    items,
    hasMoreItems,
    isModalOpen,
    loadListNextPage: handleLoadNextPage,
    onItemClick: onPartClick,
  };

  const selectedPart = items.find(({ id }) => id === selectedPartId);

  return (
    <ProductTabContent title={title} controls={controls} loading={loading}>
      {viewMode === viewModes.GRID && <GridView listItemClassName={classes.listItem} {...props} />}
      {viewMode === viewModes.LIST && <ListView columns={listColumns} {...props} />}
      <div>
        {selectedPart && <PartsDetailsModal
          isOpen={isModalOpen}
          part={selectedPart}
          closeModal={closeModal}
        />}
      </div>
    </ProductTabContent>
  );
};

Parts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  productId: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  renderEscButton: PropTypes.bool,
  totalItems: PropTypes.number.isRequired,
  allItems: PropTypes.number.isRequired,
  loadList: PropTypes.func.isRequired,
  loadListNextPage: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  currentTab: PropTypes.string,
  currentPdf: PropTypes.number,
  currentChapter: PropTypes.number,
  partsCount: PropTypes.object.isRequired,
};

export {
  Parts,
};
