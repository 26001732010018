import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const AspectRatioBox = ({ children, height, width }) => {
  const classes = useStyles({ height, width });

  return <div className={classes.aspectRatioBox}>
    <div>{children}</div>
  </div>;
};

AspectRatioBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

AspectRatioBox.defaultProps = {
  height: 9,
  width: 16,
};

export {
  AspectRatioBox,
};