import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';
import { FormatUtils } from '../../utils/FormatUtils'

const CurrentChapterDisplay = ({currentChapter, allChapters}) => {
  const classes = useStyles();
  const formatedCurrentChapter = useMemo(() => FormatUtils.formatChapter(currentChapter + 1), [currentChapter]);
  const formatedAllChapters = useMemo(() => FormatUtils.formatChapter(allChapters), [allChapters]);

  return (
    <div className={classes.root}>
      <p className={classes.label}>Kapitel&nbsp;</p>
      <span className={classes.current}>{formatedCurrentChapter}</span>
      <span className={classes.divider}>/</span>
      <span>{formatedAllChapters}</span>
    </div>
  )
}

CurrentChapterDisplay.defaultProps = {
  currentChapter: 0,
  allChapters: 0,
}

CurrentChapterDisplay.propTypes = {
  currentChapter: PropTypes.number.isRequired,
  allChapters: PropTypes.number.isRequired,
}

export {
  CurrentChapterDisplay,
};
