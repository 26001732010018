import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import clsx from 'clsx';

const IndexNumber = ({ value, mini, colorSchema, selected, disabled }) => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.chapterNumber, {
      [classes.blue]: colorSchema === 'blue',
      [classes.selected]: selected,
      [classes.disabled]: disabled,
      [classes.mini]: mini,
    })}>
    {value}
  </span>
  );
};

IndexNumber.propTypes = {
  value: PropTypes.number.isRequired,
  colorSchema: PropTypes.oneOf(['blue']),
  mini: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export {
  IndexNumber,
};