import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { CheckboxInput } from './CheckboxInput';

const CheckboxField = ({
  part,
  value,
  name,
  fieldPrefix,
  setFieldValue,
  enableRemoveButtons,
  disableRemoveButtons,
}) => {
  const fieldName = `${fieldPrefix}.${name}`;

  const handleChange = (fieldValue) => {
    if (fieldValue === value) {
      setFieldValue(fieldName, '');
      setFieldValue(`${fieldPrefix}.partQuantity`, 0);
      disableRemoveButtons(fieldPrefix)
      return;
    }

    setFieldValue(fieldName, value);
    setFieldValue(`${fieldPrefix}.partQuantity`, part.partQuantity ? part.partQuantity : 1);
    enableRemoveButtons(fieldPrefix);
  };

  return (
    <Field
      name={fieldName}
      value={value}
      placeholder="Jane Doe"
      component={CheckboxInput}
      handleChange={handleChange}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

CheckboxField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  fieldPrefix: PropTypes.string,
  part: PropTypes.object,
  setFieldValue: PropTypes.func,
  enableRemoveButtons: PropTypes.func,
  disableRemoveButtons: PropTypes.func,
};

export {
  CheckboxField,
};