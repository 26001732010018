import axios from 'axios';
import { API_URL } from '../../constants/routes';

const domain = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test' ? API_URL :  '';

class BaseService {

  constructor(url = '') {
    this.baseServiceURL = domain;
    if (url) {
      this.baseServiceURL = `${domain}${url}`;
    }
    this.processError = this.processError.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.post = this.post.bind(this);
    this.get = this.get.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  get baseURL() {
    return this.baseServiceURL;
  }

  get agent() {
    const headers = {};


    return axios.create({
      baseURL: this.baseURL,
      headers,
    });
  }

  processError(error) {
    return {
      message: `Request to ${error.response.config.baseURL + error.response.config.url} failed with status code ${error.response.status}!`,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
      },
    };
  }

  processResponse(response) {
    const axiosData = response?.data;

    return axiosData;
  }

  async post(data = {}, options = {}) {
    try {
      const response = await this.agent.post('', {
        ...data,
      });

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }

  async get(options = {}) {
    try {
      const response = await this.agent.get('');

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }

  async put(id, data = {}, options = {}) {
    const url = `/${id}`;
    try {
      const response = await this.agent.put(url, {
        ...data,
      });

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }


  async delete(id, params = {}, options = {}) {
    const url = `/${id}`;
    try {
      const response = await this.agent.delete(url, {
        params,
      });

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }

}

export {
  BaseService,
};
