import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { useStyles } from './styles';

const SwitchButton = ({ checked, onChange, ...rest}) => {
  const classes = useStyles();

  return (
    <Switch
      disableRipple
      checked={checked}
      onChange={onChange}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...rest}
    />
  )
}

SwitchButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export {
  SwitchButton,
};
