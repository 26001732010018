import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  root: {
    width: '100%',
    maxWidth: '86px',
    padding: '10px 0',
  },
  thumb: {
    display: 'none',
  },
  track: {
    height: '5px',
    borderRadius: '10px',
    background: theme.colors.primary,
  },
  rail: {
    height: '5px',
    borderRadius: '10px',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  selected: {
    '& $track': {
      background: '#fff',
    },
    '& $rail': {
      background: ' rgba(255, 255, 255)',
    },
  },
}));

export {
  useStyles,
};
