import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  text: {
    margin: '0',
    padding: '0',
    fontSize: '1.25rem',
    fontWeight: 500,
    letterSpacing: '0.44px',
    lineHeight: 1,
    ['@media (pointer: fine) and (max-width: 1439px)']: {
      fontSize: '1.1rem',
    },
  },
}));

export {
  useStyles,
};