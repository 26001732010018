import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../../constants/routes';
import { PlayerPartsView } from '../../../containers/PlayerPartsView';
import { PlayerToolsView } from '../../../containers/PlayerToolsView';
import { PlayerPeopleView } from '../../../containers/PlayerPeopleView';
import { PlayerView } from '../../../containers/PlayerView';
import { PlayerTipsView } from '../../../containers/PlayerTipsView';
import { PlayerConstructionTimeView } from '../../../containers/PlayerConstructionTimeView';

const getRoutePath = (path) => `${UI_ROUTES.videoPlayer}${path}`;

const nestedRoutes = [
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.parts),
    exact: true,
    components: PlayerPartsView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.tools),
    exact: true,
    components: PlayerToolsView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.people),
    exact: true,
    components: PlayerPeopleView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.constructionTime),
    exact: true,
    components: PlayerConstructionTimeView,
  },
  {
    path: getRoutePath(CONTEXT_MENU_ROUTES.tips),
    exact: true,
    components: PlayerTipsView,
  },
  {
    path: UI_ROUTES.videoPlayer,
    components: PlayerView,
    exact: true,
  },
];

export { nestedRoutes };