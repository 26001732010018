import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './styles';

const ToggleButton = ({
  iconOnTrue: IconOnTrue,
  iconOnFalse: IconOnFalse,
  svgClass,
  flag,
  big,
  onClick,
  ...rest
}) => {

  const classes = useStyles({ big });
  const Icon = flag ? IconOnTrue : IconOnFalse;

  return (
    <IconButton
      disableRipple
      onClick={onClick}
      classes={{ root: classes.iconButton, disabled: classes.disabled }}
      {...rest}
    >
      <Icon className={svgClass} classes={{ root: classes.svgIcon }}/>
    </IconButton>
  );
};

ToggleButton.propTypes = {
  iconOnTrue: PropTypes.elementType.isRequired,
  iconOnFalse: PropTypes.elementType.isRequired,
  flag: PropTypes.bool.isRequired,
  big: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  svgClass: PropTypes.string,
};

export {
  ToggleButton,
};
