import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import { useStyles } from './styles';

const Modal = ({ isOpen, children, onClose, disablePortal }) => {
  const { backDrop, ...classes } = useStyles();

  return (
    <Dialog
      classes={classes}
      open={isOpen}
      BackdropProps={{
        classes: {
          root: backDrop,
        },
      }}
      onEscapeKeyDown={onClose ? onClose : null}
      onClose={onClose}
      disablePortal={disablePortal}
    >
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  disablePortal: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  disablePortal: true,
};

export {
  Modal,
};