import { createSelector } from 'reselect';
import { PARTS_SIZES } from '../../constants/parts';

const parts = ({ Parts }) => Parts;

const selectPartsList = createSelector(
  [parts],
  (parts) => ({
    items: parts.list,
    page: parts.page,
    hasMoreItems: (parts.page * parts.limit) < parts.totalItems,
    totalItems: parts.totalItems,
    allItems: parts.allItems,
  }),
);

const selectPartsBySize = createSelector(
  [parts],
  (parts) => ({
    [PARTS_SIZES.BIG]: parts.bigParts,
    [PARTS_SIZES.SMALL]: parts.smallParts,
  }),
);

const selectLoadingState = createSelector(
  [parts],
  (parts) => parts.loading,
);

export {
  selectPartsList,
  selectLoadingState,
  selectPartsBySize,
};