import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { productActions } from '../redux/product/actions';
import { chaptersActions } from '../redux/chapters/actions';
import { pdfActions } from '../redux/pdf/actions';
import { selectCurrentTab, selectPreviousTab } from '../redux/product/selectors';
import { selectPdfPages, selectPreviousPageNumber } from '../redux/pdf/selectors';
import { selectChapters, selectPreviousChapterNumber } from '../redux/chapters/selectors';
import { TABS } from '../constants/tabs';
import { VideoSidebarUtils } from '../utils/VideoSidebarUtils';
import { useUpdateEffect } from './useUpdateEffect';

const useIsolatedPlayerState = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTab = useSelector(selectCurrentTab);
  const previousTab = useSelector(selectPreviousTab);
  const { currentPdfIndex, allPdfs } = useSelector(selectPdfPages);
  const { currentChapter: currentChapterIndex, allChapters } = useSelector(selectChapters);
  const previousPdf = useSelector(selectPreviousPageNumber);
  const previousChapter = useSelector(selectPreviousChapterNumber);

  const prevData = useRef({
    prevChapter: null,
    prevPdf: null,
  });

  useEffect(() => {
    dispatch(productActions.setPreviousTab(currentTab));
    dispatch(chaptersActions.setPreviousChapter(currentChapterIndex));
    dispatch(pdfActions.setPreviousPdfPage(currentPdfIndex));
  }, []);

  useEffect(() => {
    prevData.current = {
      prevPdf: currentPdfIndex,
      prevChapter: currentChapterIndex,
    };
  }, [currentPdfIndex, currentChapterIndex, prevData]);

  useUpdateEffect(() => {
    if (currentTab === TABS.PDF) {
      const { prevPdf } = prevData.current;
      const currentChapter = allChapters[currentChapterIndex];
      const newPdf = VideoSidebarUtils.findNewPdfPage(allPdfs, currentChapter, prevPdf);

      if (newPdf !== prevPdf) {
        dispatch(pdfActions.changeCurrentPage(newPdf));
      }
    }
  }, [allChapters, allPdfs, currentChapterIndex, currentTab]);

  useUpdateEffect(() => {
    if (currentTab === TABS.VIDEO_KAPITEL) {
      const { prevChapter } = prevData.current;
      const currentPdf = allPdfs[currentPdfIndex];
      const chapterIndex = VideoSidebarUtils.findNewChapter(allChapters, currentPdf);

      if (chapterIndex !== prevChapter) {
        dispatch(chaptersActions.changeCurrentChapter(chapterIndex));
      }
    }
  }, [allChapters, allPdfs, currentPdfIndex, currentTab]);

  useEffect(() => {
    const unblock = history.block(() => {
      dispatch(productActions.switchTab(previousTab));
      dispatch(pdfActions.changeCurrentPage(previousPdf));
      dispatch(chaptersActions.changeCurrentChapter(previousChapter));
    });

    return () => {
      unblock();
    };
  }, [previousTab, previousPdf, previousChapter]);
};


export {
  useIsolatedPlayerState,
};