import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: 'relative',
    height: '100%',
    borderRadius: '20px',
    '& .video-js': {
      backgroundColor: 'unset',
    },
    '& *': {
      userSelect: 'none',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
    }
  },
  videoPlayer: {
    borderRadius: '20px',
    overflow: 'hidden',
    zIndex: 10,
    '& .vjs-text-track-display': {
      bottom: '15%',
      [theme.breakpoints.up('extraLg')]: {
        bottom: '12%',
      },
      [theme.breakpoints.up('extraXl')]: {
        bottom: '14%',
      },
      '& .vjs-text-track-cue': {
        background: 'rgba(0, 0, 0, 0.25) !important',
        padding: '20px 0',
        transform: 'translateY(-40px)',
        boxSizing: 'content-box',
        borderRadius: '20px',
        '& div': {
          background: 'none !important',
        },
      },
    },
  },
  toolBar: {
    position: 'absolute',
    zIndex: 100,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    height: '15%',
    padding: props => props.topBarPadding ? `0 ${props.topBarPadding}px` : '0 10px',
    borderRadius: '0 0 19px 19px',
    background: 'rgba(255, 255, 255, .5)',
    [theme.breakpoints.up('extraLg')]: {
      height: '14%',
      padding: props => props.topBarPadding ? `0 ${props.topBarPadding + 10}px` : '0 10px',
    },
  },
  exitButton: {
    position: 'absolute',
    top: 0,
    right: '-70px',
    [theme.breakpoints.up('lg')]: {
      right: '-100px',
    },
    [`@media only screen  
      and (min-device-width: 768px)
      and (max-device-width: 1024px)
      and (-webkit-min-device-pixel-ratio: 1)
      `]: {
      right: '-70px'
    },
  },
  rightPanel: {
    '& div:nth-child(n)': {
      marginRight: props => props.rightPanelMargin ? `${props.rightPanelMargin}px` : '20px',
    },
    '& div:last-child': {
      marginRight: '0 !important',
    },
    [theme.breakpoints.up('lg')]: {
      '& div:nth-child(n)': {
        marginRight: props => props.rightPanelMargin ? `${props.rightPanelMargin + 4}px` : '24px',
      },
      '& div:last-child': {
        marginRight: '0 !important',
      },
    },
    [theme.breakpoints.up('extraLg')]: {
      '& div:nth-child(n)': {
        marginRight: props => props.rightPanelMargin ? `${props.rightPanelMargin + 12}px` : '32px',
      },
      '& div:last-child': {
        marginRight: '0 !important',
      },
    },

  },
}));

export { useStyles };
