import React from 'react';
import PropsTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { useStyles } from './style';

const ContextMenu = ({ menuItems, tipsTotalCount }) => {
  const classes = useStyles();

  const onLinkClick = disabled => (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <nav>
      <List className={classes.list}>
        {menuItems.map(({ label, path, disabled, indicator }) =>
          <ListItem className={classes.listItem} key={label}>
            <NavLink
              className={clsx(classes.listItemLink, { [classes.disabled]: disabled })}
              activeClassName={classes.active}
              to={path}
              onClick={onLinkClick(disabled)}
              data-label={label}
            >
              {label}
            </NavLink>
            {indicator && Boolean(tipsTotalCount) && <div className={classes.indicator}>{tipsTotalCount}</div>}
          </ListItem>,
        )}
      </List>
    </nav>
  );
};

ContextMenu.propTypes = {
  menuItems: PropsTypes.arrayOf(PropsTypes.shape({
    label: PropsTypes.string,
    path: PropsTypes.string,
    disabled: PropsTypes.bool,
    indicator: PropsTypes.bool,
  })),
  tipsTotalCount: PropsTypes.number.isRequired,
};

export {
  ContextMenu,
};
