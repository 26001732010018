import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';
import { FormatUtils } from '../../utils/FormatUtils'

const TimestampDisplay = ({currentTime, duration}) => {
  const classes = useStyles();
  const formatedTime = useMemo(() => FormatUtils.formatTimestamp(currentTime), [currentTime]);
  const formatedDuration = useMemo(() => FormatUtils.formatTimestamp(duration), [duration]);

  return (
      <p className={classes.text} data-testid='timestamp-display'>
        <span>{formatedTime}</span> | <span>{formatedDuration}</span>
      </p>
  )
}

TimestampDisplay.defaultProps = {
  duration: 0,
  currentTime: 0,
}

TimestampDisplay.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

export {
  TimestampDisplay,
};
