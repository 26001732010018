import { makeStyles } from '@material-ui/core/styles';

const useSideBySideStyles = makeStyles((theme) => ({
  pageWrapper: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
    },
    '& img': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      border: `10px solid ${theme.colors.outline}`,
      borderRadius: '30px',
    },
  },
  zoomContainer: {
    '& > button': {
      alignSelf: 'center',
    },
    width:'95%',
    margin: '0 auto',
    '& *': {
      userSelect: 'none',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
    },
    ['@media screen and  (max-height: 755px) and (min-width: 1281px) and (max-width: 1600px)']: {
      padding: '20px 4.4%'
    },
    ['@media screen and  (max-height: 560px) and (min-width: 1212px)']: {
      padding: '0 4.4%'
    },
  },
  container: {
    position: 'relative',
    width: 'calc(45% - 20px)',
    maxWidth: '630px',
    height: 'min-content',
    paddingBottom: '57.4%',
  },
  pagesContainer: {
    position: 'relative',
    width: 'calc((100vh - 120px) * 1.4)',
    '& > div:first-child': {
      margin: '0 12px 0 0',
    },
    '& > div:last-child': {
      margin: '0 0 0 12px',
    },
  },
  zoomInButton: {
    position: 'absolute',
    background: theme.colors.background.quaternary,
    bottom: '6px',
  },
}));

export {
  useSideBySideStyles,
};