import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: '#fff',
    width: '30px',
    height: '30px',
    fontSize: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    background: theme.colors.primary,
    '&:focus, &:hover': {
      background: theme.colors.primary,
    },
    '&:disabled': {
      border: `2px solid ${theme.colors.primary}`,
      background: '#fff',
      '& svg': {
        color: theme.colors.primary,
        fontSize: 'calc( 1em - 4px )',
      }
    },
    [theme.breakpoints.up('lg')]: {
      width: '35px',
      height: '35px',
      fontSize: '35px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '44px',
      height: '44px',
      fontSize: '44px',
    },
  },
  svgIcon: {
    fontSize: '1em',
  },
}));

export {
  useStyles,
};