import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import Grid from '@material-ui/core/Grid';

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import { TEXT_TRACK_MODES, PLAYER_EVENTS, KEYS_CODE, TEXT_TRACK_KINDS, WINDOW_EVENTS } from '../../constants/player';
import { options, changePlayerState, popoverInitState } from '../Player/assets';
import { useStyles } from '../Player/style';
import { ProgressSlider } from '../ProgressSlider';
import { TimestampDisplay } from '../TimestampDisplay';
import { PlayerButton, RoundedTextButton, ToggleButton } from '../Buttons';
import { VolumeButton } from '../VolumeButton';
import { SubtitlesButton } from '../SubtitlesButton';
import { VoiceOverButton } from '../VoiceOverButton';

const TipsVideoPlayer  = ({ volume, videoSrc, onPlayerDispose }) => {
  const videoRef = useRef(null);
  const playerData = useRef({});
  const [player, setPlayer] = useState(null);
  const [paused, setPaused] = useState(true);
  const [buffered, setBuffered] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [wasPaused, setWasPaused] = useState(false);
  const [newVolume, setNewVolume] = useState(1);
  const [textTracks, setTextTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([]);
  const [refreshPlayPauseState, setRefreshPlayPauseState] = useState(true);
  const [popoverState, setPopoverState] = useState(popoverInitState);
  const classes = useStyles({topBarPadding: 20, rightPanelMargin : 40});

  const canPlayHls = useMemo(() => videoRef.current?.canPlayType("application/x-mpegURL") === 'maybe' 
    || videoRef.current?.canPlayType("application/x-mpegURL") === 'probably', [videoRef, player])

  const textTracksKind = useMemo(() => canPlayHls ? TEXT_TRACK_KINDS.captions : TEXT_TRACK_KINDS.subtitles, [canPlayHls]);

  const onSpacePressHandler = useCallback((e) => {
    if (e.code === KEYS_CODE.space) {
      e.stopPropagation();
      e.preventDefault();
      changePlayerState(player);
    }
  }, [player, changePlayerState]);

  const onKeyDown = useCallback((e) => {
    const duration = player.duration();
    const current = player.currentTime();
    const paused = player.paused();

    if (!e.ctrlKey && !e.metaKey) {
      switch (e.code) {
        case KEYS_CODE.arrowLeft:
          e.stopPropagation();
          setCurrentTime(time => time <= 0 ? time : time - 5 < 0 ? 0 : time - 5);
          if(!paused) {
            player.pause();
            setRefreshPlayPauseState(false);
          }
          player.currentTime(current - 5);
          player.one(PLAYER_EVENTS.canplaythrough, () => {
            if(!paused) {
              player.play().then(() => {
                setRefreshPlayPauseState(true);
              });
            }
          });
          break;
        case KEYS_CODE.arrowRight:
          e.stopPropagation();
          setCurrentTime(time => duration < time ? time : time + 5 > duration ? duration : time + 5);
          player.currentTime(current + 5);
          break;
        case KEYS_CODE.keyM:
          e.stopPropagation();
          if (player.volume() > 0) {
            player.volume(0);
          } else {
            player.volume(playerData.current.volume || 1);
          }
          break;
      }
    }
  }, [setCurrentTime, player, playerData, setRefreshPlayPauseState]);

  const onVideoClickHandler = useCallback(() => {
    changePlayerState(player);
  }, [player, changePlayerState]);

  const onPlayPauseClick = useCallback((e) => {
    changePlayerState(player);
    e.currentTarget.blur();
  }, [player, changePlayerState]);

  const onSliderChange = useCallback((e, value) => {
    setCurrentTime(value);
    player.currentTime(value);

    if (e && e.target instanceof Element && !(e.target instanceof HTMLDocument)) {
      e.target.blur();
    }
  }, [player, setCurrentTime]);

  const onForwardClick = useCallback(() => {
    player.currentTime(player.duration());
  }, [player]);

  const onBackwardClick = useCallback(() => {
    player.currentTime(0);
  }, [player]);

  const onPlusFiveClick = useCallback(() => {
    const duration = player.duration();
    const current = player.currentTime();

    setCurrentTime(time => duration < time ? time : time + 5 > duration ? duration : time + 5);
    player.currentTime(current + 5);
  }, [player, setCurrentTime]);

  const onMinusFiveClick = useCallback(() => {
    const current = player.currentTime();

    setCurrentTime(time => time <= 0 ? time : time - 5 < 0 ? 0 : time - 5);
    player.currentTime(current - 5)
  }, [player, setCurrentTime]);

  const onVolumeChange = useCallback((e, value) => {
    player.volume(value);
    playerData.current.volume = value;

    if (e && e.target instanceof Element && !(e.target instanceof HTMLDocument)) {
      e.target.blur();
    }
}, [player, setNewVolume, playerData]);

  const onLoadedData = useCallback(() => {

    let textTracks = Array
      .from(player.textTracks())
      .filter( track => track.kind === textTracksKind)
    let audioTracks = Array.from(player.audioTracks()).sort((a, b) => (a.id > b.id) ? 1 : -1);

    textTracks.forEach(track => track.mode = TEXT_TRACK_MODES.hidden);

    setAudioTracks(audioTracks);
    setTextTracks(textTracks);

  }, [player, setTextTracks, setAudioTracks, textTracksKind]);

  const onTextTrackChange = useCallback(state => {
      const labels = Object.keys(state);
      const tracks = player.textTracks();
      Array.from(tracks).filter(track => track.kind === textTracksKind).forEach((track, i) => track.mode = track.label === labels[i] && state[labels[i]] ? TEXT_TRACK_MODES.showing : TEXT_TRACK_MODES.disabled);
  }, [player, textTracksKind]);

  const onAudioTrackChange = useCallback((id) => {
    const tracks = player.audioTracks();
    Array.from(tracks).find(track => track.id === id).enabled = true;
  }, [player])

  const onPopoverOpen = useCallback((popover) => {
    setPopoverState(prevState => ({...popoverInitState, [popover]: !prevState[popover]}))
      if (player.paused()) {
        setWasPaused(true);
      } else {
        setWasPaused(false);
      }
  }, [player, setWasPaused, setPopoverState]);

  const onPopoverClose= useCallback(() => {
    setPopoverState(popoverInitState);
    if (!wasPaused) {
      player.play();
    }
  }, [player, wasPaused, setPopoverState]);

  const onLoadedMetadata = useCallback(() => {
    setDuration(player.duration());
  }, [player, setDuration]);

  const onEnded = useCallback(() => {
    player.pause();
    setPaused(true);
  }, [player, setPaused]);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(player.currentTime());
  }, [player, setCurrentTime]);

  const onProgress = useCallback(() => {
    setBuffered(player.bufferedEnd());
  }, [player, setBuffered]);

  const onReady = useCallback(() => {
    player.src({
      src: videoSrc,
      type: 'application/x-mpegURL',
      withCredentials: false,
    })
  }, [player])

  const onPlayerTextTrackChange = useCallback(() => {
    if (player.textTracks().length) {
      setTextTracks(Array.from(player.textTracks()).filter(track => track.kind === textTracksKind));
    }
  },[player, setTextTracks, textTracksKind]);

  const onPlayerAudioTrackChange = useCallback(() => {
    if (player.audioTracks().length) {
      setAudioTracks(Array.from(player.audioTracks()).sort((a, b) => (a.id > b.id) ? 1 : -1));
    }
  }, [player, setAudioTracks]);

  const onPlay = useCallback(() => {
    if (refreshPlayPauseState){
      setPaused(false);
    }
  }, [setPaused, refreshPlayPauseState]);

  const onPause = useCallback(() => {
    if (refreshPlayPauseState){
      setPaused(true);
    }
  }, [setPaused, refreshPlayPauseState]);

  const onLoadStart = useCallback(() => {
    player.autoplay(false);
  }, [player])

  const onDispose = useCallback(() => {
    onPlayerDispose(
      player.volume(),
    );
  }, [player, onPlayerDispose]);

  const onPlayerVolumeChange = useCallback(() => {
    setNewVolume(player.volume());
  }, [player, setNewVolume]);

  useEffect(() => {
    const videoPlayer = videojs(videoRef.current, options);
    setPlayer(videoPlayer);
    return () => {
      videoPlayer.dispose();
    };
  }, []);

  useEffect(() => {
    if (player) {
      setNewVolume(volume);
      player.volume(volume);
    }
  }, [player, volume, setNewVolume]);

  useEffect(() => {
    if (player) {
      player.on(PLAYER_EVENTS.loadedmetadata, onLoadedMetadata);
      player.on(PLAYER_EVENTS.ended, onEnded);
      player.on(PLAYER_EVENTS.timeupdate, onTimeUpdate);
      player.on(PLAYER_EVENTS.progress, onProgress);
      player.on(PLAYER_EVENTS.play, onPlay);
      player.on(PLAYER_EVENTS.pause, onPause);
      player.on(PLAYER_EVENTS.dispose, onDispose);
      player.textTracks().on(PLAYER_EVENTS.change, onPlayerTextTrackChange );
      player.audioTracks().on(PLAYER_EVENTS.change, onPlayerAudioTrackChange);
      player.on(WINDOW_EVENTS.click, onVideoClickHandler);
      player.on(PLAYER_EVENTS.loadstart, onLoadStart);
      player.on(PLAYER_EVENTS.volumechange, onPlayerVolumeChange);
      document.addEventListener(WINDOW_EVENTS.keypress, onSpacePressHandler);
      document.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
    return () => {
      if (player) {
        player.off(PLAYER_EVENTS.loadedmetadata, onLoadedMetadata);
        player.off(PLAYER_EVENTS.ended, onEnded);
        player.off(PLAYER_EVENTS.timeupdate, onTimeUpdate);
        player.off(PLAYER_EVENTS.progress, onProgress);
        player.off(PLAYER_EVENTS.play, onPlay);
        player.off(PLAYER_EVENTS.pause, onPause);
        player.off(PLAYER_EVENTS.dispose, onDispose);
        player.textTracks().off(PLAYER_EVENTS.change, onPlayerTextTrackChange );
        player.audioTracks().off(PLAYER_EVENTS.change, onPlayerAudioTrackChange);
        player.off(WINDOW_EVENTS.click, onVideoClickHandler);
        player.off(PLAYER_EVENTS.loadstart, onLoadStart);
        player.off(PLAYER_EVENTS.volumechange, onPlayerVolumeChange);
        document.removeEventListener(WINDOW_EVENTS.keypress, onSpacePressHandler);
        document.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
      }
    };
  }, [player, onLoadedMetadata, onEnded, onTimeUpdate, onProgress, onPlay, onPause, onPlayerTextTrackChange, onPlayerAudioTrackChange, onVideoClickHandler, onSpacePressHandler, onKeyDown]);

  useEffect(() => {
    if (player) {
      player.one(PLAYER_EVENTS.loadeddata, onLoadedData)
    }
    return () => {
      if (player) {
        player.off(PLAYER_EVENTS.loadeddata, onLoadedData)
      }
    }
  }, [player, onLoadedData])

  useEffect(() => {
    if (player) {
      player.on(PLAYER_EVENTS.timeupdate, onTimeUpdate);
    }
    return () => {
      if (player) {
        player.off(PLAYER_EVENTS.timeupdate, onTimeUpdate);
      }
    }
  }, [player, onTimeUpdate]);

  useEffect(() => {
    if (player) {
      player.ready(onReady);
    }
  }, [player, onReady])

  return (
    <div className={classes.playerWrapper}>
      <video
        ref={videoRef}
        id={'video-player'}
        className={`video-js ${classes.videoPlayer}`}
        data-testid='video-player'
      ></video>
      <div className={classes.toolBar}>
        <Grid container justify="space-around" alignItems="center">
          <Grid item xs={4}>
              <TimestampDisplay currentTime={currentTime} duration={duration}/>
          </Grid>
          <Grid item xs={4}>
            <Grid container justify="space-around" alignItems="center">
              <PlayerButton onClick={onBackwardClick} icon={SkipPreviousIcon} fontSize={0.8}/>
              <RoundedTextButton text="-5" onClick={onMinusFiveClick}/>
              <ToggleButton
                big
                data-testid='play-pause'
                iconOnTrue={PlayArrowIcon}
                iconOnFalse={PauseIcon}
                flag={paused}
                onClick={onPlayPauseClick}
              />
              <RoundedTextButton text="+5" onClick={onPlusFiveClick}/>
              <PlayerButton onClick={onForwardClick} icon={SkipNextIcon} fontSize={0.8}/>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container justify='flex-end' alignItems="center" classes={{root: classes.rightPanel}}>
            <div>
                <VoiceOverButton
                  disabled
                  audioTracks={audioTracks}
                  open={popoverState.voiceOver}
                  onChange={onAudioTrackChange}
                  onOpen={onPopoverOpen}
                  onClose={onPopoverClose}
                />
              </div>
              <div>
                <SubtitlesButton
                  centered
                  disabled={!textTracks.length}
                  textTracks={textTracks}
                  open={popoverState.subtitles}
                  onChange={onTextTrackChange}
                  onOpen={onPopoverOpen}
                  onClose={onPopoverClose}
                />
              </div>
              <div>
                <VolumeButton
                  right
                  volume={newVolume} 
                  open={popoverState.volume}
                  onOpen={onPopoverOpen}
                  onClose={onPopoverClose} 
                  onChange={onVolumeChange} 
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <ProgressSlider currentTime={currentTime} duration={duration} buffered={buffered} onChange={onSliderChange}/>
      </div>
    </div>
  )
}

TipsVideoPlayer .propTypes = {
  volume: PropTypes.number.isRequired,
  videoSrc: PropTypes.string.isRequired,
  onPlayerDispose: PropTypes.func.isRequired,
}

export {
  TipsVideoPlayer ,
};
