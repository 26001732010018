import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modalContent: {
    height: '100%',
  },
  controlButtons: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  leftColumn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '40%',
    margin: '0 35px 0 0',
    padding: '10px',
    background: theme.colors.background.primary,
    borderRadius: '20px',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 55px 0 0',
    },
  },
  thumbnailHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  partName: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: props => props.informationTabControl ? '0 100px 0 0' : '0 40px 0 0',
    fontSize: '1.5rem',
    fontWeight: 700,
    '& svg': {
      margin: '0 0 0 20px',
      color: theme.colors.primary,
      fontSize: '3.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem',
    },
  },
  partProperty: {
    margin: '0 0 10px',
    fontSize: '1.375rem',
    fontWeight: 500,
    '&:last-child': {
      margin: 0,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
      margin: '0 0 15px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.75rem',
      margin: '0 0 30px',
    },
  },
  partPropertyLabel: {
    display: 'inline-block',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1.25rem',
    fontWeight: 500,
  },
}));

export {
  useStyles,
};