const PDF = 'PDF';
const VIDEO_KAPITEL = 'VIDEO_KAPITEL';

const TABS = {
  PDF,
  VIDEO_KAPITEL,
}

const TABS_LABELS = {
  PDF: 'PDF',
  VIDEO_KAPITEL: 'Video Kapitel',
}

export {
  TABS,
  TABS_LABELS,
}