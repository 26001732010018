import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConstructionTime } from '../../components/ProductTabs/ConstructionTime';

import { chaptersActions } from '../../redux/chapters/actions';
import { pdfActions } from '../../redux/pdf/actions';

import { selectChapterConstructionTimeList, selectChapterTimestamps, selectCurrentChapter, selectCurrentChapterNumber } from '../../redux/chapters/selectors';
import { selectCurrentPage, selectCurrentPageNumber, selectPdfConstructionTimeList, selectPdfTimestamps } from '../../redux/pdf/selectors';
import { selectCurrentTab } from '../../redux/product/selectors';
import { useParams } from 'react-router-dom';

const PlayerConstructionTimeView = ()=> {
  const dispatch = useDispatch();
  const { id } = useParams();
  const pdfConstructionTimeList = useSelector(selectPdfConstructionTimeList);
  const chapterConstructionTimeList = useSelector(selectChapterConstructionTimeList);
  const currentTab = useSelector(selectCurrentTab)
  const currentPage = useSelector(selectCurrentPage);
  const currentChapter = useSelector(selectCurrentChapter);
  const currentPageNumber = useSelector(selectCurrentPageNumber);
  const currentChapterNumber = useSelector(selectCurrentChapterNumber);
  const pdfTimestamps = useSelector(selectPdfTimestamps);
  const chapterTimestamps = useSelector(selectChapterTimestamps);

  const onChapterChange = useCallback(
    (chapter) => {
      dispatch(chaptersActions.changeCurrentChapter(chapter));
    },
    [dispatch],
  );

  const onPdfChange = useCallback(
    (page) => {
      dispatch(pdfActions.changeCurrentPage(page));
    },
    [dispatch],
  );

  const onRestoreData = useCallback(
    (chapter, page) => {
      dispatch(chaptersActions.changeCurrentChapter(chapter));
      dispatch(pdfActions.changeCurrentPage(page));
    },
    [dispatch],
  );

  return (
      <ConstructionTime
        currentPdf={currentPage}
        currentChapter={currentChapter}
        currentChapterNumber={currentChapterNumber}
        currentPageNumber={currentPageNumber}
        pdfConstructionTimeList={pdfConstructionTimeList}
        chapterConstructionTimeList={chapterConstructionTimeList}
        currentTab={currentTab}
        pdfTimestamps={pdfTimestamps}
        chapterTimestamps={chapterTimestamps}
        onChapterChange={onChapterChange}
        onRestoreData={onRestoreData}
        onPdfChange={onPdfChange}
        productId={id}
      />
  )
}

export {
  PlayerConstructionTimeView,
};
