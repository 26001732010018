import { BaseService } from '../BaseService';

import { API_ROUTES } from '../../constants/routes';

class ProductService extends BaseService {
  constructor(url) {
    super(url);

    this.get = this.get.bind(this);
    this.getAll = this.getAll.bind(this);
  }

  async get(id, options = {}) {
    const url = `/${id}`;
    try {
      const response = await this.agent.get(url);

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }

  async getAll(params, options = {}) {
    try {
      const response = await this.agent.get('', { params });

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }
}

const ServiceInstance = new ProductService(API_ROUTES.products);

export { ServiceInstance as ProductService };
