import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: '5px',
    [theme.breakpoints.up('extraLg')]: {
      padding: '10px',
    },
  },
}));

export {
  useStyles,
};

