import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.colors.primary,
    width: props => props.big ? '50px' : '30px',
    height: props => props.big ? '50px' : '30px',
    fontSize: props => props.big ? '50px' : '30px',
    padding: '0',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      width: props => props.big ? '55px' : '35px',
      height: props => props.big ? '55px' : '35px',
      fontSize: props => props.big ? '55px' : '35px',
    },
    [theme.breakpoints.up('extraXl')]: {
      width: props => props.big ? '60px' : '40px',
      height: props => props.big ? '60px' : '40px',
      fontSize: props => props.big ? '60px' : '40px',
    },
    [theme.breakpoints.up('xl')]: {
      width: props => props.big ? '65px' : '45px',
      height: props => props.big ? '65px' : '45px',
      fontSize: props => props.big ? '65px' : '45px',
    },
  },
  svgIcon: {
    fontSize: '1em',
    '&, & path': {
      fill: theme.colors.thirty,
    },
  },
  disabled: {
    color: `${theme.colors.thirty} !important`,
  },
}));

export {
  useStyles,
};
