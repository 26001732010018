import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modalContent: {
    height: '100%',
  },
  leftColumn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '40%',
    margin: '0 35px 0 0',
    padding: '40px',
    background: theme.colors.background.primary,
    borderRadius: '20px',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 55px 0 0',
    },
  },
  thumbnailHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  toolName: {
    margin: '10px 0',
    padding: '0 40px 0 0',
    fontSize: '1.5rem',
    fontWeight: 700,
    color: '#000',
    [theme.breakpoints.up('md')]: {
      margin: '15px 0',
      fontSize: '1.75rem',
    },
    [theme.breakpoints.up('xl')]: {
      margin: '30px 0',
      fontSize: '2rem',
    },
  },
  toolsProperty: {
    margin: '0 0 10px',
    fontSize: '1.375rem',
    fontWeight: 500,
    color: theme.colors.text.thirty,
    overflow: 'auto',
    '&:first-child': {
      overflow: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
      margin: '0 0 15px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.75rem',
      margin: '0 0 30px',
    },
  },
  buttonPosition: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
}));

export {
  useStyles,
};
