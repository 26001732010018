import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { Link, useParams, generatePath } from 'react-router-dom';

import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../constants/routes';

import { useModalStyles } from './styles';
import { PartsDetailsModal } from '../ProductTabs/PartsDetailsModal';

const mockPartsTip = {
  title: 'Verwechslungsgefahr',
  text: 'Um sicher zu sein, dass Sie das richtige Teil nachbestellen,' +
    ' schauen Sie sich bitte die Tipps in der Zusammenfassung an.',
};

const SparePartsDetailsModal = ({ isOpen, part, closeModal }) => {
  const classes = useModalStyles();
  const [isInformationTabOpen, setInformationTabState] = useState(false);
  const { id } = useParams();
  //tip for test
  //todo replace mock tip to tip from real data
  const tip = part.id % 2 ? mockPartsTip : undefined;

  const toggleInformationTab = useCallback(() => {
    setInformationTabState(state => !state);
  }, [setInformationTabState]);

  const informationTabControl = (
    <IconButton classes={{ root: classes.infoButton }} onClick={toggleInformationTab}>
      <InfoIcon/>
    </IconButton>
  );

  const additionalProps = tip ? {
    isInformationTabOpen,
    informationTabControl,
    informationTabContent: <>
      <div className={classes.infoTitle}>{tip.title}</div>
      <div className={classes.infoText}>{tip.text}</div>
      <Link to={generatePath(`${UI_ROUTES.summary}${CONTEXT_MENU_ROUTES.tips}`, { id })} className={classes.tipsLink}>Tipps
        anzeigen</Link>
    </>,
  } : {};

  return (
    <PartsDetailsModal
      isOpen={isOpen}
      part={part}
      closeModal={isInformationTabOpen ? toggleInformationTab : closeModal}
      {...additionalProps}
    />
  );
};

SparePartsDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  part: PropTypes.shape({
    id: PropTypes.string,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    quantity: PropTypes.number,
    partNumber: PropTypes.number,
  }),
  closeModal: PropTypes.func.isRequired,
};

export {
  SparePartsDetailsModal,
};
