import React from 'react';
import { ListImage } from '../../ListImage';
import { IndexNumber } from '../../IndexNumber';
import { FormatUtils } from '../../../utils/FormatUtils';

const listColumns = [
  {
    field: 'chapterIndex',
    label: 'Kapitel',
    maxWidth: '100px',
    align: 'center',
    // eslint-disable-next-line react/display-name
    valueGetter: (value) => <IndexNumber value={value} colorSchema="blue"/>,
  },
  {
    field: 'thumbnail',
    width: '10%',
    align: 'center',
    // eslint-disable-next-line react/display-name
    valueGetter: (value, item) => <ListImage imgSrc={value} alt={item.name} width='100%' filter='grayscale(100%)'/>,
  },
  { field: 'name', label: 'Name', width: '25%', },
  { field: 'pdfPages', label: 'PDF-Seiten im Kapitel', align: 'center', width: '25%', },
  { field: 'duration', label: 'Dauer', align: 'center', maxWidth: '290px', valueGetter: (value) => FormatUtils.formatTime(value)},
];

export {
  listColumns,
};
