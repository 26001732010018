import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { InputNumber } from './InputNumber';

const InputNumberField = ({ value, name, min, max, fieldPrefix, enableRemoveButtons, disableRemoveButtons, setFieldValue }) => {
  const fieldName = `${fieldPrefix}.${name}`;

  const onChange = (value) => {
    if (value === min) {
      setFieldValue(`${fieldPrefix}.orderReason`, '');
      disableRemoveButtons(fieldPrefix);
    } else {
      enableRemoveButtons(fieldPrefix);
    }

    setFieldValue(fieldName, value);
  };

  return (
    <Field
      name={fieldName}
      value={value}
      min={min}
      max={max}
      placeholder="Jane Doe"
      component={InputNumber}
      onChange={onChange}
    />
  );
};

InputNumberField.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  fieldPrefix: PropTypes.string,
  part: PropTypes.object,
  setFieldValue: PropTypes.func,
  enableRemoveButtons: PropTypes.func,
  disableRemoveButtons: PropTypes.func,
};

export {
  InputNumberField,
};