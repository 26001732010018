import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  selectContainer: {
    width: 'auto',
    background: '#f2f2f2',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  selectButton: {
    width: '40px',
    height: '40px',
    minWidth: 'unset',
    background: 'transparent',
    borderRadius: '10px',
    '& svg': {
      fontSize: '1.875rem',
      '& path': {
        fill: '#000',
      },
    },
    '&:hover, &:focus': {
      background: 'inherit',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50px',
      height: '50px',
    },
  },
  selected: {
    background: theme.colors.primary,
    color: '#fff',
    '& svg': {
      '& path': {
        fill: '#fff',
      },
    },
    '&:hover, &:focus': {
      background: theme.colors.primary,
    },
  },
  disabled: {
    opacity: 0.5,
  },
  big: {
    '& svg': {
      fontSize: '2.5rem',
    },
  }
}));

export {
  useStyles,
};