import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { ProductService } from '../../services/ProductService';

import { appActions } from '../app/actions';
import { productsActions } from './actions';
import { selectProduct } from '../product/selectors';

function* listLoad({ payload: { requestData = {} } }) {
  const { page, limit = 10 } = requestData;

  try {
    const { data, meta } = yield call(ProductService.getAll, requestData);
    const product = yield select(selectProduct);
    const filteredData = data.filter(({ id }) => id !== product.id);

    yield put(productsActions.listRefresh(
      { items: product.id ? [product, ...filteredData] : filteredData, page, limit },
      meta.page.totalItems,
    ));
  } catch (error) {
    yield put(appActions.setError(error));
    yield put(productsActions.listLoadFail());
  }
}

function* listLoadNextPage({ payload: { requestData = {} } }) {
  const { page, limit = 10 } = requestData;

  try {
    const { data, meta } = yield call(ProductService.getAll, requestData);
    const product = yield select(selectProduct);
    const filteredData = data.filter(({ id }) => id !== product.id);

    yield put(productsActions.listUpdate({ items: filteredData, page, limit }, meta.page.totalItems));
  } catch (error) {
    yield put(appActions.setError(error));
  }
}

export default function* productsSaga() {
  yield all([
    takeLatest(productsActions.LIST_LOAD, listLoad),
    takeLatest(productsActions.LIST_LOAD_NEXT_PAGE, listLoadNextPage),
  ]);
}

export {
  listLoad,
  listLoadNextPage,
};
