import { StoreUtils } from '../../utils/StoreUtils';

const prefix = '[Tools]';
const { createAction } = StoreUtils;

const TYPES = {
  LIST_LOAD: `${prefix} list-load`,
  LIST_REFRESH: `${prefix} list-refresh`,
  LIST_LOAD_FAIL: `${prefix} list-load-fail`,
  LIST_LOAD_NEXT_PAGE: `${prefix} list-load-next-page`,
  LIST_UPDATE: `${prefix} list-update`,
};

export const toolsActions = {
  ...TYPES,
  listLoad: createAction(TYPES.LIST_LOAD, 'productId', 'requestData'),
  listRefresh: createAction(TYPES.LIST_REFRESH, 'listData', 'totalItems'),
  listLoadFail: createAction(TYPES.LIST_LOAD_FAIL),
  listLoadNextPage: createAction(TYPES.LIST_LOAD_NEXT_PAGE, 'productId', 'requestData'),
  listUpdate: createAction(TYPES.LIST_UPDATE, 'listData', 'totalItems'),
};
