import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    overflow: 'hidden',
    borderRadius: '0',
    '& .MuiIconButton-label': {
      height: '100%',
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  icon: {
    width: '25px',
    height: '25px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '5px',
    background: '#fff',
    [theme.breakpoints.up('extraLg')]: {
      width: '36px',
      height: '36px',
      borderRadius: '10px',
    },
  },
  checkedIcon: {
    padding: '0 3px',
    color: '#fff',
    background: theme.colors.primary,
    border: '1px solid transparent',
  },
}));

export {
  useStyles,
};
