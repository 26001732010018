import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: '30px',
    height: '30px',
    fontSize: '30px',
    padding: '0',
    margin: '0 25px 0 0',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.up('extraLg')]: {
          margin: '0 35px 0 0',
    },
  },
  svgIcon: {
    fontSize: '30px',
    color: theme.colors.primary,
  },
}));

export {
  useStyles,
};
