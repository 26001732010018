import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generatePath, NavLink } from 'react-router-dom';

import { Loading } from '../Loading';
import { SectionTitle } from '../SectionTitle';
import { BackButton } from '../Buttons';

import { useStyles } from './styles';

import { UI_ROUTES } from '../../constants/routes';
import { FormatUtils } from '../../utils/FormatUtils';

const AssemblyVideos = ({
  items,
  totalItems,
  hasMoreItems,
  loading,
  loadListNextPage,
  onBackButtonClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <Grid container direction="column" wrap="nowrap" classes={{ root: classes.container }}>
        <Grid container justify="space-between" alignItems="center" classes={{ root: classes.header }}>
          <SectionTitle title="Videos" subtitle={totalItems ? FormatUtils.formatListItemsTotalCount(totalItems) : ''}/>
          <BackButton onClick={onBackButtonClick}/>
        </Grid>
        <div className={classes.contentWrapper}>
          <InfiniteScroll
            dataLength={items.length}
            next={loadListNextPage}
            hasMore={hasMoreItems}
            height="100%"
            loader={<div className={classes.loading} />}
          >
            <Grid container classes={{ root: classes.list }} component="ul">
              <Loading loading={loading}>
                {items.map(({ id, thumbnail, name, program, productNumber }, index) => (
                  <Grid
                    item
                    key={`${id}-${index}`}
                    component="li"
                    classes={{ root: classes.listItem }}
                  >
                    <NavLink
                      className={classes.listItemLink}
                      to={generatePath(UI_ROUTES.videoPlayer, { id })}
                    >
                      <div className={clsx(classes.listItemContent, { [classes.selected]: index === 0 })}>
                        <div className={classes.thumbnailHolder}>
                          <img src={thumbnail} alt={name}/>
                        </div>
                        <span className={classes.program}>{program}</span>
                        <span className={classes.name}>{name}</span>
                        <span className={classes.number}>{productNumber}</span>
                      </div>
                    </NavLink>
                  </Grid>
                ))}
              </Loading>
            </Grid>
          </InfiniteScroll>
        </div>
      </Grid>
    </div>
  );
};

AssemblyVideos.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    productNumber: PropTypes.string,
    program: PropTypes.string,
    videoId: PropTypes.string,
  })).isRequired,
  totalItems: PropTypes.number.isRequired,
  loadListNextPage: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
};

export {
  AssemblyVideos,
};