import { StoreUtils } from '../../utils/StoreUtils';

const prefix = '[Tips]';
const { createAction } = StoreUtils;

const TYPES = {
  LIST_TOPICS_LOAD: `${prefix} list-topic-reload`,
  LIST_TOPICS_REFRESH: `${prefix} list-topic-refresh`,
  LIST_TIPS_LOAD: `${prefix} list-tips-reload`,
  LIST_TIPS_REFRESH: `${prefix} list-tips-refresh`,
  EXPAND_TOPIC: `${prefix} expanded-topic`,
  COLLAPSE_TOPIC: `${prefix} collapse-topic`,
};

export const tipsActions = {
  ...TYPES,
  listTopicsLoad: createAction(TYPES.LIST_TOPICS_LOAD, 'productId', 'requestData'),
  listTopicsRefresh: createAction(TYPES.LIST_TOPICS_REFRESH, 'topics'),
  listTipsLoad: createAction(TYPES.LIST_TIPS_LOAD, 'productId', 'requestData'),
  listTipsRefresh: createAction(TYPES.LIST_TIPS_REFRESH, 'tips', 'topicId'),
  expandTopic: createAction(TYPES.EXPAND_TOPIC,'topic'),
  collapseTopic: createAction(TYPES.COLLAPSE_TOPIC,'topic'),
};
