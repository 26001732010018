import { partsActions } from '../parts/actions';
import { toolsActions } from './actions';

const initState = {
  list: [],
  page: 1,
  limit: 10,
  totalItems: 0,
  loading: false,
};

export default function toolsReducer(state = initState, { type, payload }) {
  switch (type) {
    case partsActions.LIST_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case toolsActions.LIST_REFRESH: {
      return {
        ...state,
        page: payload.listData.page,
        limit: payload.listData.limit,
        list: payload.listData.items,
        totalItems: payload.totalItems,
        loading: false,
      };
    }
    case toolsActions.LIST_UPDATE: {
      return {
        ...state,
        page: payload.listData.page,
        limit: payload.listData.limit,
        list: [...state.list, ...payload.listData.items],
        totalItems: payload.totalItems,
      };
    }
    case partsActions.LIST_LOAD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
