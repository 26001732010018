import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Parts } from '../../components/ProductTabs/Parts';

import { selectPartsBySize, selectPartsList } from '../../redux/parts/selectors';
import { partsActions } from '../../redux/parts/actions';

const SummaryPartsView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { items, page, totalItems, allItems, hasMoreItems } = useSelector(selectPartsList);
  const partsCount = useSelector(selectPartsBySize);

  const loadList = (loadAction) => (requestParams) => {
    dispatch(loadAction(id, requestParams));
  };

  return (
    <Parts
      page={page}
      productId={id}
      items={items}
      totalItems={totalItems}
      allItems={allItems}
      hasMoreItems={hasMoreItems}
      partsCount={partsCount}
      loadList={loadList(partsActions.listLoad)}
      loadListNextPage={loadList(partsActions.listLoadNextPage)}
    />
  );
};

export {
  SummaryPartsView,
};
