const PROFI_LEVELS = {
  beginner: 'Anfänger',
  handyman: 'Heimwerker',
  professional: 'Profi',
};

const PROFI_LEVELS_VIEW_MODE = {
  BEGINNER: 'beginner',
  HANDYMAN: 'handyman',
  PROFI: 'professional',
};

const GENERAL_INFO_LABEL = {
  productLabel: 'Zeit für gesamtes Produkt',
  chapterLabel: 'Zeit für aktuelles Kapitel',
  pdfLabel: 'Zeit für aktuelle PDF-Seite',
};

const PROGRESS_INFO_LABEL = {
  pdfProgressLabel: 'Aktuelle PDF-Seite',
  chapterProgressLabel: 'Aktuelle Kapitel',
};

const PRODUCT_LABEL = 'Möbel';

const PROGRESS_DETAILS_LABEL = {
  productLabel: 'Möbel',
  chapterLabel: 'Kapitel',
  pdfLabel: 'PDF',
}


export {
  PROFI_LEVELS,
  PROFI_LEVELS_VIEW_MODE,
  GENERAL_INFO_LABEL,
  PROGRESS_INFO_LABEL,
  PRODUCT_LABEL,
  PROGRESS_DETAILS_LABEL,
};
