import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ListCell } from './TipsListCell';
import { ToggleButton } from '../Buttons';
import { TipsSublist } from './TipsSublist';

import { useStyles as useListStyles } from '../ListView/styles';
import { useStyles } from './styles';

import { sublistColumns, listColumns } from './assets';

const listItemContentProps = {
  container: true,
  spacing: 1,
  wrap: 'nowrap',
  justify: 'space-between',
};

const TipsList = ({ topics, tips, expandedTopics, toggleTopic }) => {
  const sharedListClasses = useListStyles();
  const classes = useStyles();
  const defaultColumnWidth = `${(100 / listColumns.length)}%`;

  const getColumnValue = (field, item, isTopicExpanded) => {
    if (field === 'name') {
      return (
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          {item.name}
          <ToggleButton
            iconOnTrue={ExpandMore}
            iconOnFalse={ChevronRightIcon}
            flag={isTopicExpanded}
            onClick={() => toggleTopic(item.id)}
            disabled={!item.numberOfTips}
          />
        </Grid>
      );
    }

    if (field === 'numberOfTips') {
      return <div className={classes.indicator}>{item.numberOfTips}</div>;
    }

    return '';
  };

  const renderListItems = (item) => {
    const isTopicExpanded = expandedTopics.includes(item.id);
    const topicTips = isTopicExpanded && tips[item.id];

    return (
      <Grid key={`list-${item.id}`} component="li">
        <Grid
          classes={{ root: clsx(sharedListClasses.listItem, classes.listItem, { [classes.disabled]: !item.numberOfTips }) }}
          {...listItemContentProps}
        >
          {listColumns.map(({ field, label, width, ...props }) => (
            <ListCell
              item
              key={label}
              width={width || defaultColumnWidth}
              listCellClassName={classes.listCell}
              {...props}
            >
              {getColumnValue(field, item, isTopicExpanded)}
            </ListCell>
          ))}
        </Grid>
        {topicTips && <TipsSublist tips={topicTips} columns={sublistColumns}/>}
      </Grid>
    );
  };

  return (
    <Grid container className={clsx(sharedListClasses.listViewContainer, classes.listViewContainer)} direction="column">
      <Grid
        classes={{ root: clsx(sharedListClasses.listHeader, classes.listHeader) }}
        {...listItemContentProps}
      >
        {listColumns.map(({ label, width, ...props }) => (
          <ListCell
            item
            key={label}
            width={width || defaultColumnWidth}
            {...props}
          >{label}</ListCell>
        ))}
      </Grid>
      <div className={clsx(sharedListClasses.listWrapper, classes.listWrapper)}>
        <ul className={classes.listContainer}>
          {topics.map(renderListItems)}
        </ul>
      </div>
    </Grid>
  );
};

TipsList.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    numberOfTips: PropTypes.number.isRequired,
  })).isRequired,
  tips: PropTypes.object.isRequired,
  expandedTopics: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleTopic: PropTypes.func.isRequired,
};

export {
  TipsList,
};