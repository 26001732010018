import { productsActions } from './actions';

export const initState = {
  list: [],
  page: 1,
  limit: 10,
  totalItems: 0,
  loading: false,
};

export default function productsReducer(state = initState, { type, payload }) {
  switch (type) {
    case productsActions.LIST_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case productsActions.LIST_REFRESH: {
      return {
        ...state,
        page: payload.listData.page,
        limit: payload.listData.limit,
        list: payload.listData.items,
        totalItems: payload.totalItems,
        loading: false,
      };
    }
    case productsActions.LIST_UPDATE: {
      return {
        ...state,
        page: payload.listData.page,
        limit: payload.listData.limit,
        list: [...state.list, ...payload.listData.items],
        totalItems: payload.totalItems,
      };
    }
    case productsActions.LIST_LOAD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case productsActions.RESET_PRODUCTS_STATE: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}
