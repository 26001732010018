import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectButton: {
    height: '40px',
    background: '#FFF',
    border: '1px solid' + theme.colors.outline,
    borderRadius: '10px',
    padding: '0 15px',
    marginLeft: '20px',
    boxSizing: 'border-box',
    textTransform: 'none',
    '& svg': {
      fontSize: '1.875rem',
    },
    '&:hover, &:focus': {
      background: 'inherit',
    },
    [theme.breakpoints.up('xl')]: {
      height: '50px',
    },
  },
  selected: {
    background: theme.colors.primary,
    color: '#fff',
    border: `1px solid ${theme.colors.primary}`,
    '&:hover, &:focus': {
      background: theme.colors.primary,
    },
    '& $label': {
      textShadow: '0 0 .65px #fff, 0 0 .65px #fff',
    },
  },
  label: {

  }
}));

export {
  useStyles,
};