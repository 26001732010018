import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { history, store } from './redux/store';
import { ConnectedRouter } from 'connected-react-router';

import { Routes } from './components/Routes';
import { routes } from './routes';

import { theme } from './theme';
import { Redirect } from 'react-router';

window.onload = () => {
  if (document.documentElement.offsetWidth < 1024) {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const aspectRatio = screenWidth / screenHeight;
    const viewportWidth = 1024 * aspectRatio >= 1024 ?  1024 * aspectRatio : 1024;
    const scale = window.screen.availWidth / viewportWidth;

    document.getElementById("viewport").setAttribute("content", `width=${viewportWidth}, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale},`);
    window.addEventListener('orientationchange', () => {
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;
      const aspectRatio = screenWidth / screenHeight;
      const viewportWidth = 1024 * aspectRatio >= 1024 ?  1024 * aspectRatio : 1024;
      const scale = window.screen.availWidth / viewportWidth;

      document.getElementById("viewport").setAttribute("content", `width=${viewportWidth}, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale},`);
    })
  }
}

ReactDOM.render(
  <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Routes routes={routes}>
              <Redirect to='/product/1'/>
            </Routes>
          </ConnectedRouter>
        </Provider>
      </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
