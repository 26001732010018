import PropTypes from 'prop-types';
import React from 'react';
import PropsTypes from 'prop-types';

import { PDFPage } from '../PDFPage';
import { useStyles } from './style';

import { PAGE_TYPES } from '../../../constants/pdf';

const FollowingThumbnails = ({ followingThumbnails, allPdfs, isSliderActive, changeCurrentPage }) => {
  const classes = useStyles();

  return (
    <div className={classes.thumbnailList}>
      {followingThumbnails.map((pdfIndex) => {
          const page = allPdfs[pdfIndex];

          return (
            <div className={classes.thumbnailPage} key={pdfIndex}>
              <PDFPage
                isSliderActive={isSliderActive}
                pageType={PAGE_TYPES.THUMBNAIL_PAGE}
                imageSrc={page.thumbnail}
                currentPdfIndex={pdfIndex}
                onPageClick={changeCurrentPage}
              />
            </div>
          )
        }
      )}
    </div>
  );
};

FollowingThumbnails.propTypes = {
  allPdfs: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    thumbnail: PropTypes.string,
  })).isRequired,
  followingThumbnails: PropsTypes.arrayOf(PropsTypes.number).isRequired,
  isSliderActive: PropsTypes.bool.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
};

export {
  FollowingThumbnails,
};



