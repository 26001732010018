import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import { useStyles } from './styles';
import { KEYS_CODE, WINDOW_EVENTS } from '../../../constants/player';

const EscButton = ({ position, onClick }) => {

  const classes = useStyles();

  const onKeyDown = useCallback((e) => {
    if (e.code === KEYS_CODE.escape) {
      onClick();
    }
  }, [])

  useEffect(() => {
    window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    return () => {
      window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
  }, [onKeyDown])
  
  return (
    <IconButton classes={{ root: clsx(classes.iconButton, position), label: classes.label}} onClick={onClick} data-testid='esc-button'>
      <CloseIcon classes={{ root: classes.svgIcon}} />
      <span className={classes.text}>ESC</span>
    </IconButton>
  )
}

EscButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  position: PropTypes.string,
}

export{
  EscButton,
};
