import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { useStyles } from './styles';

const NavBar = ({ menuItems, isActive }) => {
  const classes = useStyles()
  return (
    <nav className={classes.nav}>
      {menuItems.map(({ label, path }) => (
        <NavLink
          className={classes.link}
          activeClassName={classes.activeLink}
          to={path}
          isActive={isActive(path)}
          key={path}
          data-label={label}
        >
          {label}
        </NavLink>
      ))}
    </nav>
  );
};

NavBar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  isActive: PropTypes.func.isRequired,
};

export { NavBar };
