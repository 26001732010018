import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ProductTabContent } from '../ProductTabContent';
import { SectionTitle } from '../../SectionTitle';
import { Select } from '../../Select';
import { PeopleList } from '../../PeopleList';

import { PROFI_LEVELS_VIEW_MODE } from '../../../constants/constructionTime';
import { profiLevelsButtons } from '../ConstructionTime/assets';
import { listColumns } from './assets';
import { FormatUtils } from '../../../utils/FormatUtils';
import { ConstructionTimeUtils } from '../../../utils/ConstructionTimeUtils';

const TotalConstructionTime = ({ allChapters, pdfTimestamps, chapterConstructionTimeList, ...rest }) => {
  const [viewMode, setViewMode] = useState(PROFI_LEVELS_VIEW_MODE.BEGINNER);

  const items = useMemo(() => {
    if (allChapters.length > 0 && chapterConstructionTimeList.length > 0) {
      return allChapters.map((chapter, index) => ({
        thumbnail: chapter.thumbnail,
        name: chapter.name,
        chapterIndex: index + 1,
        duration: ConstructionTimeUtils.getDuration(chapterConstructionTimeList, index, viewMode),
        pdfPages: pdfTimestamps.filter(pdf => pdf.startTime >= chapter.startTime && pdf.startTime < chapter.endTime).length
      }));
    }
    return [];
  }, [allChapters, viewMode, pdfTimestamps, chapterConstructionTimeList]);
  
  const totalTime = useMemo(() => FormatUtils.formatTime(ConstructionTimeUtils.calculateDuration(chapterConstructionTimeList, viewMode)), [chapterConstructionTimeList, viewMode])

  const title = <SectionTitle title="Aufbauzeit" subtitle={totalTime}/>;
  const controls = (
    <>
      <Select items={profiLevelsButtons} selectedValue={viewMode} setSelectValue={setViewMode} background='#FFF'/>
    </>
  );

  return (
    <ProductTabContent title={title} controls={controls}>
      <PeopleList 
        items={items}
        columns={listColumns}
        {...rest}
      />
    </ProductTabContent>
  )
}

TotalConstructionTime.propTypes = {
  allChapters: PropTypes.array,
  chapterConstructionTimeList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    time: PropTypes.string,
    duration: PropTypes.number.isRequired,
  }))),
  pdfTimestamps: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
  })),
}

export {
  TotalConstructionTime,
};
