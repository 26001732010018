import { productActions } from './actions';
import { TABS } from '../../constants/tabs';

const initState = {
  product: {
    id: null,
    name: '',
    number: '',
    program: '',
    videos: [],
    subtitles: [],
    companyLogo: '',
  },
  ui: {
    currentTab: TABS.PDF,
    previousTab: TABS.PDF,
    isSidebarDisabled: false,
  },
  loading: false,
};

export default function productReducer(state = initState, { type, payload }) {
  switch (type) {
    case productActions.FETCH_PRODUCT: {
      return {
        ...state,
        loading: true,
      };
    }
    case productActions.FETCH_PRODUCT_SUCCESS: {
      return {
        ...state,
        product: payload.product,
        loading: false,
        error: '',
      };
    }
    case productActions.FETCH_PRODUCT_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case productActions.SWITCH_TAB: {
      return {
        ...state,
        ui: {
          ...state.ui,
          currentTab: payload.tab,
        },
      };
    }
    case productActions.SET_PREVIOUS_TAB: {
      return {
        ...state,
        ui: {
          ...state.ui,
          previousTab: payload.tab,
        },
      };
    }
    case productActions.TOGGLE_SIDEBAR_SCREEN: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSidebarDisabled: payload.isSidebarDisabled,
        },
      };
    }
    default: {
      return state;
    }
  }
}
