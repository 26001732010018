import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { useCellStyles } from './styles';

const ListCurrentInfoCell = ({ value, width, bold, align}) => {
  const classes = useCellStyles({ width, bold, align });

  return <Grid item classes={{ root: classes.listCell }}>{value}</Grid>;
}

ListCurrentInfoCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.string,
  bold: PropTypes.bool,
  align: PropTypes.string,
}

export {
  ListCurrentInfoCell,
};
