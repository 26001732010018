import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PlayerButton, RoundedTextButton, RoundedToggleButton, SvgPlayerButton } from '../Buttons';
import { AudioDurationDisplay } from '../AudioDurationDisplay';
import { ProgressSlider } from '../ProgressSlider';
import { VolumeButton } from '../VolumeButton';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { RepeatIcon } from '../../assets/icons/RepeatIcon';

import { changePlayerState, popoverInitState } from './assets';
import { KEYS_CODE, PLAYER_EVENTS, WINDOW_EVENTS } from '../../constants/player';
import { useStyles } from './styles';

const TipsAudioPlayer = ({ storedVolume, onPlayerDispose, audio }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [paused, setPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [repeat, setRepeat] = useState(false);
  const [player, setPlayer] = useState(null);
  const [popoverState, setPopoverState] = useState(popoverInitState);

  const classes = useStyles({ repeat });

  const audioRef = useRef(null);
  const playerData = useRef({});

  const onSpacePressHandler = useCallback((e) => {
    if (e.code === KEYS_CODE.space) {
      e.stopPropagation();
      e.preventDefault(); 
      changePlayerState(player);
    }
  }, [player, changePlayerState]);

  const onKeyDown = useCallback((e) => {
    const duration = player.duration;
    const current = player.currentTime;
   
    if (!e.ctrlKey && !e.metaKey) {
      switch (e.code) {
        case KEYS_CODE.arrowLeft:
          e.stopPropagation();
          setCurrentTime(time => time <= 0 ? time : time - 5 < 0 ? 0 : time - 5);
          player.currentTime = current - 5;
          break;
        case KEYS_CODE.arrowRight:
          e.stopPropagation();
          setCurrentTime(time => duration < time ? time : time + 5 > duration ? duration : time + 5);
          player.currentTime = current + 5;
          break;
        case KEYS_CODE.keyM:
          e.stopPropagation();
          if (player.volume > 0) {
            player.volume = 0;
          } else {
            player.volume = playerData.current.volume || 1;
          }
          break;
      }
    }
  }, [setCurrentTime, player, playerData]);

  const onSliderChange = useCallback((e, value) => {
    setCurrentTime(value);
    player.currentTime = value;

    if (e && e.target instanceof Element && !(e.target instanceof HTMLDocument)) {
      e.target.blur();
    }
  }, [setCurrentTime, player]);

  const onPlayPauseClick = useCallback(() => {
    changePlayerState(player);
  }, [player]);

  const onPlusFiveClick = useCallback(() => {
    const duration = player.duration;
    const currentTime = player.currentTime;

    setCurrentTime(time => duration < time ? time : time + 5 > duration ? duration : time + 5);
    player.currentTime = currentTime + 5;
  }, [setCurrentTime, player]);

  const onMinusFiveClick = useCallback(() => {
    const currentTime = player.currentTime;

    setCurrentTime(time => time <= 0 ? time : time - 5 < 0 ? 0 : time - 5);
    player.currentTime = currentTime - 5;
  }, [player]);

  const onBackwardClick = useCallback(() => {
    player.currentTime = 0;
  }, [player])

  const onForwardClick = useCallback(() => {
    player.currentTime = player.duration;
  }, [player])

  const onVolumeChange = useCallback((e, value) => {
    setVolume(value);
    player.volume = value;
    playerData.current.volume = value;

    if (e && e.target instanceof Element && !(e.target instanceof HTMLDocument)) {
      e.target.blur();
    }
  }, [setVolume, player, playerData]);

  const onRepeatClick = useCallback(() => {
    setRepeat(repeat => !repeat);
  }, [setRepeat]);

  const onPopoverOpen = useCallback((popover) => {
    setPopoverState(prevState => ({...popoverInitState, [popover]: !prevState[popover]}))
  }, [player, setPopoverState]);

  const onPopoverClose= useCallback(() => {
    setPopoverState(popoverInitState)
  }, [setPopoverState]);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(player.currentTime);
  }, [setCurrentTime, player]);

  const onLoadedMetadata = useCallback(() => {
    setDuration(player?.duration);
  }, [setDuration, player]);

  const onPlay = useCallback(() => {
    setPaused(false);
  }, [setPaused]);

  const onPause = useCallback(() => {
    setPaused(true);
  }, [setPaused]);

  const onEnded = useCallback(() => {
    if (repeat) {
      player.currentTime = 0;
      player.play();
    }
  }, [player, repeat]);

  const onPlayerVolumeChange = useCallback(() => {
    setVolume(player.volume);
  }, [player, setVolume]);

  const onDispose = useCallback(() => {
    onPlayerDispose(player?.volume);
  }, [player, onPlayerDispose]);

  useEffect(() => {
    if (player && storedVolume) {
      setVolume(storedVolume);
      player.volume = storedVolume;
      playerData.current.volume = storedVolume;
    }
  }, [player, storedVolume, setVolume, playerData]);

  useEffect(() => {
    if (audio) {
      audioRef.current.src = audio;
      audioRef.current.preload = 'metadata';
      setPlayer(audioRef.current);
    }
  }, [audio]);

  useEffect(() => {
    return () => {
      onDispose();
    }
  }, [onDispose]);

  useEffect(() => {
    if (player) {
      player.addEventListener(PLAYER_EVENTS.timeupdate, onTimeUpdate);
      player.addEventListener(PLAYER_EVENTS.loadedmetadata, onLoadedMetadata);
      player.addEventListener(PLAYER_EVENTS.play, onPlay);
      player.addEventListener(PLAYER_EVENTS.pause, onPause);
      player.addEventListener(PLAYER_EVENTS.ended, onEnded);
      player.addEventListener(PLAYER_EVENTS.volumechange, onPlayerVolumeChange);
      document.addEventListener(WINDOW_EVENTS.keypress, onSpacePressHandler);
      document.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
    return () => {
      if (player) {
        player.removeEventListener(PLAYER_EVENTS.timeupdate, onTimeUpdate);
        player.removeEventListener(PLAYER_EVENTS.loadedmetadata, onLoadedMetadata);
        player.removeEventListener(PLAYER_EVENTS.play, onPlay);
        player.removeEventListener(PLAYER_EVENTS.pause, onPause);
        player.removeEventListener(PLAYER_EVENTS.ended, onEnded);
        player.removeEventListener(PLAYER_EVENTS.volumechange, onPlayerVolumeChange);
        document.removeEventListener(WINDOW_EVENTS.keypress, onSpacePressHandler);
        document.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
      }
    }
  }, [player, onTimeUpdate, onLoadedMetadata, onPlay, onPause, onEnded, onPlayerVolumeChange, onSpacePressHandler, onKeyDown]);

  return (
    <div className={classes.audioPlayer}>
      <Grid container justify='center'>
        <Grid item>
          <Typography variant='body1' classes={{root: classes.playerName}}>
            Audio
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify='center'>
        <Grid item>
          <Typography variant='body1' classes={{root: classes.tipsName}}>
            Vertauschte Seitenteile
          </Typography>
        </Grid>
      </Grid>
      <audio ref={audioRef} data-testid='audio-player'></audio>
      <div className={classes.controls}>
        <AudioDurationDisplay current={currentTime} duration={duration}/>
        <Grid container justify='center' alignItems='center'>
          <Grid item xs={2}>
            <Grid container justify='flex-start' alignItems='center'>
              <SvgPlayerButton 
                data-testid='repeat'
                viewBox="0 0 21 22" 
                icon={RepeatIcon} 
                onClick={onRepeatClick}
                buttonClasses={{ root: classes.iconButton }}
                svgClasses={{root: classes.svgIcon}}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item xs={4}>
                <Grid container justify='space-around' alignItems='center'>
                  <PlayerButton data-testid='backward' onClick={onBackwardClick} icon={SkipPreviousIcon} color='#000' fontSize={0.7}/>
                  <RoundedTextButton text="-5" onClick={onMinusFiveClick} size={48}/>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container justify='center' alignItems='center'>
                  <RoundedToggleButton
                    big
                    data-testid='play-pause'
                    fontSize={0.6}
                    iconOnTrue={PlayArrowIcon}
                    iconOnFalse={PauseIcon}
                    flag={paused}
                    onClick={onPlayPauseClick}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container justify='space-around' alignItems='center'>
                  <RoundedTextButton text="+5" onClick={onPlusFiveClick} size={48}/>
                  <PlayerButton data-testid='forward' onClick={onForwardClick} icon={SkipNextIcon} color='#000' fontSize={0.7}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container justify='flex-end' alignItems='center'>
              <VolumeButton
                right 
                audioPlayer 
                volume={volume}
                open={popoverState.volume} 
                onChange={onVolumeChange}  
                onOpen={onPopoverOpen}
                onClose={onPopoverClose}    
              />
            </Grid>
          </Grid>
        </Grid>
        <ProgressSlider currentTime={currentTime} duration={duration} onChange={onSliderChange} audioSlider/>
      </div>
    </div>
  )
}

TipsAudioPlayer .propTypes = {
  storedVolume: PropTypes.number.isRequired,
  onPlayerDispose: PropTypes.func.isRequired,
  audio: PropTypes.string.isRequired,
}

export {
  TipsAudioPlayer,
};
