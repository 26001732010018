import React from 'react';
import { viewModes } from '../../../constants/viewModes';

import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';

import { ListImage } from '../../ListImage';

const viewModesList = [
  { value: viewModes.GRID, icon: GridIcon },
  { value: viewModes.LIST, icon: ListIcon },
];

const listColumns = [
  {
    field: 'thumbnail',
    label: 'Bild',
    width: '15%',
    // eslint-disable-next-line react/display-name
    valueGetter: (value, item) => <ListImage imgSrc={value} alt={item.name} />,
  },
  { field: 'name', label: 'Name', width: '85%' },
];

export {
  viewModesList,
  listColumns,
};
