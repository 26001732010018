import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.colors.primary,
    width: props => props.big ? '63px' : '30px',
    height: props => props.big ? '63px' : '30px',
    fontSize: props => props.big ? '63px' : '30px',
    backgroundColor: theme.colors.primary,
    padding: '0',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
    [theme.breakpoints.up('extraXl')]: {
      width: props => props.big ? '88px' : '40px',
      height: props => props.big ? '88px' : '40px',
      fontSize: props => props.big ? '88px' : '40px',
    },
  },
  svgIcon: {
    fontSize: props => `${props.fontSize || 1}em`,
    color: '#FFF',
  },
  disabled: {
    color: `${theme.colors.primary} !important`,
  },
}));

export {
  useStyles,
};
