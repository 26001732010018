import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { ListCurrentInfoCell } from './ListCurrentInfoCell';
import { useStyles } from './styles';

const ListCurrentInfoView = ({columns, items}) => {
  const classes = useStyles();

  const renderListItems = (item, i) => (
    <Grid
      container
      key={i}
      component="li"
      justify="space-between"
      classes={{root: classes.listItem}}
    >
      {columns.map(({ field, valueGetter, width }) => {
          const value = item[field];
          const parsedValue = valueGetter ? valueGetter(value, item.name) : value;
          let bold;

          if (field.startsWith('time')) {
            bold = true;
          } else {
            bold = false;
          }

          return <ListCurrentInfoCell key={field} width={width} value={parsedValue} bold={bold}/>;
        },
      )}
    </Grid>
  );

  return (
    <Grid container className={classes.listViewContainer} direction="column">
      <Grid container classes={{ root: classes.listHeader }} justify="space-between">
        {columns.map(({ label, field, width }) => <ListCurrentInfoCell item key={field} width={width} value={label} align='flex-end'/>)}
      </Grid>
      <div className={classes.listWrapper}>
          <ul>
            {items?.map(renderListItems)}
          </ul>
      </div>
    </Grid>
  );
};

ListCurrentInfoView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    width: PropTypes.string,
    valueGetter: PropTypes.func,
  })).isRequired,
}

export {
  ListCurrentInfoView,
};
