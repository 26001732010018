import { TIPS_TYPE } from '../../constants/tips';

import { ReactComponent as DocumentsIcon } from '../../assets/icons/documents.svg';
import { ReactComponent as VideoCameraIcon } from '../../assets/icons/video-camera.svg';
import { ReactComponent as AudioIcon } from '../../assets/icons/audio.svg';

const columns = [
  { label: 'Thema', width: '28%' },
  { label: 'Kategorie', maxWidth: '160px' },
  { label: 'Kapitel', maxWidth: '160px' },
  { label: 'PDF-Seite', maxWidth: '160px' },
  { label: 'Medien',maxWidth: '180px' },
  { label: 'Einträge', align: 'center', maxWidth: '100px' },
];

const listFields = ['name', '', '', '', '', 'numberOfTips'];
const sublistFields = ['name', 'category', 'chapter', 'pdf', 'media'];

const listColumns = columns.map((column, index) => ({
  ...column,
  field: listFields[index],
}));

const sublistColumns = columns.map((column, index) => ({
  ...column,
  field: sublistFields[index],
}));

const mediaList = [
  { icon: DocumentsIcon, viewBox: '0 0 24 25', tipType: TIPS_TYPE.TEXT },
  { icon: AudioIcon, viewBox: '0 0 24 25', tipType: TIPS_TYPE.AUDIO },
  { icon: VideoCameraIcon, viewBox: '0 0 32 32', tipType: TIPS_TYPE.VIDEO },
];

export {
  listColumns,
  sublistColumns,
  mediaList,
};
