class AnimationsUtils {
  static scrollAnimate({duration, draw, timing, element, pos}) {

    const drawFunction = draw(element);
    let start = performance.now();
    let scrolled = pos;
  
    requestAnimationFrame(function animate(time) {
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;
      
      let progress = timing(timeFraction);
      let scrollTo = Math.round(scrolled * progress);
      scrolled -= Math.round(scrolled * progress);
      drawFunction(scrollTo);
  
      if (timeFraction < 1) {
        requestAnimationFrame(animate);
      }
  
    });
  }
  
  static circ(timeFraction) {
    return 1 - Math.sin(Math.acos(timeFraction));
  }
  
  static easeInOutCirc(timeFraction) {
    return timeFraction < 0.5
      ? (1 - Math.sqrt(1 - Math.pow(2 * timeFraction, 2))) / 2
      : (Math.sqrt(1 - Math.pow(-2 * timeFraction + 2, 2)) + 1) / 2;
  }

  static scrollElement(elem) {
    return (pos) => elem.scrollBy(0, pos);
  }

  static easeInOutCubic(x) {
    return +(x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2);
    }

}

export { AnimationsUtils };
