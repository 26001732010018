import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },
  header: {
    padding: '10px 20px 0 40px',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 20px 5px 40px',
    },
    [theme.breakpoints.up('extraLg')]: {
      padding: '15px 20px 10px 40px',
    },
    [theme.breakpoints.up('extaXl')]: {
      padding: '20px 20px 10px 40px',
    },
  },
  contentWrapper: {
    position: 'relative',
    height: 'calc(100% - 50px)',
    [theme.breakpoints.up('extraLg')]: {
      height: 'calc(100% - 65px)',
    },
    [theme.breakpoints.up('xl')]: {
      height: 'calc(100% - 75px)',
    },
    '& .infinite-scroll-component__outerdiv': {
      height: '100%',
    },
  },
  controlsContainer: {
    width: 'auto',
    '& > *': {
      margin: '0 0 0 20px',
    },
  },
}));

export {
  useStyles,
};