import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { UI_ROUTES } from '../../constants/routes';

import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { MainContent } from './MainContent';
import { MobileModal } from '../MobileModal';
import { MainHeader } from '../MainHeader';

import { playerActions } from '../../redux/player/actions';
import { productActions } from '../../redux/product/actions';
import { selectTipsTotalCount } from '../../redux/tips/selectors';
import { selectCompanyLogo, selectLoadingState } from '../../redux/product/selectors';

import { contextMenuItems } from './assets';
import { useStyles } from './style';

import { usePageMode } from '../../hooks/usePageMode';
import { selectFullscreenZoomState, selectZoomViewState } from '../../redux/pdf/selectors';
import { pdfActions } from '../../redux/pdf/actions';
import { chaptersActions } from '../../redux/chapters/actions';
import { TABS } from '../../constants/tabs';
import { Loading } from '../Loading';

const ProductPagesContainer = ({ children, pageBaseUrl, hideSecondHeader }) => {
  const isZoomView = useSelector(selectZoomViewState);
  const isFullscreenZoom = useSelector(selectFullscreenZoomState);
  const classes = useStyles({ hideSecondHeader, isZoomView, isFullscreenZoom });
  const dispatch = useDispatch();
  const { id } = useParams();
  const isFullscreen = usePageMode();
  const tipsTotalCount = useSelector(selectTipsTotalCount);
  const productLoading = useSelector(selectLoadingState);
  const companyLogo = useSelector(selectCompanyLogo);
  const isVideoPlayerPage = pageBaseUrl === UI_ROUTES.videoPlayer;

  const onExitClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      dispatch(playerActions.toggleFullscreenState(false));
    }
  }, [dispatch]);

  const onHomeButtonClick = useCallback(() => {
    dispatch(playerActions.resetPlayerState());
    dispatch(pdfActions.changeCurrentPage(0));
    dispatch(chaptersActions.changeCurrentChapter(0));
    dispatch(productActions.switchTab(TABS.PDF));
  }, [dispatch]);

  const closeZoomView = useCallback(() => {
    dispatch(pdfActions.toggleZoomViewState(false));
  }, [dispatch]);

  const refreshPlayer = useCallback(() => {
    dispatch(playerActions.refreshPlayer(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(productActions.fetchProduct(id));
  }, []);

  const menuItems = useMemo(() =>
    contextMenuItems.map(({ path, indicator, disabled, ...item }) => ({
      ...item,
      path: generatePath(`${pageBaseUrl}${path}`, { id }),
      indicator: isVideoPlayerPage ? indicator : false,
      disabled: pageBaseUrl ? disabled : true,
    })), [pageBaseUrl, isVideoPlayerPage, id]);

  const matches = useMediaQuery(theme => `${theme.breakpoints.down('md')} and (orientation: portrait)`);

  return (
    <Grid className={clsx(classes.root, { [classes.fullscreen]: isFullscreen, [classes.zoomView]: isZoomView })}>
      <MainHeader
        id={id}
        onHomeClick={onHomeButtonClick}
        closeZoomView={closeZoomView}
        refreshPlayer={refreshPlayer}
        companyLogo={companyLogo}
      />
      <Loading loading={productLoading}>
        {!isFullscreen && <Header menuItems={menuItems} tipsTotalCount={tipsTotalCount} />}
        <Grid container className={classes.container} wrap="nowrap" onClick={isFullscreen ? onExitClick : null}>
          <Grid container className={classes.contentWrapper}>
            {children}
          </Grid>
        </Grid>
      </Loading>
      {matches && <MobileModal />}
    </Grid>
  );
};

ProductPagesContainer.Sidebar = Sidebar;
ProductPagesContainer.MainContent = MainContent;

ProductPagesContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideSecondHeader: PropTypes.bool,
  pageBaseUrl: PropTypes.string,
};

export {
  ProductPagesContainer,
};