import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    margin: '0 0 7px 0',
    fontSize: '1.15rem',
    fontWeight: 'bold',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  labelPlacementStart: {
    margin: '7px 0px',
  },
}));

export { useStyles };