import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './styles';

const RoundedToggleButton = ({
  iconOnTrue: IconOnTrue,
  iconOnFalse: IconOnFalse,
  svgClass,
  flag,
  big,
  fontSize,
  onClick,
  ...rest
}) => {

  const classes = useStyles({ big, fontSize });
  const Icon = flag ? IconOnTrue : IconOnFalse;

  return (
    <IconButton
      disableRipple
      onClick={onClick}
      classes={{ root: classes.iconButton, disabled: classes.disabled }}
      {...rest}
    >
      <Icon className={svgClass} classes={{ root: classes.svgIcon }}/>
    </IconButton>
  );
};

RoundedToggleButton.propTypes = {
  iconOnTrue: PropTypes.elementType.isRequired,
  iconOnFalse: PropTypes.elementType.isRequired,
  flag: PropTypes.bool.isRequired,
  big: PropTypes.bool,
  fontSize: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  svgClass: PropTypes.string,
};

export {
  RoundedToggleButton,
};
