import { createSelector } from 'reselect';

const chapters = ({ Chapters }) => Chapters;

const selectChapters = createSelector(
  [chapters],
  ({ chapter, chapters }) => ({
    currentChapter: chapter,
    chaptersLength: chapters.length,
    allChapters: chapters,
  }),
);

const selectCurrentChapterNumber = createSelector(
  [chapters],
  ({ chapter }) => chapter,
);

const selectPreviousChapterNumber = createSelector(
  [chapters],
  ({ previousChapter }) => previousChapter,
);

const selectChapterLoadingState = createSelector(
  [chapters],
  ({ loading }) => loading,
);

const selectChapterConstructionTimeList = createSelector(
  [chapters],
  ({ chapters }) => chapters.map(chapter => chapter.constructionTimes),
);

const selectCurrentChapter = createSelector(
  [chapters],
  ({ chapters, chapter }) => chapters[chapter],
);

const selectChapterTimestamps = createSelector(
  [chapters],
  ({ chapters }) => chapters.map(({ startTime, endTime }) => ({
    startTime,
    endTime,
  })),
);
const selectPeopleList = createSelector(
  [chapters],
  ({ chapters }) => chapters.map(({ id, numberOfDocuments, name, numberOfPeople, difficultyLevel, orderDependency }) => ({
    chapterId: id,
    numberOfDocuments,
    name,
    numberOfPeople,
    difficultyLevel,
    orderDependency,
  })),
);

export {
  selectChapters,
  selectCurrentChapterNumber,
  selectPreviousChapterNumber,
  selectChapterLoadingState,
  selectChapterConstructionTimeList,
  selectCurrentChapter,
  selectChapterTimestamps,
  selectPeopleList,
};