import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    minWidth: props => `${props.size - 15 || 25}px`,
    width: props => `${props.size - 15 || 25}px`,
    height: props => `${props.size - 15 || 25}px`,
    borderRadius: '50%',
    padding: 0,
    background: theme.colors.thirty,
    fontSize: '.75rem',
    lineHeight: 1,
    '&:focus, &:hover': {
      background: theme.colors.thirty,
    },
    [theme.breakpoints.up('extraLg')]: {
      minWidth: props => `${props.size - 10 || 30}px`,
      width: props => `${props.size - 10 || 30}px`,
      height: props => `${props.size - 10 || 30}px`,
    },
    [theme.breakpoints.up('extraXl')]: {
      minWidth: props => `${props.size || 40}px`,
      width: props => `${props.size || 40}px`,
      height: props => `${props.size || 40}px`,
      fontSize: '1rem',
    },
  },
}));

export {
  useStyles,
};
