import { all, takeLatest, put, call } from 'redux-saga/effects';

import { ChaptersService } from '../../services/ChaptersService';
import { appActions } from '../app/actions';
import { chaptersActions } from './actions';

function* chaptersReload({ payload: { productId, requestData } }) {
  try {
    const { data } = yield call(ChaptersService.get, productId, requestData);

    yield put(chaptersActions.refreshChapters(data));
  } catch (error) {
    yield put(appActions.setError(error));
  }
}

export default function* chaptersSaga() {
  yield all([
    takeLatest(chaptersActions.RELOAD_CHAPTERS, chaptersReload),
  ]);
}

export {
  chaptersReload,
};