import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';

import { SvgPlayerButton } from '../Buttons';
import { ButtonsPopover } from '../ButtonsPopover';

import { isoLang } from '../../constants/language';
import { GENDERS } from '../../constants/player';
import { voiceOverLabels, voiceOverMenuItems, setInitState, POPOVER_NAME } from './assets';
import { ReactComponent as VoiceOverIcon } from '../../assets/icons/sound-bars.svg';
import { ReactComponent as CheckLineIcon } from '../../assets/icons/check-line.svg';

import { useStyles } from './styles';

const VoiceOverButton = ({audioTracks, changeVideoTrack, disabled, open, onChange, onOpen, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTrack, setSelectedTrack] = useState(setInitState);
  const classes = useStyles();

  const initState = useMemo(() => setInitState(), [voiceOverMenuItems]);

  const mockAudioTracks = useMemo(() => {
    if (audioTracks.length > 1) {
      return audioTracks;
    }

    return [{
      label: 'm',
      language: 'de',
      enabled: true,
      id: 1,
    }]
  }, [audioTracks])

  useEffect(() => {
    if (audioTracks.length || changeVideoTrack) { 
      const enabledTrack = mockAudioTracks.find(track => track.enabled);
      const label = voiceOverLabels[GENDERS[enabledTrack?.label]];
      const code = enabledTrack?.language;
      if (code && label) {
        setSelectedTrack({...initState, [code]: { ...initState[code], [label]: true }});
      }
    }
  }, [audioTracks, changeVideoTrack, setSelectedTrack, initState]);

  const handleListItemClick = (label, code, id) => () => {
    if(!selectedTrack[code][label]) {
      setSelectedTrack({...initState, [code]: { ...initState[code], [label]: true }});
      onChange(id);
    }
    onClose();
  };

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    onOpen(POPOVER_NAME);
  }, [setAnchorEl, onOpen]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);
  
  return (
    <>
      <SvgPlayerButton
        onClick={handleClick} 
        icon={VoiceOverIcon} 
        viewBox="0 0 50 50"  
        buttonClasses={{ root: classes.iconButton, disabled: classes.disabled}}
        svgClasses={{root: classes.svgIcon}}
        disabled={disabled}
      />
      <ButtonsPopover open={open} onClose={handleClose} anchorEl={anchorEl}>
        <p className={classes.title}>Sprecher/in</p>
        <List component="ul" classes={{root: classes.list}}>
          {voiceOverMenuItems.sort((a, b) => a.langCode.localeCompare(b.langCode)).map( ({langCode, gendersLabel}, index) => gendersLabel.map((genderLabel, labelIndex) => {
            const currentTrack = mockAudioTracks?.find(track => track.language === langCode && voiceOverLabels[GENDERS[track.label]] === genderLabel);
            let enableTrack, trackId;
            if (currentTrack) {
              enableTrack = true;
              trackId = currentTrack?.id;
            } else {
              return;
            }
            return (
              <ListItem
                selected={selectedTrack[langCode][genderLabel]}
                key={index + 1, labelIndex}
                classes={{root: classes.listItem, disabled: classes.listItemDisabled, selected: classes.listItemSelected}}
                disabled={!enableTrack}
              > 
                <Button
                  variant="text"
                  color="default"
                  disableRipple
                  disableFocusRipple
                  endIcon={selectedTrack[langCode][genderLabel] ? <CheckLineIcon/> : null}
                  onClick={handleListItemClick(genderLabel, langCode, trackId)}
                  classes={{root: classes.button, disabled: classes.disabledButton}}
                  disabled={!enableTrack}
                >
                  {`${isoLang[langCode].nativeName} ${genderLabel}`}
                </Button>
              </ListItem>
            )}
          ))}
        </List>
      </ButtonsPopover>
    </>
  )
}

VoiceOverButton.defaultProps = {
  changeVideoTrack: true,
}

VoiceOverButton.propTypes = {
  audioTracks : PropTypes.array.isRequired,
  changeVideoTrack: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired, 
}

export {
  VoiceOverButton,
};
