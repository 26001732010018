import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute !important',
    boxShadow: 'none',
  },
  paper: {
    position: 'relative',
    width: '60%',
    minWidth: '500px',
    maxWidth: '1150px',
    height: 'calc(100% - 140px)',
    maxHeight: '870px',
    margin: '70px 63px',
    padding: '20px',
    borderRadius: '20px',
    overflow: 'visible',
    [theme.breakpoints.up('extraLg')]: {
      height: 'calc(100% - 210px)',
      margin: '110px 93px',
    },
  },
  backDrop: {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  contentWrapper: {
    height: '100%',
  },
  content: {
    width: '100%',
    margin: '10px 0 0',
    padding: '0 30px',
    fontSize: '1.313rem',
    color: '#666666',
    overflow: 'auto',
  },
}));

export {
  useStyles,
};