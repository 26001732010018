import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  thumbnailList: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  thumbnailPage: {
    position: 'relative',
    width: '100%',
    maxWidth: 'calc(33.3% - 10px)',
    margin: '0 15px 0 0',
    paddingBottom: '40.25%',
    '&:last-child': {
      margin: 0,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'calc(33.3% - 13.3px)',
      margin: '0 20px 0 0',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(33.3% - 19.3px)',
      margin: '0 29px 0 0',
    },
    '& $currentPage': {
      borderRadius: '15px',
      borderWidth: '3px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
}));

export {
  useStyles,
};