import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AssemblyVideos } from '../../components/AssemblyVideos';

import { productsActions } from '../../redux/products/actions';
import { selectProductsList, selectLoadingState } from '../../redux/products/selectors';

const AssemblyVideosView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, page, hasMoreItems, totalItems } = useSelector(selectProductsList);
  const loading = useSelector(selectLoadingState);

  useEffect(() => {
    dispatch(productsActions.listLoad({ page: 1 }));

    return () => {
      dispatch(productsActions.resetProductsState());
    };
  }, []);

  const loadListNextPage = useCallback(() => {
    dispatch(productsActions.listLoadNextPage({ page: page + 1 }));
  }, [page]);

  const onBackButtonClick = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <AssemblyVideos
      items={items}
      totalItems={totalItems}
      hasMoreItems={hasMoreItems}
      loading={loading}
      loadListNextPage={loadListNextPage}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export {
  AssemblyVideosView,
};
