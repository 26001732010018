import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import Grid from '@material-ui/core/Grid';

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import { ProgressSlider } from '../ProgressSlider';
import { TimestampDisplay } from '../TimestampDisplay';
import { CurrentChapterDisplay } from '../CurrentChapterDisplay';
import { BackButton, PlayerButton, RoundedTextButton, ToggleButton } from '../Buttons';
import { VolumeButton } from '../VolumeButton';
import { PerspectiveButton } from '../PerspectiveButton';
import { SubtitlesButton } from '../SubtitlesButton';
import { VoiceOverButton } from '../VoiceOverButton';

import { useDiviceDetect } from '../../hooks/useDeviceDetect';

import { UI_ROUTES } from '../../constants/routes';
import {
  AUDIO_TRACK_KINDS,
  CHAPTER_DELAY,
  KEYS_CODE,
  PLAYER_EVENTS,
  TEXT_TRACK_KINDS,
  TEXT_TRACK_MODES,
  VIDEO_TRACK_LABEL,
  WINDOW_EVENTS,
} from '../../constants/player';
import { changePlayerState, changeTrackSelectedProp, options, popoverInitState } from './assets';
import { useStyles } from './style';

const Player = ({
  currentChapter,
  chaptersLength,
  allChapters,
  page,
  timestamps,
  isPlayerFullscreen,
  isZoomView,
  volume,
  videos,
  subtitles,
  lastCurrentTime,
  lastTextTrackId,
  lastVideoTrackId,
  lastAudioTrackId,
  refreshPlayer,
  onPdfChange,
  onPlayerDispose,
  onChapterChange,
  onFullscreenChange,
  deleteTextTrackId,
  deleteAudioTrackId,
  refreshState,
  isBurgerMenuOpen,
}) => {
  const videoRef = useRef(null);
  const prevData = useRef({
    prevChapter: null,
    prevPdf: null,
  });
  const clickCountRef = useRef({
    firstClick: null,
    secondClick: null,
  });
  const [player, setPlayer] = useState(null);
  const [paused, setPaused] = useState(true);
  const [buffered, setBuffered] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [wasPaused, setWasPaused] = useState(true);
  const [newVolume, setNewVolume] = useState(1);
  const [textTracks, setTextTracks] = useState([]);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [popoverState, setPopoverState] = useState(popoverInitState);
  const [refreshPlayerState, setRefreshPlayerState] = useState(false);

  const findPerspective = useCallback((currentTrackLabel) =>
      videoTracks.find(({ perspective }) => perspective === currentTrackLabel)?.availabilities
    , [videoTracks]);

  const [newAvailablePerspectives, setNewAvailablePerspectives] = useState(() => {
    let newValue = {};

    Object
    .values(VIDEO_TRACK_LABEL)
    .forEach(label => newValue[label] = findPerspective(label)?.some(elem => 0 >= elem.startTime && 0 <= elem.endTime));

    return newValue;
  });
  const [currentVideoTrack, setCurrentVideoTrack] = useState('main');
  const [changeVideoTrack, setChangeVideoTrack] = useState(false);
  const { isChrome } = useDiviceDetect();
  const { pathname } = useLocation();
  const classes = useStyles();

  const canPlayHls = useMemo(() => videoRef.current?.canPlayType('application/x-mpegURL') === 'maybe'
    || videoRef.current?.canPlayType('application/x-mpegURL') === 'probably', [videoRef, player]);

  const textTracksKind = useMemo(() => canPlayHls ? TEXT_TRACK_KINDS.subtitles : TEXT_TRACK_KINDS.subtitles, [canPlayHls]);

  const isPlayerPage = useMemo(() =>
      matchPath(pathname, { path: UI_ROUTES.videoPlayer, exact: true }),
    [pathname]);

  const somePopoverIsOpen = useMemo(() => Object.values(popoverState).some((state) => state), [popoverState]);

  const stopClickPropagation = (e) => {
    e.stopPropagation();
  };

  const onSpacePressHandler = useCallback((e) => {
    if (e.code === KEYS_CODE.space) {
      setRefreshPlayerState(true);
      let promise = changePlayerState(player);

      if (promise !== undefined) {
        promise
        .catch(() => player.pause());
      }
    }
  }, [player, changePlayerState, setRefreshPlayerState]);

  const onKeyDown = useCallback((e) => {
    const duration = player.duration();
    const current = player.currentTime();
    const paused = player.paused();

    if (!e.ctrlKey && !e.metaKey) {
      switch (e.code) {

        case KEYS_CODE.arrowLeft:
          setCurrentTime(time => time <= 0 ? time : time - 5 < 0 ? 0 : time - 5);

          if (isChrome && !paused) {
            player.pause();
            setRefreshPlayerState(false);
          }

          player.currentTime(current - 5);

          player.one(PLAYER_EVENTS.canplay, () => {

            if (isChrome && !paused) {
              const promise = player.play();

              if (promise !== undefined) {
                promise
                .then(() => {
                  setRefreshPlayerState(true);
                })
                .catch(() => {
                  player.pause();
                  setRefreshPlayerState(true);
                });
              }
            }
          });
          break;

        case KEYS_CODE.arrowRight:
          setCurrentTime(time => duration < time ? time : time + 5 > duration ? duration : time + 5);
          player.currentTime(current + 5);
          break;

        case KEYS_CODE.escape:
          onFullscreenChange(false);
          break;

        case KEYS_CODE.keyM:
          if (player.volume() > 0) {
            player.volume(0);
          } else {
            player.volume(prevData.current.volume || 1);
          }
          break;
      }
    }
  }, [setCurrentTime, player, onFullscreenChange, prevData, setRefreshPlayerState, isChrome]);

  const onVideoClickHandler = useCallback((e) => {
    if (!somePopoverIsOpen) {
      stopClickPropagation(e);
      const { firstClick, secondClick } = clickCountRef.current;

      if (!firstClick && !secondClick) {
        clickCountRef.current.firstClick = true;
        setTimeout(() => {
          const { firstClick, secondClick } = clickCountRef.current;

          if (firstClick && secondClick) {
            onFullscreenChange(!isPlayerFullscreen);
          } else {
            setRefreshPlayerState(true);
            let promise = changePlayerState(player);

            if (promise !== undefined) {
              promise
              .catch(() => player.pause());
            }
          }

          clickCountRef.current = {
            firstClick: null,
            secondClick: null,
          };
        }, 200);
      } else {
        clickCountRef.current.secondClick = true;
      }
    }
  }, [player, onFullscreenChange, setPaused, clickCountRef, isPlayerFullscreen, setRefreshPlayerState, somePopoverIsOpen]);

  const onPlayerClick = useCallback((e) => {
    setRefreshPlayerState(true);
    let promise = changePlayerState(player);

    if (promise !== undefined) {
      promise
      .catch(() => player.pause());
    }
    e.currentTarget.blur();
  }, [player, changePlayerState, setRefreshPlayerState]);

  const onSliderChange = useCallback((e, value) => {
    setCurrentTime(value);
    player.currentTime(value);

    if (e && e.target instanceof Element && !(e.target instanceof HTMLDocument)) {
      e.target.blur();
    }
  }, [player, setCurrentTime]);

  const onForwardClick = useCallback(() => {

    if (currentChapter !== chaptersLength - 1) {
      player.currentTime(allChapters[currentChapter + 1].startTime);
      setCurrentTime(allChapters[currentChapter + 1].startTime);
      player.trigger(PLAYER_EVENTS.timeupdate);
    }

  }, [player, allChapters, chaptersLength, currentChapter, setCurrentTime]);

  const onBackwardClick = useCallback(() => {

    if (player.currentTime() > 0) {

      if (currentChapter === 0) {
        player.currentTime(allChapters[currentChapter].startTime);
        setCurrentTime(allChapters[currentChapter].startTime);

      } else if (player.currentTime() > allChapters[currentChapter].startTime + CHAPTER_DELAY) {
        player.currentTime(allChapters[currentChapter].startTime);
        setCurrentTime(allChapters[currentChapter].startTime);

      } else {
        player.currentTime(allChapters[currentChapter - 1].startTime);
        setCurrentTime(allChapters[currentChapter - 1].startTime);
      }
      player.trigger(PLAYER_EVENTS.timeupdate);
    }
  }, [player, allChapters, currentChapter]);

  const onPlusFiveClick = useCallback(() => {
    const duration = player.duration();
    const current = player.currentTime();

    setCurrentTime(time => duration < time ? time : time + 5 > duration ? duration : time + 5);
    player.currentTime(current + 5);
  }, [player, setCurrentTime]);

  const onMinusFiveClick = useCallback(() => {
    const current = player.currentTime();
    const paused = player.paused();

    if (isChrome && !paused) {
      player.pause();
      setRefreshPlayerState(false);
    }

    player.currentTime(current - 5);

    player.one(PLAYER_EVENTS.canplay, () => {

      if (isChrome && !paused) {
        const promise = player.play();

        if (promise !== undefined) {
          promise
          .then(() => {
            setRefreshPlayerState(true);
          })
          .catch(() => {
            player.pause();
            setRefreshPlayerState(true);
          });
        }
      }
    });
  }, [player, setCurrentTime]);

  const onFullscreenClick = useCallback((e) => {
    e.stopPropagation();
    onFullscreenChange(!isPlayerFullscreen);
  }, [onFullscreenChange, isPlayerFullscreen]);

  const onEscClick = useCallback((e) => {
    if (e.currentTarget !== document) {
      e.stopPropagation();
    }
    onFullscreenChange(false);
  }, [onFullscreenChange]);

  const onVolumeChange = useCallback((e, value) => {
    setNewVolume(value);
    player.volume(value);
    prevData.current.volume = value;

    if (e && e.target instanceof Element && !(e.target instanceof HTMLDocument)) {
      e.target.blur();
    }
  }, [player, setNewVolume, prevData]);

  const onLoadedData = useCallback(() => {
    let textTracks = Array
    .from(player.textTracks())
    .filter(track => track.kind === textTracksKind);
    let audioTracks = Array.from(player.audioTracks()).sort((a, b) => (a.id > b.id) ? 1 : -1);

    if (lastTextTrackId && textTracks.length) {
      textTracks
      .forEach(track => track.mode = track.label === lastTextTrackId ? TEXT_TRACK_MODES.showing : TEXT_TRACK_MODES.disabled);
    } else if (textTracks.length) {
      textTracks.forEach((track) => track.mode = TEXT_TRACK_MODES.disabled);
    }

    if (lastAudioTrackId && audioTracks.length) {
      audioTracks
      .find(track => track.id === lastAudioTrackId).enabled = true;
    } else if (audioTracks.length) {
      audioTracks.forEach((track) => track.enabled = track.kind === AUDIO_TRACK_KINDS.main);
    }

    setAudioTracks(audioTracks);
    setTextTracks(textTracks);
    setChangeVideoTrack(false);
  }, [player, lastAudioTrackId, lastTextTrackId, deleteTextTrackId, deleteAudioTrackId, setTextTracks, setAudioTracks, setChangeVideoTrack]);

  const onVideoTrackChange = useCallback(async (label) => {
    const currentTime = player.currentTime();
    const newTrack = videos.find(track => track.perspective === label);
    const subtitleLabel = Array.from(player.textTracks()).find(track => track.mode === TEXT_TRACK_MODES.showing)?.label;
    const audioId = Array.from(player.audioTracks()).find(track => track.enabled)?.id;
    const paused = player.paused();
    const volume = player.volume();

    if (player.volume() !== 0.01) {
      prevData.current.volume = player.volume();
    }

    setChangeVideoTrack(true);
    setRefreshPlayerState(false);

    player.muted(true);
    player.volume(0);
    player.pause();

    const canvas = document.createElement('canvas');
    const poster = document.getElementById('poster');
    canvas.width = player.currentWidth();
    canvas.height = player.currentHeight();
    const ctx = canvas.getContext('2d');
    ctx.drawImage(player.tech({ IWillNotUseThisInPlugins: true }).el(), 0, 0, player.currentWidth(), player.currentHeight());
    player.poster('none');
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.borderRadius = '20px';
    canvas.style.zIndex = 11;
    poster.appendChild(canvas);

    player.loadMedia({
      src: newTrack.source,
      type: newTrack.type,
    }, () => {
      player.currentTime(currentTime);
      player.muted(true);
      player.volume(0.01);
      setCurrentTime(currentTime);
      setCurrentVideoTrack(label);
      setVideoTracks(changeTrackSelectedProp(videos, label));
      subtitles.forEach(track => {
        player.addRemoteTextTrack(track, false);
      });

      player.one(PLAYER_EVENTS.loadstart, () => {
        setCurrentTime(currentTime);
      });

      player.one(PLAYER_EVENTS.loadeddata, () => {

        let textTracks = Array
        .from(player.textTracks())
        .filter(track => track.kind === textTracksKind);
        let audioTracks = Array.from(player.audioTracks()).sort((a, b) => (a.id > b.id) ? 1 : -1);


        if (subtitleLabel && textTracks.length) {
          textTracks.forEach((track) => track.mode = track.label === subtitleLabel ? TEXT_TRACK_MODES.showing : TEXT_TRACK_MODES.disabled);
        }

        if (audioId && audioTracks.length) {
          audioTracks.forEach((track) => track.enabled = track.id === audioId);
        }

        setAudioTracks(audioTracks);
        setTextTracks(textTracks);
        setChangeVideoTrack(false);
      });

      player.one(PLAYER_EVENTS.canplay, () => {
        setCurrentTime(currentTime);
        player.currentTime(currentTime);

        if (paused) {
          setRefreshPlayerState(true);
          player.muted(false);
          player.volume(volume);
          player.pause();
        } else {
          let promise = player.play();
          if (promise !== undefined) {
            promise
            .catch(() => {
              setRefreshPlayerState(true);
              player.muted(false);
              player.volume(volume);
              player.pause();
            })
          }
        }
      });

      let timerId;

      player.one('seeked', () => {
        if (timerId) clearInterval(timerId);
        timerId = setTimeout(() => poster.removeChild(canvas), 300);
      });

      player.one(PLAYER_EVENTS.play, () => {
        setRefreshPlayerState(true);
        player.muted(false);
        setTimeout(() => {
          !player.isDisposed() && player.volume(volume);
        }, 2000);
      });
    });

  }, [player, videos, prevData, setCurrentVideoTrack, onLoadedData, setAudioTracks, setTextTracks, setChangeVideoTrack, setCurrentVideoTrack, setVideoTracks, setCurrentTime, canPlayHls]);

  const onTextTrackChange = useCallback(state => {
    const labels = Object.keys(state);
    const tracks = player.textTracks();
    Array.from(tracks).filter(track => track.kind === textTracksKind).forEach((track, i) => track.mode = track.label === labels[i] && state[labels[i]] ? TEXT_TRACK_MODES.showing : TEXT_TRACK_MODES.disabled);
  }, [player]);

  const onAudioTrackChange = useCallback((id) => {
    if (player.volume() !== 0.01) {
      prevData.current.volume = player.volume();
    }
    player.volume(0.01);

    const tracks = player.audioTracks();
    Array.from(tracks).find(track => track.id === id).enabled = true;
  }, [player]);

  const onPopoverOpen = useCallback((popover) => {
    setPopoverState(prevState => ({ ...popoverInitState, [popover]: !prevState[popover] }));
  }, [player, setWasPaused, setPopoverState]);

  const onPopoverClose = useCallback(() => {
    setPopoverState(popoverInitState);
  }, [player, wasPaused]);

  const onBurgerMenuOpen = useCallback(() => {
    if (player.paused()) {
      setWasPaused(true);
    } else {
      setWasPaused(false);
      player.pause();
    }
  }, [player, setWasPaused, setPaused]);

  const onBurgerMenuClose = useCallback(() => {
    if (!wasPaused) {
      const promise = player.play();

      if (promise !== undefined) {
        promise
        .catch(() => player.pause());
      }
    }
  }, [player, wasPaused]);

  const onLoadedMetadata = useCallback(() => {
    setDuration(player.duration());
  }, [player, setDuration]);

  const onEnded = useCallback(() => {
    player.pause();
  }, [player]);

  const onDispose = useCallback(() => {
    const currentTextTrack = Array
    .from(player.textTracks())
    .filter(track => track.kind === textTracksKind)
    .find(track => track.mode === TEXT_TRACK_MODES.showing);

    const currentAudioTrack = Array
    .from(player.audioTracks())
    .find(track => track.enabled);

    onPlayerDispose(
      player.currentTime(),
      player.volume(),
      currentTextTrack && currentTextTrack.label,
      currentVideoTrack,
      currentAudioTrack && currentAudioTrack.id,
    );
  }, [player, onPlayerDispose, currentVideoTrack]);

  const onTimeUpdate = useCallback(() => {
    const newCurrentTime = player.currentTime();
    let newChapter = allChapters.findIndex(((elem) => elem.startTime <= newCurrentTime && elem.endTime > newCurrentTime));
    let newPdf = timestamps.findIndex(((elem) => elem.startTime <= newCurrentTime && elem.endTime > newCurrentTime));
    const { prevPdf, prevChapter } = prevData.current;
    const canPlayCurrentPerspective = findPerspective(currentVideoTrack)?.some(elem => newCurrentTime >= elem.startTime && newCurrentTime <= elem.endTime);

    setCurrentTime(newCurrentTime);

    if (newChapter !== prevChapter && ~newChapter) {
      onChapterChange(newChapter);
      if (currentVideoTrack !== 'main') {
        onVideoTrackChange('main');
      }
    }

    if (newPdf !== prevPdf && ~newPdf) {
      onPdfChange(newPdf);
    }


    if (!canPlayCurrentPerspective && currentVideoTrack !== 'main') {
      onVideoTrackChange('main');
    }

    setNewAvailablePerspectives(prevValue => {
      let newValue = {};

      Object
      .values(VIDEO_TRACK_LABEL)
      .forEach(label => newValue[label] = findPerspective(label)?.some(elem => newCurrentTime >= elem.startTime && newCurrentTime <= elem.endTime));

      const arrayValues = Object.values(newValue);

      if (!Object.values(prevValue).every((value, i) => value === arrayValues[i])) {
        return newValue;
      }

      return prevValue;
    });

  }, [player, setCurrentTime, setNewAvailablePerspectives, allChapters, timestamps, onPdfChange, onChapterChange, onVideoTrackChange, currentVideoTrack, setCurrentVideoTrack, lastCurrentTime]);

  const onProgress = useCallback(() => {
    setBuffered(player.bufferedEnd());
  }, [player, setBuffered]);

  const onPlayerTextTrackChange = useCallback(() => {
    if (player.textTracks().length) {
      setTextTracks(Array.from(player.textTracks()).filter(track => track.kind === textTracksKind));
    }
  }, [player, setTextTracks, TEXT_TRACK_KINDS]);

  const onPlayerAudioTrackChange = useCallback(() => {
    if (player.audioTracks().length) {
      setAudioTracks(Array.from(player.audioTracks()).sort((a, b) => (a.id > b.id) ? 1 : -1));
    }

    setTimeout(() => !player.isDisposed() && player.volume(prevData.current.volume), 2000);
  }, [player, setAudioTracks, prevData]);

  const onPlay = useCallback(() => {
    if (refreshPlayerState) {
      setPaused(false);
    }
  }, [setPaused, refreshPlayerState]);

  const onPause = useCallback(() => {
    if (refreshPlayerState) {
      setPaused(true);
    }
  }, [setPaused, refreshPlayerState]);

  const onPlayerVolumeChange = useCallback(() => {
    if (refreshPlayerState) {
      setNewVolume(player.volume());
    }
  }, [player, setNewVolume, refreshPlayerState]);

  useEffect(() => {
    if (player && !player.isDisposed()) {
      if (!isPlayerPage || isZoomView) {
        player.pause();
      } else {
        if (player.currentTime() < timestamps[page].startTime || player.currentTime() >= timestamps[page].endTime) {
          player.currentTime(timestamps[page].startTime);
          player.trigger(PLAYER_EVENTS.timeupdate);
        }
      }
    }
  }, [player, page, timestamps, isPlayerPage, isZoomView]);

  useEffect(() => {

    if (player && !player.isDisposed()) {
      if (player.currentTime() < allChapters[currentChapter].startTime || player.currentTime() >= allChapters[currentChapter].endTime) {
        player.currentTime(allChapters[currentChapter].startTime);
        player.trigger(PLAYER_EVENTS.timeupdate);
      }
    }
  }, [player, currentChapter, allChapters]);

  useEffect(() => {
    prevData.current.prevPdf = page;
    prevData.current.prevChapter = currentChapter;
  }, [page, currentChapter, prevData]);

  useEffect(() => {
    if (player) {
      const currentSubtitles = Array.from(player.textTracks()).find(track => track.mode === TEXT_TRACK_MODES.showing);

      if (currentSubtitles) {
        currentSubtitles.mode = TEXT_TRACK_MODES.hidden;
        currentSubtitles.mode = TEXT_TRACK_MODES.showing;
      }
    }
  }, [player, isPlayerFullscreen]);

  useEffect(() => {
    if (player && refreshState) {
      Array.from(player.textTracks()).forEach(track => track.mode = TEXT_TRACK_MODES.hidden);
      Array.from(player.audioTracks()).forEach(track => track.enabled = track.kind === AUDIO_TRACK_KINDS.main);
      player.pause();
      player.currentTime(0);
      player.volume(1);
      setPaused(true);
      onVideoTrackChange('main');
      refreshPlayer();
    } else {
      refreshPlayer();
    }
  }, [player, refreshState, onVideoTrackChange]);

  useEffect(() => {
    if (player) {
      if (isBurgerMenuOpen) {
        onBurgerMenuOpen();
      } else {
        onBurgerMenuClose();
      }
    }
  }, [player, isBurgerMenuOpen]);

  useEffect(() => {
    const videoPlayer = videojs(videoRef.current, options);
    setPlayer(videoPlayer);
    return () => {
      videoPlayer.dispose();
      onFullscreenChange(false);
    };
  }, []);

  useEffect(() => {
    if (player && !player.isDisposed() && videos && lastVideoTrackId) {
      let newTrack;

      if (lastVideoTrackId) {
        newTrack = videos.find(track => track.perspective === lastVideoTrackId);
      } else {
        newTrack = videos.find(track => track.perspective === VIDEO_TRACK_LABEL.main);
      }
      player.crossOrigin('anonymous');

      setChangeVideoTrack(true);
      setNewVolume(volume);
      player.volume(0);

      player.loadMedia({
        src: newTrack.source,
        type: newTrack.type,
      }, () => {
        setCurrentVideoTrack();
        setVideoTracks(changeTrackSelectedProp(videos, newTrack.perspective));

        subtitles.forEach(track => {
          player.addRemoteTextTrack(track, false);
        });

        let savedTime = 0;

        if (lastCurrentTime) {
          savedTime = lastCurrentTime;
        } else if (page) {
          savedTime = timestamps[page].startTime;
        }

        player.volume(volume);
        player.currentTime(savedTime);
        setCurrentTime(savedTime);

        // hack for safari on iPhone to show preview
        player.play();
        player.pause();

        player.one(PLAYER_EVENTS.loadeddata, () => {
          player.pause();
        });

        player.one(PLAYER_EVENTS.canplay, () => {
          setRefreshPlayerState(true);
          player.pause();
          player.currentTime(savedTime);
          setCurrentTime(savedTime);
          player.volume(volume);
          player.trigger(PLAYER_EVENTS.volumechange);
        });
      });
    }
  }, [player, videos, lastVideoTrackId, lastCurrentTime, setVideoTracks, setCurrentVideoTrack, setChangeVideoTrack, setNewVolume, setCurrentTime]);

  useEffect(() => {
    if (player) {
      player.on(PLAYER_EVENTS.loadedmetadata, onLoadedMetadata);
      player.on(PLAYER_EVENTS.ended, onEnded);
      player.on(PLAYER_EVENTS.dispose, onDispose);
      player.on(PLAYER_EVENTS.progress, onProgress);
      player.on(PLAYER_EVENTS.play, onPlay);
      player.on(PLAYER_EVENTS.pause, onPause);
      player.textTracks().on(PLAYER_EVENTS.change, onPlayerTextTrackChange);
      player.audioTracks().on(PLAYER_EVENTS.change, onPlayerAudioTrackChange);
      player.on(WINDOW_EVENTS.click, onVideoClickHandler);
      player.on(PLAYER_EVENTS.volumechange, onPlayerVolumeChange);
      window.addEventListener(WINDOW_EVENTS.keypress, onSpacePressHandler);
      window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
    return () => {
      if (player) {
        player.off(PLAYER_EVENTS.loadedmetadata, onLoadedMetadata);
        player.off(PLAYER_EVENTS.ended, onEnded);
        player.off(PLAYER_EVENTS.dispose, onDispose);
        player.off(PLAYER_EVENTS.progress, onProgress);
        player.off(PLAYER_EVENTS.play, onPlay);
        player.off(PLAYER_EVENTS.pause, onPause);
        player.textTracks().off(PLAYER_EVENTS.change, onPlayerTextTrackChange);
        player.audioTracks().off(PLAYER_EVENTS.change, onPlayerAudioTrackChange);
        player.off(WINDOW_EVENTS.click, onVideoClickHandler);
        player.off(PLAYER_EVENTS.volumechange, onPlayerVolumeChange);
        window.removeEventListener(WINDOW_EVENTS.keypress, onSpacePressHandler);
        window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
      }
    };
  }, [player, onLoadedMetadata, onEnded, onDispose, onProgress, onPlay, onPause, onPlayerTextTrackChange, onPlayerAudioTrackChange, onVideoClickHandler, onSpacePressHandler, onKeyDown]);

  useEffect(() => {
    if (isPlayerFullscreen && !somePopoverIsOpen) {
      document.addEventListener(WINDOW_EVENTS.click, onEscClick);
    } else {
      document.removeEventListener(WINDOW_EVENTS.click, onEscClick);
    }
    return () => {
      document.removeEventListener(WINDOW_EVENTS.click, onEscClick);
    };
  }, [isPlayerFullscreen, onEscClick, somePopoverIsOpen]);

  useEffect(() => {
    if (player) {
      player.one(PLAYER_EVENTS.loadeddata, onLoadedData);
    }
    return () => {
      if (player) {
        player.off(PLAYER_EVENTS.loadeddata, onLoadedData);
      }
    };
  }, [player, onLoadedData]);

  useEffect(() => {
    if (player) {
      player.on(PLAYER_EVENTS.timeupdate, onTimeUpdate);
    }
    return () => {
      if (player) {
        player.off(PLAYER_EVENTS.timeupdate, onTimeUpdate);
      }
    };
  }, [player, onTimeUpdate]);

  return (
    <div className={classes.playerWrapper} id='poster'>
      <video
        ref={videoRef}
        className={`video-js ${classes.videoPlayer}`}
        crossOrigin="anonymous"
      ></video>
      <div className={classes.toolBar} onClick={!somePopoverIsOpen ? stopClickPropagation : null}>
        <Grid container justify="space-around" alignItems="center">
          <Grid item xs={5}>
            <Grid container justify="space-around" alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={2} md={3}>
                    <Grid container justify="space-around">
                      <PlayerButton onClick={onBackwardClick} icon={SkipPreviousIcon}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <Grid container justify="space-around">
                      <CurrentChapterDisplay currentChapter={currentChapter} allChapters={chaptersLength}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={3}>
                    <Grid container justify="space-around">
                      <PlayerButton onClick={onForwardClick} icon={SkipNextIcon}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify='center' alignItems="center">
                  <TimestampDisplay currentTime={currentTime} duration={duration}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container justify="space-around" alignItems="center">
              <RoundedTextButton text="-5" onClick={onMinusFiveClick}/>
              <ToggleButton
                big
                iconOnTrue={PlayArrowIcon}
                iconOnFalse={PauseIcon}
                flag={paused}
                onClick={onPlayerClick}
              />
              <RoundedTextButton text="+5" onClick={onPlusFiveClick}/>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container justify='flex-end' alignItems="center" classes={{ root: classes.rightPanel }}>
              <div>
                <VoiceOverButton
                  audioTracks={audioTracks}
                  open={popoverState.voiceOver}
                  changeVideoTrack={changeVideoTrack}
                  onChange={onAudioTrackChange}
                  onOpen={onPopoverOpen}
                  onClose={onPopoverClose}
                />
              </div>
              <div>
                <PerspectiveButton
                  availablePerspectives={newAvailablePerspectives}
                  videoTracks={videoTracks}
                  open={popoverState.perspective}
                  onChange={onVideoTrackChange}
                  onOpen={onPopoverOpen}
                  onClose={onPopoverClose}
                />
              </div>
              <div>
                <SubtitlesButton
                  subtitle
                  textTracks={textTracks}
                  open={popoverState.subtitles}
                  onChange={onTextTrackChange}
                  onOpen={onPopoverOpen}
                  onClose={onPopoverClose}

                />
              </div>
              <div>
                <VolumeButton
                  fix
                  volume={newVolume}
                  open={popoverState.volume}
                  onChange={onVolumeChange}
                  onOpen={onPopoverOpen}
                  onClose={onPopoverClose}
                />
              </div>
              <div>
                <ToggleButton
                  iconOnTrue={FullscreenExitIcon}
                  iconOnFalse={FullscreenIcon}
                  flag={isPlayerFullscreen}
                  onClick={onFullscreenClick}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <ProgressSlider currentTime={currentTime} duration={duration} buffered={buffered} onChange={onSliderChange}/>
      </div>
      {isPlayerFullscreen && <BackButton position={classes.exitButton} onClick={onEscClick}/>}
    </div>
  );
};

Player.propTypes = {
  onPlayerDispose: PropTypes.func.isRequired,
  onChapterChange: PropTypes.func.isRequired,
  onPdfChange: PropTypes.func.isRequired,
  onFullscreenChange: PropTypes.func.isRequired,
  deleteTextTrackId: PropTypes.func.isRequired,
  deleteVideoTrackId: PropTypes.func.isRequired,
  deleteAudioTrackId: PropTypes.func.isRequired,
  refreshPlayer: PropTypes.func.isRequired,
  currentChapter: PropTypes.number.isRequired,
  chaptersLength: PropTypes.number.isRequired,
  allChapters: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    availablePerspectives: PropTypes.object,
  })).isRequired,
  page: PropTypes.number.isRequired,
  timestamps: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  })).isRequired,
  isPlayerFullscreen: PropTypes.bool.isRequired,
  isZoomView: PropTypes.bool.isRequired,
  videos: PropTypes.array.isRequired,
  subtitles: PropTypes.array.isRequired,
  volume: PropTypes.number.isRequired,
  lastCurrentTime: PropTypes.number,
  lastTextTrackId: PropTypes.string,
  lastVideoTrackId: PropTypes.string,
  lastAudioTrackId: PropTypes.string,
  refreshState: PropTypes.bool,
  isBurgerMenuOpen: PropTypes.bool,
};

export {
  Player,
};
