import { CONTEXT_MENU_ROUTES } from '../../constants/routes';

const contextMenuItems = [
  { label: 'Teile', path: CONTEXT_MENU_ROUTES.parts },
  { label: 'Werkzeug', path: CONTEXT_MENU_ROUTES.tools },
  { label: 'Personen', path: CONTEXT_MENU_ROUTES.people },
  { label: 'Aufbauzeit', path: CONTEXT_MENU_ROUTES.constructionTime },
  { label: 'Tipps', path: CONTEXT_MENU_ROUTES.tips, indicator: true },
  { label: 'Updates', path: CONTEXT_MENU_ROUTES.updates, disabled: true },
]

export {
  contextMenuItems
};