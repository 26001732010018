class StoreUtils {
  static createAction(type, ...argKeys) {

    return function (...args) {

      const action = { type };

      if (argKeys.length > 0) {
        const payload = {};
        argKeys.forEach((arg, index) => {
          const name = argKeys[index];
          payload[name] = args[index];
        });

        action.payload = payload;
      }

      return action;
    };
  }
}

export { StoreUtils };
