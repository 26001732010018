import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    height: '100%',
    background: theme.colors.background.primary,
    ['@media (pointer: fine) and (min-aspect-ratio: 17/10) and (min-height: 576px) and (min-width: 1124px) and (max-width: 1439px)']: {
      width: '92%',
    },
    ['@media (pointer: fine) and (min-aspect-ratio: 17/9) and (min-height: 576px) and (min-width: 1124px) and (max-width: 1439px)']: {
      width: '90%',
    },
    ['@media (pointer: fine) and (min-aspect-ratio: 17/8) and (min-height: 576px) and (min-width: 1124px) and (max-width: 1439px)']: {
      width: '88%',
    },
    ['@media (pointer: fine) and (min-aspect-ratio: 18/9) and (min-height: 576px) and (min-width: 1124px) and (max-width: 1439px)']: {
      width: '86%',
    },
    ['@media screen and  (max-height: 755px) and (min-width: 1281px) and (max-width: 1600px)']: {
      width: '90%',
    },
    ['@media screen and  (max-height: 560px) and (min-width: 1212px)']: {
      width: '90%',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.colors.background.primary,
    height: '100%',
    '&::after, &::before': {
      content: '""',
      display: 'block',
      width: '100%',
      minHeight: '10px',
      flex: '1 0',
    },
  },
  contentWrapper: {
    height: 'min-content',
    width: '95%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    ['@media (pointer: coarse) and (max-height: 900px)']: {
      width: '92%',
    },
    ['@media screen and  (max-height: 950px) and (min-width: 1600px) and (max-width:1920px)']: {
      width: '90%',
    },
    ['@media screen and  (max-height: 560px) and (min-width: 1212px)']: {
      width: '85%',
    },
  },
  zoomView: {
    height: '100% !important',
    '& $container': {
      height: '100%',
      padding: 0,
      borderRadius: '25px 25px 0 0',
      overflowY: 'overlay',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& $contentWrapper': {
        flex: '1 0 auto',
        width: '100% !important',
        padding: '27px 0',
        [theme.breakpoints.up('lg')]: {
          padding: '27px 0',
        },
      },
    },
  },
  fullscreen: {
    height: 'min-content',
    background: 'none',
    '& $contentWrapper': {
      padding: '30px 100px',
      width: '88%',
      ['@media screen and (min-height: 1080px)']: {
        width: '92%',
      },
      ['@media (pointer: coarse) and (max-height: 900px)']: {
        width: '89%',
      },
      [`@media only screen  
      and (min-device-width: 768px)
      and (max-device-width: 1024px)
      and (-webkit-min-device-pixel-ratio: 1)
      `]: {
        width: '100%',
        padding: '20px 100px',
      },
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        padding: '27px 150px',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '20px 120px',
      },
    },
    '& $container': {
      padding: 0,
      background: theme.colors.background.primary,
      borderRadius: '25px 25px 0 0',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
}));

export {
  useStyles,
};