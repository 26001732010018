import Tools from './tools/reducer';
import Player from './player/reducer';
import Product from './product/reducer';
import PDF from './pdf/reducer';
import Chapters from './chapters/reducer';
import Parts from './parts/reducer';
import Tips from './tips/reducer';
import Products from './products/reducer';
import SpareParts from './spareParts/reducer';
import App from './app/reducer';

const reducers = {
  Tools,
  Player,
  Product,
  PDF,
  Chapters,
  Parts,
  Tips,
  Products,
  SpareParts,
  App,
};

export default reducers;
