import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import clsx from 'clsx';

import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/arrow.svg'

import { useStyles } from './styles';
import { KEYS_CODE, WINDOW_EVENTS } from '../../../constants/player';

const BackButton = ({ position, onClick }) => {
  const classes = useStyles();

  const onKeyDown = useCallback((e) => {
    if (e.code === KEYS_CODE.escape) {
      onClick();
    }
  }, [])

  useEffect(() => {
    window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    return () => {
      window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
  }, [onKeyDown])

  return (
    <IconButton classes={{root: clsx(classes.iconButton, position)}} onClick={onClick} data-testid='back-button'>
      <SvgIcon classes={{ root: classes.svgIcon }} component={ArrowBackIcon} viewBox='0 0 16 16'/>
    </IconButton>
  )
}

BackButton.propTypes = {
  position: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export{
  BackButton,
};
