import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    height: '100%',
    '& .infinite-scroll-component__outerdiv': {
      height: 'calc(100% - 65px)',
      [theme.breakpoints.up('lg')]: {
        height: 'calc(100% - 95px)',
      },
      [theme.breakpoints.up('xl')]: {
        height: 'calc(100% - 100px)',
      },
    },
    '&:after': {
      content: props => props.isModalOpen ? '" "' : '',
      position: 'absolute',
      top: '0',
      left: '20px',
      right: '20px',
      bottom: '0',
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
    },
  },
  listItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deleteButton: {
    background: 'rgba(98, 102, 234, 0.1)',
    width: '30px',
    height: '30px',
    [theme.breakpoints.up('extraLg')]: {
      width: '40px',
      height: '40px',
      margin: '0 20px 0 0',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50px',
      height: '50px',
    },
    '& svg, & svg path': {
      fontSize: '1.3rem',
      fill: theme.colors.primary,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 15px 0 0',
    height: '65px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 20px 0 0',
      height: '90px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 20px 0 0',
      height: '95px',
    },
  },
  listCell: {
    [theme.breakpoints.down('lg')]: {
      width: props => props.width === '30%' ? '25%' : props.width,
      flexBasis: props => props.width === '30%' ? '25%' : props.width,
    },
    [theme.breakpoints.down('md')]: {
      width: props => props.width === '30%' ? '20%' : props.width,
      flexBasis: props => props.width === '30%' ? '20%' : props.width,
    },
  },
  listHeader: {
    '& div': {
      fontSize: '12px',
      [theme.breakpoints.up('lg')]: {
        fontSize: '13px',
      },
      [theme.breakpoints.up('extraLg')]: {
        fontSize: '1.125rem',
      },
    },
  },
  textContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    [theme.breakpoints.up('extraLg')]: {
      fontSize: '1.563rem',
    },
  },
}));

const useModalStyles = makeStyles((theme) => ({
  infoButton: {
    margin: '0 15px 0 0',
    padding: '0',
    color: theme.colors.primary,
    '& svg ,& svg path': {
      fontSize: '3rem',
    },
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 25px 0 0',
    },
  },
  infoTitle: {
    margin: '0 0 10px',
    fontSize: '1.375rem',
    fontWeight: 700,
    [theme.breakpoints.up('lg')]: {
      margin: '0 0 20px',
    },
  },
  infoText: {
    maxHeight: '35%',
    margin: '0 0 20px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1.375rem',
    fontWeight: 500,
    overflow: 'auto',
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 0 30px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '80%',
      margin: '0 0 50px',
    },
  },
  tipsLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '200px',
    minHeight: '35px',
    padding: '0 10px',
    color: '#fff',
    background: theme.colors.primary,
    borderRadius: '10px',
    textDecoration: 'none',
    fontSize: '1.375rem',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      minHeight: '45px',
    },
    [theme.breakpoints.up('extraLg')]: {
      minHeight: '55px',
      padding: '0 20px',
    },
  },
}));

export {
  useStyles,
  useModalStyles,
};
