import { DIFFICULTY_LEVELS } from '../../constants/people';

const DIFFICULTY_LEVEL_VALUES = {
  [DIFFICULTY_LEVELS.LOW]: 1,
  [DIFFICULTY_LEVELS.MIDDLE]: 2,
  [DIFFICULTY_LEVELS.HIGH]: 3,
};

export {
  DIFFICULTY_LEVEL_VALUES,
};