import { StoreUtils } from '../../utils/StoreUtils';

const prefix = '[App]';
const { createAction } = StoreUtils;

const TYPES = {
  SET_ERROR: `${prefix} set-error`,
  CLOSE_ERROR: `${prefix} close-error`,
  DELETE_ERROR: `${prefix} delete-error`,
};

export const appActions = {
  ...TYPES,
  setError: createAction(TYPES.SET_ERROR, 'error'),
  closeError: createAction(TYPES.CLOSE_ERROR, 'key'),
  deleteError: createAction(TYPES.DELETE_ERROR, 'key'),
};
