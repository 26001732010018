import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sidebarWrapper: {
    width: '27.2%',
    display: props => props.isFullscreen ? 'none' : 'flex',
    minHeight: '100%',
    padding: '0 20px 0 0',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      padding: '0 40px 0 0',
    },
  },
}));

export {
  useStyles,
};