import { useRef, useEffect } from 'react';

const useUpdateEffect = (callback, dependencies = []) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    callback();
  }, dependencies);
};

export {
  useUpdateEffect,
};