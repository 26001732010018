import { ReactComponent as SmallPartsIcon } from '../../assets/icons/small-parts.svg';
import { ReactComponent as BigPartsIcon } from '../../assets/icons/big-parts.svg';
import FunctionsIcon from '@material-ui/icons/Functions';

import { PARTS_SIZES } from '../../constants/parts';

const sizesList = [
  { value: PARTS_SIZES.ALL, icon: FunctionsIcon, size: 'big' },
  { value: PARTS_SIZES.SMALL, icon: SmallPartsIcon },
  { value: PARTS_SIZES.BIG, icon: BigPartsIcon },
];

export {
  sizesList,
};
