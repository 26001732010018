import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'block',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 500,
    padding: '15px 20px',
    '&::before': {
      content: 'attr(data-label)',
      display: 'block',
      fontWeight: 600,
      color: 'transparent',
      overflow: 'hidden',
      visibility: 'hidden',
      height: 0,
    },
  },
  activeLink: {
    position: 'relative',
    display: 'block',
    background: theme.colors.background.highlighter,
    borderRadius: '7px',
    color: 'black !important',
    fontWeight: 'bold',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '5px',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'block',
      width: '15px',
      height: '2px',
      borderRadius: '1px',
      background: theme.colors.primary,
    },
  },
  nav: {
    display: 'flex',
  },
}));

export {
  useStyles,
};