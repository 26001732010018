import { useSelector } from 'react-redux';
import { selectFullscreenState } from '../redux/player/selectors';
import { selectZoomViewState } from '../redux/pdf/selectors';

const usePageMode = () => {
  const isPlayerFullscreen = useSelector(selectFullscreenState);
  const isZoomView = useSelector(selectZoomViewState);

  return isPlayerFullscreen || isZoomView;
};

export {
  usePageMode,
};