import { makeStyles } from '@material-ui/core/styles';
import { PROGRESS_DETAILS_LABEL } from '../../constants/constructionTime';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  root: {
    height: '40px',
    borderRadius: '40px',
    willChange: 'transform',
    backgroundColor: props => typeof props.value === 'number' ? theme.colors.background.chapter : '#E8E8E8',
    [theme.breakpoints.down('xl')]: {
      height: '35px',
      borderRadius: '35px',
    },
    [theme.breakpoints.down('extraLg')]: {
      height: '30px',
      borderRadius: '30px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '25px',
      borderRadius: '25px',
    },
    [theme.breakpoints.down('md')]: {
      height: '20px',
      borderRadius: '20px',
    },
  },
  bar: {
    backgroundColor: props => {
      switch (props.name) {
        case PROGRESS_DETAILS_LABEL.productLabel:
          return '#B7B9BC'
        case PROGRESS_DETAILS_LABEL.chapterLabel:
          return '#787C84'
        default:
          break;
      }
    },
    borderRadius: '40px',
    display: props => typeof props.value === 'number' ? 'block' : 'none',
  },
  info: {
    position: 'absolute',
    top: '50%',
    left: props => `${typeof props.value === 'number' && props.value !== 0 ? props.value / 2 : 50}%`,
    transform: 'translate(-50%, -50%)',
    transition: 'all .4s linear',
    margin: '0',
  },
  percent: {
    color: props => props.value === 0 ? '#000' : '#FFF',
  },
}));

export {
  useStyles,
};
