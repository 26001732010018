import React from 'react'
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import { useStyles } from './styles';

const CloseButton = ({  position, onClick }) => {
  const classes = useStyles()
  
  return (
    <IconButton classes={{ root: clsx(classes.iconButton, position), label: classes.label}} onClick={onClick} data-testid='close-button'>
      <CloseIcon classes={{ root: classes.svgIcon}} />
    </IconButton>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  position: PropTypes.string,
}

export{
  CloseButton,
};
