import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import { People } from '../../components/ProductTabs/People';

import { productActions } from '../../redux/product/actions';
import { pdfActions } from '../../redux/pdf/actions';
import { playerActions } from '../../redux/player/actions';
import { selectCurrentTab, selectPreviousTab } from '../../redux/product/selectors';
import { TABS } from '../../constants/tabs';
import { UI_ROUTES } from '../../constants/routes';
import { selectChapterTimestamps, selectPeopleList } from '../../redux/chapters/selectors';
import { selectCurrentPageNumber, selectPdfTimestamps } from '../../redux/pdf/selectors';
import { chaptersActions } from '../../redux/chapters/actions';
import { useParams } from 'react-router-dom';
import { VideoSidebarUtils } from '../../utils/VideoSidebarUtils';

const PlayerPeopleView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const peopleList = useSelector(selectPeopleList);
  const currentDocuments = useSelector(selectCurrentPageNumber);
  const pdfTimestamps = useSelector(selectPdfTimestamps);
  const chapterTimestamps = useSelector(selectChapterTimestamps);
  const currentTab = useSelector(selectCurrentTab);
  const previousTab = useSelector(selectPreviousTab);

  useEffect(() => {
    dispatch(productActions.setPreviousTab(currentTab));
    dispatch(productActions.switchTab(TABS.PDF));
  }, []);

  useEffect(() => {
    const unblock = history.block(() => {
      dispatch(productActions.switchTab(previousTab));
    });

    return () => {
      unblock();
    };
  }, [previousTab]);

  const selectedListItemRef = useCallback(selectedItemElement => {
    if (selectedItemElement !== null) {
      selectedItemElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, []);

  const onIndexClick = useCallback((index) => () => {
    const newPdf = pdfTimestamps[index];
    const newChapter = VideoSidebarUtils.findNewChapter(chapterTimestamps, newPdf);

    dispatch(productActions.switchTab(TABS.PDF));
    dispatch(pdfActions.changeCurrentPage(index));
    dispatch(pdfActions.setPreviousPdfPage(index));
    dispatch(chaptersActions.changeCurrentChapter(newChapter));
    dispatch(chaptersActions.setPreviousChapter(0));
    dispatch(playerActions.resetCurrentTime());
    history.push(generatePath(UI_ROUTES.videoPlayer, { id }));
  }, [dispatch, history]);

  const checkIsItemSelected = useCallback((index) => index === currentDocuments, [currentDocuments]);

  return (
    <People
      renderEscButton
      selectedItemRef={selectedListItemRef}
      productId={id}
      peopleList={peopleList}
      pdfTimestamps={pdfTimestamps}
      chapterTimestamps={chapterTimestamps}
      checkIsItemSelected={checkIsItemSelected}
      onIndexClick={onIndexClick}
    />
  );
};

export {
  PlayerPeopleView,
};
