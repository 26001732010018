import { createSelector } from 'reselect';

const product = ({ Product }) => Product;
const productUI = ({ Product }) => Product.ui;

const selectProduct = createSelector(
  [product],
  (product) => product.product,
);

const selectVideoId = createSelector(
  [product],
  (product) => product.product.videoId,
);

const selectVideos = createSelector(
  [product],
  (product) => product.product.videos,
);

const selectCompanyLogo = createSelector(
  [product],
  (product) => product.product.companyLogo,
);

const selectSubtitles = createSelector(
  [product],
  (product) => product.product.subtitles.map(subtitle => ({
    label: subtitle.label,
    src: subtitle.source,
    kind: 'subtitles',
    default: false,
    mode: 'hidden',
  })),
);

const selectProductId = createSelector(
  [product],
  (product) => product.product.id,
);

const selectLoadingState = createSelector(
  [product],
  (product) => product.loading,
);

const selectCurrentTab = createSelector(
  [productUI],
  (productUI) => productUI.currentTab,
);

const selectPreviousTab = createSelector(
  [productUI],
  (productUI) => productUI.previousTab,
);

const selectSidebarState = createSelector(
  [productUI],
  (productUI) => productUI.isSidebarDisabled,
);

export {
  selectVideoId,
  selectProductId,
  selectProduct,
  selectVideos,
  selectSubtitles,
  selectCompanyLogo,
  selectLoadingState,
  selectCurrentTab,
  selectPreviousTab,
  selectSidebarState,
};
