import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const PlayerButton = ({ icon: Icon, onClick, color, fontSize, ...rest }) => {
  const classes = useStyles({ color, fontSize });
  return (
    <IconButton 
      onClick={onClick} 
      classes={{ root: classes.iconButton, disabled: classes.disabled }}
      disableRipple
      disableFocusRipple
      data-testid ='player-button' 
      {...rest}
    >
      <Icon classes={{ root: classes.svgIcon }} />
    </IconButton>
  )
}

PlayerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.number,
};

export {
  PlayerButton,
};
