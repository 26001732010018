import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, .5)',
    backdropFilter: 'blur(2px)',
    overflow: 'hidden',
  },
  container: {
    width: '80%',
    padding: '24px',
    background: '#fff',
    borderRadius: '20px',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    }
  },
}));

export {
  useStyles,
};