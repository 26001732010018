import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { chaptersActions } from '../../redux/chapters/actions';
import { selectChapterLoadingState } from '../../redux/chapters/selectors';
import { pdfActions } from '../../redux/pdf/actions';
import { selectPdfTimestampsLoadingState } from '../../redux/pdf/selectors';
import { Loading } from '../../components/Loading';
import { useParams } from 'react-router-dom';

const SpareParts = ({ children }) => {
  const [isVideoDataLoading, setVideoDataLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const isFirstRender = useRef(true);
  const chaptersLoading = useSelector(selectChapterLoadingState);
  const pdfTimestampsLoading = useSelector(selectPdfTimestampsLoadingState);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setVideoDataLoading(true);
      return;
    }

    setVideoDataLoading(chaptersLoading || pdfTimestampsLoading);
  }, [chaptersLoading, pdfTimestampsLoading]);

  useEffect(() => {
    dispatch(chaptersActions.reloadChapters(id));
    dispatch(pdfActions.reloadPdfTimestamps(id));
  }, [id]);

  return <Loading loading={isVideoDataLoading}>{children}</Loading>;
};

SpareParts.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export {
  SpareParts,
};