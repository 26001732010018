class ConstructionTimeUtils {

  static createGeneralInfoObject(name, label, duration) {
    return ({
      name, 
      label, 
      duration,
    })
  }

  static createProgressDetailsObject(name, progress, timePassed, timeRest) {
    return ({
      name, 
      progress, 
      timePassed, 
      timeRest,
    })
  }

  static calculateProductRestTime(array, current, viewMode, chapters) {
    return array.reduce( (acc, elems, i) => {
      if(current === 0) return this.calculateDuration(chapters, viewMode)
      if(i >= current) return acc + elems.find( elem => elem.type === viewMode ).duration
      return acc;
    }, 0);
  }

  static calculateProductPassedTime(array, current, viewMode) {
    return array.reduce( (acc, elems, i) => {
      if(i < current) return acc + elems.find( elem => elem.type === viewMode ).duration
      return acc;
    }, 0);
  }

  static calculateChapterRestTime(pdfs, currentChapter, pdfTimestamps, index, viewMode) {
    return pdfs.reduce( (acc, elems, i) => {
      if(pdfTimestamps[i].startTime >= currentChapter.startTime && pdfTimestamps[i].endTime <= currentChapter.endTime && i >= index) {
        return acc + elems.find( elem => elem.type === viewMode ).duration;
      } 
      return acc;
    }, 0);
  }

  static calculateChapterPassedTime(pdfs, currentChapter, pdfTimestamps, index, viewMode) {
    return pdfs.reduce( (acc, elems, i) => {
      if(pdfTimestamps[i].startTime >= currentChapter.startTime && pdfTimestamps[i].endTime <= currentChapter.endTime && i < index) {
        return acc + elems.find( elem => elem.type === viewMode ).duration;
      } 
      return acc;
    }, 0);
  }

  static calculateProductProgress(array, current, viewMode) {
      return Math.round((this.calculateProductPassedTime(array, current, viewMode) * 100) / this.calculateDuration(array, viewMode));
  }

  static calculateChapterProgress(pdfs, currentChapter, pdfTimestamps, pdfIndex, viewMode, chapters, chaptersIndex) {
    return Math.round((this.calculateChapterPassedTime(pdfs, currentChapter, pdfTimestamps, pdfIndex, viewMode) * 100) / this.getDuration(chapters, chaptersIndex, viewMode));
  }

  static calculateDuration(array, viewMode) {
    return this.formatTime(array.reduce( (acc, elems) => acc + elems.find( elem => elem.type === viewMode ).duration, 0));
  }

  static getDuration (array, index, viewMode) {
    return this.formatTime(array[index]?.find( elem => elem.type === viewMode ).duration);
  }

  static formatTime (time) {
    return time % 60 === 0 ? time : (time + (60 - (time % 60)));
  }

}

export { ConstructionTimeUtils };