import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const Sidebar = ({ isFullscreen, children }) => {
  const classes = useStyles({ isFullscreen });
  
  return <div className={classes.sidebarWrapper}>{children}</div>;
};

Sidebar.defaultProps = {
  isFullscreen: false,
}

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isFullscreen: PropTypes.bool,
};

export {
  Sidebar,
};
