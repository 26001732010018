import React from 'react';
import PropTypes from 'prop-types';

import { Tips } from '../Tips';

const PlayerTips = ({
  totalTipsCount,
  topics,
  tips,
  expandedTopics,
  toggleTopic,
  renderEscButton,
  productId,
}) => (
  <Tips
    totalTipsCount={totalTipsCount}
    topics={topics}
    tips={tips}
    expandedTopics={expandedTopics}
    toggleTopic={toggleTopic}
    renderEscButton={renderEscButton}
    productId={productId}
  />
);


PlayerTips.propTypes = {
  productId: PropTypes.string.isRequired,
  renderEscButton: PropTypes.bool.isRequired,
  totalTipsCount: PropTypes.number.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tips: PropTypes.object.isRequired,
  expandedTopics: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleTopic: PropTypes.func,
};

export {
  PlayerTips,
};
