import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import { chaptersActions } from '../../redux/chapters/actions';
import { selectChapterLoadingState } from '../../redux/chapters/selectors';
import { pdfActions } from '../../redux/pdf/actions';
import { selectPdfTimestampsLoadingState } from '../../redux/pdf/selectors';

import { UI_ROUTES, CONTEXT_MENU_ROUTES } from '../../constants/routes';
import { Loading } from '../../components/Loading';

const Summary = ({ children }) => {
  const [isPageDataLoading, setPageDataLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const isFirstRender = useRef(true);
  const chaptersLoading = useSelector(selectChapterLoadingState);
  const pdfTimestampsLoading = useSelector(selectPdfTimestampsLoadingState);
  const isSummaryPersonsPage = useRouteMatch(UI_ROUTES.summary + CONTEXT_MENU_ROUTES.people)?.isExact ?? false;
  const isSummaryConstructionTimePage = useRouteMatch(UI_ROUTES.summary + CONTEXT_MENU_ROUTES.constructionTime)?.isExact ?? false;
  const loadData = isSummaryConstructionTimePage || isSummaryPersonsPage;

  useEffect(() => {
    if (isFirstRender.current && loadData) {
      isFirstRender.current = false;
      setPageDataLoading(true);
      return;
    }

    setPageDataLoading(chaptersLoading || pdfTimestampsLoading);
  }, [chaptersLoading, loadData, pdfTimestampsLoading]);

  useEffect(() => {
    if (loadData) {
      dispatch(chaptersActions.reloadChapters(id, { withConstructionTime: true }));
      dispatch(pdfActions.reloadPdfTimestamps(id, { withConstructionTime: true }));
    }
  }, [id, loadData]);

  return <Loading loading={isPageDataLoading}>{children}</Loading>;
};

Summary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export {
  Summary,
};