import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

const RoundedTextButton = ({ onClick, text, size }) => {
  const classes = useStyles({ size });
  return (
    <Button 
      disableRipple 
      disableElevation
      variant="contained" 
      color="primary" 
      classes={{ root: classes.button }} 
      onClick={onClick}
    >
      {text}
    </Button>
  )
}
RoundedTextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.number,
};
export {
  RoundedTextButton,
};
