import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgPlayerButton = ({ icon: Icon, viewBox, onClick, buttonClasses, svgClasses, ...rest}) => {
  return (
    <IconButton 
      onClick={onClick} 
      classes={{...buttonClasses}} 
      disableRipple
      disableFocusRipple
      {...rest} 
    >
      <SvgIcon component={Icon} viewBox={viewBox} classes={{...svgClasses}}/>
    </IconButton>
  )
}

SvgPlayerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
  viewBox: PropTypes.string.isRequired,
  buttonClasses: PropTypes.objectOf(PropTypes.string),
  svgClasses: PropTypes.objectOf(PropTypes.string),
}

export {
  SvgPlayerButton,
};
