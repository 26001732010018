const isoLang = {
  "en":{
    "name":"English",
    "nativeName":"English"
  },
  "fr":{
    "name":"French",
    "nativeName":"Français"
  },
  "de":{
    "name":"German",
    "nativeName":"Deutsch"
  },
}

export {
  isoLang,
};
