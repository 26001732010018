import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { ProductPagesContainer } from '../../../components/ProductPagesContainer';
import { UI_ROUTES } from '../../../constants/routes';
import { SummaryMenu } from '../../../components/SummaryMenu';
import { Routes } from '../../../components/Routes';
import { Summary } from '../../../containers/Summary';

const SummaryPage = ({ routes }) => {
  const { id } = useParams();

  return (
    <ProductPagesContainer pageBaseUrl={UI_ROUTES.summary}>
      <ProductPagesContainer.Sidebar>
        <SummaryMenu productId={id}/>
      </ProductPagesContainer.Sidebar>
      <ProductPagesContainer.MainContent>
        <Summary>
          <Routes routes={routes}/>
        </Summary>
      </ProductPagesContainer.MainContent>
    </ProductPagesContainer>
  );
};

SummaryPage.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    components: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  })).isRequired,
};

export {
  SummaryPage,
};