import { createSelector } from 'reselect';

const player = ({ Player }) => Player;
const playerUI = ({ Player }) => Player.ui;

const selectCurrentTime = createSelector(
	[player],
	(player) => player.lastCurrentTime,
);

const selectVolume = createSelector(
  [player],
  (player) => player.volume,
);

const selectAudioTrackId = createSelector(
  [player],
  (player) => player.lastAudioTrackId,
);

const selectTextTrackId = createSelector(
  [player],
  (player) => player.lastTextTrackId,
);

const selectVideoTrackId = createSelector(
  [player],
  (player) => player.lastVideoTrackId,
);

const selectFullscreenState = createSelector(
  [playerUI],
  (playerUI) => playerUI.isPlayerFullScreen,
);

const selectBurgerMenuState = createSelector(
  [playerUI],
  (playerUI) => playerUI.isBurgerMenuOpen,
);

const selectRefreshState = createSelector(
  [playerUI],
  (playerUI) => playerUI.refreshState,
)

export {
  selectCurrentTime,
  selectVolume,
  selectAudioTrackId,
  selectTextTrackId,
  selectVideoTrackId,
  selectFullscreenState,
  selectBurgerMenuState,
  selectRefreshState,
};
