import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  imgHolder: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: props => props.width ? props.width : '50px',
    filter : props => props.filter || 'none',
    [theme.breakpoints.up('lg')]: {
      maxWidth: props => props.width ? props.width : '60px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: props => props.width ? props.width : '70px',
    },
    '& > img': {
      objectFit: 'contain', width: '100%',
      borderRadius: '10px',
    },
  },
}));

export {
  useStyles,
};