import Modal from '@material-ui/core/Modal';
import React from 'react';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles';

const MobileModal = () => {

  const classes = useStyles();

  return (
    <Modal
      open
      className={classes.root}
    >
      <Grid container classes={{root: classes.container}}>
        <div className={classes.content}>
          <Typography variant="h3" color="primary">Bitte halten Sie Ihr Smartphone im Querformat</Typography>
        </div>
      </Grid>
    </Modal>
  )
}

export {
  MobileModal,
};
