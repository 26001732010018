import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Chapter } from './Chapter';

import { selectSidebarState } from '../../redux/product/selectors';

import { useStyles } from './styles';
import { AnimationsUtils } from '../../utils/AnimationsUtils';

const Chapters = ({ currentChapter, allChapters, chaptersLength, changeCurrentChapter }) => {
  const isSidebarDisabled = useSelector(selectSidebarState);
  const classes = useStyles({ isSidebarDisabled });
  const chapterWrapperRef = useRef(null);
  const selectedChapterRef = useRef(null);

  useEffect(() => {

    let scrollPosition;

    if (currentChapter >= 2 && currentChapter < chaptersLength - 4) {
      const chapterItemHeight = selectedChapterRef.current.offsetHeight;
      scrollPosition = selectedChapterRef.current.offsetTop - chapterWrapperRef.current.scrollTop - (chapterItemHeight * 2);
    }

    if (currentChapter < 3) {
      scrollPosition = -chapterWrapperRef.current.scrollTop
    }

    if (currentChapter >= chaptersLength - 4) {
      scrollPosition = chapterWrapperRef.current.scrollHeight - (chapterWrapperRef.current.scrollTop + chapterWrapperRef.current.offsetHeight);
    }
    
    if ((scrollPosition === 0 && chapterWrapperRef.current.scrollTop === 0)
      ||(chapterWrapperRef.current.scrollHeight - (chapterWrapperRef.current.scrollTop + chapterWrapperRef.current.clientHeight) === 0 
        && scrollPosition >= 0)){
      return;
    }

    AnimationsUtils.scrollAnimate({
      duration: 1500,
      timing: AnimationsUtils.easeInOutCubic,
      draw: AnimationsUtils.scrollElement,
      element: chapterWrapperRef.current,
      pos: scrollPosition,
    })
  }, [currentChapter]);

  return (
    <div className={classes.chaptersWrapper} ref={chapterWrapperRef}>
      <List className={classes.chaptersList}>
        {allChapters.map((chapter, index) => (
            <ListItem
              key={`${chapter.name}-${index}`}
              ref={index === currentChapter ? selectedChapterRef : null}
              className={classes.chaptersListItem}
              {...(!isSidebarDisabled && { onClick: () => changeCurrentChapter(index) })}
            >
              <Chapter selected={currentChapter === index} chapterIndex={index} chapter={chapter}/>
            </ListItem>
          ),
        )}
      </List>
    </div>
  );
};

Chapters.propTypes = {
  currentChapter: PropTypes.number.isRequired,
  allChapters: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  chaptersLength: PropTypes.number.isRequired,
  changeCurrentChapter: PropTypes.func.isRequired,
};

export {
  Chapters,
};