import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { TipsUtils } from '../../utils/TipsUtils';

import { tipsActions } from './actions';

import { appActions } from '../app/actions';
import { TipsService } from '../../services/TipsService';
import { selectExpandedTopics } from './selectors';

function* listTopicLoad({ payload: { productId, requestData } }) {
  try {
    const { data } = yield call(TipsService.getTopics, productId, requestData);

    yield put(tipsActions.listTopicsRefresh(data));
    yield call(refreshTipsForExpandedTopics, { topics: data, productId, requestData });
  } catch (error) {
    yield put(appActions.setError(error));
  }
}

function* listTipsLoad({ payload: { productId, requestData } }) {
  const { filters: { topic } } = requestData;

  try {
    const { data } = yield call(TipsService.getTips, productId, { ...requestData, limit: 0 });

    yield put(tipsActions.listTipsRefresh(data, topic));
  } catch (error) {
    yield put(appActions.setError(error));
  }
}

function* refreshTipsForExpandedTopics({ topics, productId, requestData }) {
  const allExpandedTopics = yield select(selectExpandedTopics);
  const currentExpandedTopics = topics.filter(({ id }) => allExpandedTopics.includes(id));

  if (currentExpandedTopics.length) {
    yield all(currentExpandedTopics.map(({ id }) => call(
      listTipsLoad,
      TipsUtils.buildPayloadForRefreshTips(productId, requestData, id),
    )));
  }
}

export default function* tipsSaga() {
  yield all([
    takeLatest(tipsActions.LIST_TOPICS_LOAD, listTopicLoad),
    takeLatest(tipsActions.LIST_TIPS_LOAD, listTipsLoad),
  ]);
}

export {
  listTopicLoad,
  listTipsLoad,
};
