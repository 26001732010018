import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    fontSize: '50px',
    borderRadius: '10px',
    background: theme.colors.primary,
    [theme.breakpoints.down('lg')]: {
      width: '40px',
      height: '40px',
      fontSize: '40px',
    },
    '&:hover': {
      background: theme.colors.primary,
    },
  },
  svgIcon: {
    fontSize: '.6em',
    color: '#FFF',
  },
}));

export {
    useStyles,
};
