import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ProductPagesContainer } from '../../../components/ProductPagesContainer';

import { TABS } from '../../../constants/tabs';
import { SparePartsView } from '../../../containers/SparePartsView';
import { TabsView } from '../../../containers/TabsView/TabsView';
import { SpareParts } from '../../../containers/SpareParts';
import { usePageMode } from '../../../hooks/usePageMode';

import { pdfActions } from '../../../redux/pdf/actions';
import { selectCurrentPageNumber, selectPreviousPageNumber } from '../../../redux/pdf/selectors';
import { productActions } from '../../../redux/product/actions';
import { selectCurrentTab, selectPreviousTab } from '../../../redux/product/selectors';

const SparePartsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isFullscreen = usePageMode();
  const currentTab = useSelector(selectCurrentTab);
  const previousTab = useSelector(selectPreviousTab);
  const currentPdfPage = useSelector(selectCurrentPageNumber);
  const previousPdfPage = useSelector(selectPreviousPageNumber);

  useEffect(() => {
    dispatch(productActions.setPreviousTab(currentTab));
    dispatch(pdfActions.setPreviousPdfPage(currentPdfPage));
    dispatch(productActions.switchTab(TABS.PDF));
  }, []);

  useEffect(() => {
    const unblock = history.block(() => {
      dispatch(productActions.switchTab(previousTab));
      dispatch(pdfActions.changeCurrentPage(previousPdfPage));
    });

    return () => {
      unblock();
    };
  }, [previousTab, previousPdfPage]);

  return (
    <ProductPagesContainer>
      <ProductPagesContainer.Sidebar isFullscreen={isFullscreen}>
        <TabsView />
      </ProductPagesContainer.Sidebar>
      <ProductPagesContainer.MainContent isFullscreen={isFullscreen}>
        <SpareParts>
          <SparePartsView />
        </SpareParts>
      </ProductPagesContainer.MainContent>
    </ProductPagesContainer>
  );
};

export { SparePartsPage };