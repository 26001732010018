import React from 'react';
import PropTypes from 'prop-types';

import { SectionTitle } from '../../SectionTitle';
import { ProductTabContent } from '../ProductTabContent';
import { CloseTabButton } from '../CloseTabButton';
import { TipsList } from '../../TipsList';

import { FormatUtils } from '../../../utils/FormatUtils';

import { useStyles } from './styles';

const Tips = ({ totalTipsCount, topics, tips, productId, expandedTopics, toggleTopic, renderEscButton }) => {
  const classes = useStyles();
  const title = (
    <SectionTitle
      title="Tipps"
      subtitle={totalTipsCount ? FormatUtils.formatListItemsTotalCount(totalTipsCount) : ''}
    />
  );
  const controls = (
    <>
      {renderEscButton && <CloseTabButton productId={productId}/>}
    </>
  );

  return (
    <ProductTabContent title={title} controls={controls}>
      <div className={classes.content}>
        <TipsList
          topics={topics}
          tips={tips}
          expandedTopics={expandedTopics}
          toggleTopic={toggleTopic}
        />
      </div>
    </ProductTabContent>
  );
};

Tips.propTypes = {
  productId: PropTypes.string.isRequired,
  renderEscButton: PropTypes.bool,
  totalTipsCount: PropTypes.number.isRequired,
  expandedTopics: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleTopic: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    numberOfTips: PropTypes.number.isRequired,
  })).isRequired,
  tips: PropTypes.object.isRequired,
};

export {
  Tips,
};
