import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listViewContainer: {
    position: 'relative',
    flexGrow: 0,
    flexBasis: '100%',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    padding: '15px 0 0 0',
    [theme.breakpoints.up('extraXl')]: {
      padding: '25px 0 0 0',
    },
  },
  listWrapper: {
    height: '100%',
    '& ul': {
      flexGrow: 0,
      maxWidth: '100%',
      height: '100%',
      padding: '0',
      listStyle: 'none',
      margin: '0 20px 0 0',
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
    },
  },
  listHeader: {
    width: '100%',
    margin: 0,
    padding: '0 20px 10px',
    fontWeight: 600,
    '& > div': {
      fontSize: '1.125rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 'normal',
      '&:first-child': {
        paddingLeft: '20px',
      },
    },
    [theme.breakpoints.up('extraLg')]: {
      padding: '10px 20px 15px',
    },
  },
  listItem: {
    margin: '0 0 15px',
    '&:last-child': {
      margin: 0,
    },
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 0 25px',
    },
  },
}));

const useCellStyles = makeStyles(theme => ({
  listCell: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    justifyContent: props => props.align || props.bold && 'flex-end',
    flexBasis: props => props.width || 'auto',
    width: props => props.width || 'auto',
    fontSize: '1.05rem',
    fontWeight: props => props.bold ? '700' : '500',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem',
    },
    [theme.breakpoints.up('extraLg')]: {
      fontSize: '1.25rem',
    },
  },
}));

export {
  useCellStyles,
  useStyles,
};