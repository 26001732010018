import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ loading, children }) =>
  loading ? <></> : children;

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export {
  Loading,
};
