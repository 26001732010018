const PLAYER_EVENTS = {
  loadedmetadata: 'loadedmetadata',
  dispose: 'dispose',
  ended: 'ended',
  timeupdate: 'timeupdate',
  progress: 'progress',
  ready: 'ready',
  change: 'change',
  canplay: 'canplay',
  canplaythrough: 'canplaythrough',
  loadeddata: 'loadeddata',
  play: 'play',
  pause: 'pause',
  loadstart: 'loadstart',
  volumechange: 'volumechange',
  seeking: 'seeking',
  seeked: 'seeked',
};

const TEXT_TRACK_MODES = {
  showing: 'showing',
  hidden: 'hidden',
  disabled: 'disabled',
};

const TEXT_TRACK_KINDS = {
  subtitles: 'subtitles', 
  captions: 'captions',
  chapters: 'chapters',
  descriptions: 'descriptions',
  metadata: 'metadata',
};

const AUDIO_TRACK_KINDS = {
  alternative: 'alternative',
  descriptions: 'descriptions',
  main: 'main',
  mainDesc: 'main-desc',
  translation: 'translation',
  commentary: 'commentary',
};

const CHAPTER_DELAY = 2;

const KEYS_CODE = {
  space: 'Space',
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
  keyM: 'KeyM',
  escape: 'Escape',
  controlLeft: 'ControlLeft',
  controlRight: 'ControlRight',
}

const WINDOW_EVENTS = {
  keypress: 'keypress',
  keydown: 'keydown',
  keyup: 'keyup',
  click: 'click',
  dblclick: 'dblclick',
}

const VIDEO_TRACK_LABEL = {
  main: 'main',
  closeLook: 'closeLook' ,
  topShot: 'topShot',
  wideAngel: 'wideAngle',
}

const GENDERS = {
  m: 'male',
  f: 'female',
}

export { 
  PLAYER_EVENTS, 
  TEXT_TRACK_MODES,
  TEXT_TRACK_KINDS, 
  AUDIO_TRACK_KINDS,
  CHAPTER_DELAY,
  KEYS_CODE,
  WINDOW_EVENTS,
  VIDEO_TRACK_LABEL,
  GENDERS, 
};
