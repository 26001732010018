const options = {
  autoplay: false,
  playsinline: true,
  controls: false,
  loop: false,
  fluid: true,
  inactivityTimeout: 0,
  aspectRatio: "16:9",
  children: {
    mediaLoader: true,
    bigPlayButton: false,
    textTrackDisplay: true,
    textTrackSettings: false,
    loadingSpinner: true,
    controlBar: {
      currentTimeDisplay: false,
      timeDivider: false,
      durationDisplay: false,
      remainingTimeDisplay: false,
      pictureInPictureToggle: false,
      volumePanel: false,
      chaptersButton: false,
      subtitlesButton: false,
      audioTrackButton: false,
      subsCapsButton: false,
    },
  },
};

const changePlayerState = (player) => {

  if (player.paused()) {
    return player.play();
  }

  return player.pause();
}

const changeTrackSelectedProp = (tracks, which) => tracks.map(track => ({...track, selected : track.perspective === which}));

const popoverInitState = {
  volume: false,
  voiceOver: false,
  perspective: false,
  subtitles: false,
};

export {
    options,
    popoverInitState,
    changePlayerState,
    changeTrackSelectedProp,
};