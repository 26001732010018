import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './styles';

import { EscButton } from '../Buttons';


const TextTip = ({ onClose, text }) => {
  const { backDrop, content, contentWrapper, ...classes } = useStyles();

  return <Dialog
    classes={classes}
    open={true}
    onClose={onClose}
    BackdropProps={{
      classes: {
        root: backDrop,
      },
    }}
    onEscapeKeyDown={onClose}
  >
    <Grid container direction="column" alignItems="flex-end" wrap="nowrap" classes={{ root: contentWrapper }}>
      <EscButton onClick={onClose}/>
      <div className={content}>{text}</div>
    </Grid>
  </Dialog>;
};

TextTip.propTypes = {
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export {
  TextTip,
};