import { generatePath } from 'react-router';
import qs from 'qs';

import { BaseService } from '../BaseService';

import { API_ROUTES, API_SUB_ROUTES } from '../../constants/routes';

class PartsService extends BaseService {
  constructor(url) {
    super(url);

    this.get = this.get.bind(this);
  }

  async get(id, params = { page: 1, limit: 10 }, options = {}) {
    try {
      const url = generatePath(API_SUB_ROUTES.parts, { id });

      const response = await this.agent.get(url, {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      });

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }
}

const ServiceInstance = new PartsService(API_ROUTES.videos);

export { ServiceInstance as PartsService };
