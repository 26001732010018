import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tools } from '../../components/ProductTabs/Tools';

import { toolsActions } from '../../redux/tools/actions';
import { selectToolsList } from '../../redux/tools/selectors';
import { selectCurrentTab } from '../../redux/product/selectors';
import { selectCurrentPage, selectCurrentPageNumber } from '../../redux/pdf/selectors';
import { selectCurrentChapter, selectCurrentChapterNumber } from '../../redux/chapters/selectors';

import { TABS } from '../../constants/tabs';
import { useIsolatedPlayerState } from '../../hooks/useIsolatedPlayerState';
import { useParams } from 'react-router-dom';

const PlayerToolsView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { items, page, totalItems, hasMoreItems } = useSelector(selectToolsList);
  const currentTab = useSelector(selectCurrentTab);
  const currentPdf = useSelector(selectCurrentPage);
  const currentChapter = useSelector(selectCurrentChapter);
  const currentPdfIndex = useSelector(selectCurrentPageNumber);
  const currentChapterIndex = useSelector(selectCurrentChapterNumber);
  useIsolatedPlayerState();

  const loadList = (loadAction) => (requestParams) => {
      const filters = { ...requestParams.filters };

      if (currentTab === TABS.PDF) {
        filters.pdf = currentPdf.id;
      } else {
        filters.chapter = currentChapter.id;
      }

      dispatch(loadAction(id, { ...requestParams, filters }));
  };

  return (
      <Tools
        renderEscButton
        productId={id}
        page={page}
        items={items}
        totalItems={totalItems}
        hasMoreItems={hasMoreItems}
        currentTab={currentTab}
        currentPdf={currentPdfIndex}
        currentChapter={currentChapterIndex}
        loadList={loadList(toolsActions.listLoad)}
        loadListNextPage={loadList(toolsActions.listLoadNextPage)}
      />
  )
};

export {
  PlayerToolsView,
};
