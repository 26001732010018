import { all, takeLatest, put, call } from 'redux-saga/effects';

import { PartsService } from '../../services/PartsService';
import { appActions } from '../app/actions';
import { sparePartsActions } from './actions';
import { SparePartsUtils } from '../../utils/SparePartsUtils';

function* listLoad({ payload: { videoId, requestData } }) {
  try {
    const { data, meta } = yield call(PartsService.get, videoId, requestData);
    const values =  SparePartsUtils.getFormValuesBySize(data);

    yield put(sparePartsActions.listRefresh(values, meta.smallParts, meta.bigParts));
  } catch (error) {
    yield put(appActions.setError(error));
    yield put(sparePartsActions.listLoadFail());
  }
}

export default function* sparePartsSaga() {
  yield all([
    takeLatest(sparePartsActions.LIST_LOAD, listLoad),
  ]);
}

export {
  listLoad
}
