import { THUMBNAILS_NUMBER } from '../../constants/pdf';

class PDFUtils {
  static getFollowingThumbnailIndexes(currentPage, pages) {
    return new Array(THUMBNAILS_NUMBER)
    .fill(currentPage)
    .reduce((thumbnails, page, i) => {
      const nextPageIndex = currentPage + i + 1;
      if (nextPageIndex < pages) thumbnails.push(nextPageIndex);

      return thumbnails;
    }, []);
  }
}

export { PDFUtils };
