import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { SvgPlayerButton as IconButton } from '../Buttons';

import { ReactComponent as ZoomInIcon } from '../../assets/icons/zoom-in.svg';

import { useStyles } from '../PDFZoom/styles';
import {useSideBySideStyles} from './styles';

const PDFZoomPage = ({ fullWidth, src, pageIndex, onClick }) => {
  const sharedClasses = useStyles();
  const classes = useSideBySideStyles({ fullWidth });

  const handleZoomIn = () =>  {
    onClick(pageIndex);
  }

  return (
    <div className={classes.container}>
      <div className={sharedClasses.pageNumber} onClick={handleZoomIn}>{pageIndex + 1}</div>
      <div className={classes.pageWrapper} onClick={handleZoomIn}>
        <img src={src} alt={`page-${pageIndex}`} />
      </div>
      <IconButton
        classes={{ root: clsx(classes.zoomInButton, sharedClasses.zoomInButton) }}
        onClick={handleZoomIn}
        icon={ZoomInIcon} viewBox="0 0 26 26"
      />
    </div>
  );
};

PDFZoomPage.propTypes = {
  fullWidth: PropTypes.bool,
  src: PropTypes.string.isRequired,
  pageIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export {
  PDFZoomPage,
};