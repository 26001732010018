import { createSelector } from 'reselect';

const tips = ({ Tips }) => Tips;

const tipsUI = ({ Tips }) => Tips.ui;


const selectTipsTotalCount = createSelector(
  [tips],
  ({ topics }) => topics.reduce((acc, { numberOfTips }) => acc + numberOfTips, 0),
);

const selectTopics = createSelector(
  [tips],
  ({ topics }) => topics,
);

const selectTips = createSelector(
  [tips],
  ({ tips }) => tips,
);

const selectExpandedTopics = createSelector(
  [tipsUI],
  (tipsUI) => tipsUI.expandedTopics,
);

export {
  selectTipsTotalCount,
  selectExpandedTopics,
  selectTopics,
  selectTips,
};
