import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pdfActions } from '../../redux/pdf/actions';
import { selectFullscreenZoomState, selectPdfPages, selectPreviousPageNumber } from '../../redux/pdf/selectors';

import { PDFZoom } from '../../components/PDFZoom';
import { KEYS_CODE, WINDOW_EVENTS } from '../../constants/player';

const PDFZoomView = () => {
  const dispatch = useDispatch();
  const { currentPdfIndex, pdfsLength, allPdfs } = useSelector(selectPdfPages);
  const previousPdfIndex = useSelector(selectPreviousPageNumber);
  const isFullscreenZoom = useSelector(selectFullscreenZoomState);

  const changeCurrentPage = useCallback((page) => {
    dispatch(pdfActions.changeCurrentPage(page));
  }, [dispatch, pdfsLength]);

  const setFullscreenZoom = useCallback((isFullscreenZoom) => {
    dispatch(pdfActions.toggleFullscreenZoomState(isFullscreenZoom));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(pdfActions.toggleZoomViewState(false));
      dispatch(pdfActions.changeCurrentPage(previousPdfIndex));
    };
  }, [previousPdfIndex]);

  const onKeyDown = useCallback((e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      switch (e.code) {
        case KEYS_CODE.arrowLeft:
          dispatch(pdfActions.changeCurrentPage(currentPdfIndex - 1 <= 0 ? 0 : currentPdfIndex - 1));
          break;
        case KEYS_CODE.arrowRight:
          dispatch(pdfActions.changeCurrentPage(currentPdfIndex + 1 >= pdfsLength - 1 ? pdfsLength - 1 : currentPdfIndex + 1));
          break;
      }
    }
  }, [currentPdfIndex, pdfsLength, dispatch, pdfActions]);

  useEffect(() => {
    window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    return () => {
      window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <PDFZoom
      currentPdfIndex={currentPdfIndex}
      pdfsLength={pdfsLength}
      allPdfs={allPdfs}
      isFullscreenZoom={isFullscreenZoom}
      setFullscreenZoom={setFullscreenZoom}
      changeCurrentPage={changeCurrentPage}
    />
  );
};

export {
  PDFZoomView,
};
