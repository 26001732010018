import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pdfWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'calc(100% - 5px)',
    [theme.breakpoints.up('extraLg')]: {
      height: 'calc(100% - 8px)'
    },
    '& *': {
      userSelect: 'none',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
    }
  },
}));

export {
  useStyles
};