import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'relative',
    height: '100%',
    '&:after': {
      content: props => props.isModalOpen ? '" "' : '',
      position: 'absolute',
      top: '0',
      left: '20px',
      right: '20px',
      bottom: '0',
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
    },
  },
  list: {
    position: 'relative',
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
    padding: '0 10px 0',
    margin: '0',
    listStyle: 'none',
  },
  listItem: {
    width: '100%',
    height: '100%',
  },
  listItemContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    borderRadius: '20px',
    background: theme.colors.background.card,
    height: '100%',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
    [theme.breakpoints.up('extraLg')]: {
      padding: '50px',
    },
  },
}));

export {
  useStyles,
};