import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    padding: props => props.fullWidth ? '20px 0' : '0 0',
    background: theme.colors.background.primary,
    height: '100%',
  },
  exitButton: {
    position: 'absolute',
    top: '10px',
    right: '60px',
  },
  pageNumber: {
    position: 'absolute',
    top: '3.5px',
    left: '50%',
    width: '100%',
    padding: '2px 0',
    borderRadius: '16px',
    fontWeight: '500',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    lineHeight: '1.2',
    overflow: 'hidden',
    zIndex: 1,
    maxWidth: '40px',
    background: theme.colors.background.chapter,
    fontSize: '1.438rem',
    color: theme.colors.primary,
    [theme.breakpoints.up('extraLg')]: {
      padding: '5px 0',
      maxWidth: '65px',
    },
    '&:hover': {
      cursor: 'pointer',
    }
  },
  zoomInButton: {
    left: '50%',
    width: '65px',
    padding: '8px',
    background: `${theme.colors.secondary}66`,
    borderRadius: '15px !important',
    transform: 'translate(-50%, 50%)',
    zIndex: 1,
    '&:hover': {
      backgroundColor: `${theme.colors.secondary}66 !important`,
    },
    [theme.breakpoints.up('extraLg')]: {
      width: '70px',
      padding: '10px',
      borderRadius: '20px !important',
    },
    [theme.breakpoints.up('xl')]: {
      width: '90px',
      padding: '13px',
    },
  },
}));

export {
  useStyles,
};
