import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tools } from '../../components/ProductTabs/Tools';

import { selectToolsList } from '../../redux/tools/selectors';
import { toolsActions } from '../../redux/tools/actions';

const SummaryToolsView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { items, page, totalItems, hasMoreItems } = useSelector(selectToolsList);

  const loadList = (loadAction) => (requestParams) => {
    dispatch(loadAction(id, requestParams));
  };

  return (
    <Tools
      page={page}
      productId={id}
      items={items}
      totalItems={totalItems}
      hasMoreItems={hasMoreItems}
      loadList={loadList(toolsActions.listLoad)}
      loadListNextPage={loadList(toolsActions.listLoadNextPage)}
    />
  );
};

export {
  SummaryToolsView,
};
