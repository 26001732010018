import React from 'react';
import { useSelector } from 'react-redux';

import { PDFView } from '../PDFView';
import { ChaptersView } from '../ChaptersView';

import { selectCurrentTab } from '../../redux/product/selectors';
import { usePageMode } from '../../hooks/usePageMode';

const tabsMap = {
  PDF: PDFView,
  VIDEO_KAPITEL: ChaptersView,
};

const TabsView = () => {
  const currentTab = useSelector(selectCurrentTab);
  const isFullscreen = usePageMode();
  const TabPanelComponent = tabsMap[currentTab];

  return !isFullscreen ? <TabPanelComponent /> : <></>;
};

export {
  TabsView,
};
