import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  header: {
    padding: '0 20px',
    margin: '0 0 10px',
    ['@media screen and  (min-width: 1600px)']: {
      padding: '0 50px',
    },
    ['@media screen and  (min-height: 1080px) and (min-width: 1600px)']: {
      margin: '0 0 30px',
      padding: '0 40px',
    },
  },
  contentWrapper: {
    height: 'calc(100% - 50px)',
    overflow: 'auto',
    '& .infinite-scroll-component__outerdiv': {
      height: '100%',
    },
  },
  outerContainer: {
    width: '100%',
    height: '100%',
    background: theme.colors.background.thirty,
    boxShadow: '0 20px 56px #f0f1f6',
    border: `1px solid ${theme.colors.outline}`,
    borderRadius: '30px',
    padding: '20px 0 40px',
  },
  list: {
    alignItems: 'flex-start',
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
    height: '100%',
    padding: '0',
    margin: '0',
    listStyle: 'none',
    ['@media screen and  (min-height: 1080px) and (min-width: 1600px)']: {
      padding: '0 20px',
    },
  },
  listItem: {
    height: '50%',
    minHeight: '250px',
    flexGrow: 0,
    maxWidth: '20%',
    flexBasis: '20%',
    padding: '10px 15px',
    ['@media screen and  (min-width: 1600px)']: {
      padding: '10px 45px',
    },
    ['@media screen and  (min-height: 1080px) and (min-width: 1600px)']: {
      padding: '10px 15px',
    },
  },
  listItemContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: '10px 15px 0',
    borderRadius: '30px',
    background: theme.colors.background.primary,
    lineHeight: 1,
    [theme.breakpoints.up('extraLg')]: {
      borderRadius: '30px',
    },
    ['@media screen and  (min-height: 1080px) and (min-width: 1600px)']: {
      padding: '10px',
    },
  },
  selected: {
    background: theme.colors.primary,
    '& $program, & $name, & $number': {
      color: theme.colors.text.fifth,
    },
  },
  listItemLink: {
    display: 'block',
    height: '100%',
    color: theme.colors.text.thirty,
    textDecoration: 'none',
  },
  thumbnailHolder: {
    borderRadius: '20px',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('extraLg')]: {
      borderRadius: '30px',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  program: {
    margin: '20px 0 12px',
    color: theme.colors.primary,
    fontSize: '1rem',
    fontWeight: 600,
    ['@media screen and  (min-height: 1080px) and (min-width: 1600px)']: {
      margin: '25px 0 12px',
    },
  },
  name: {
    margin: '0 0 12px',
    fontSize: '1.563rem',
    fontWeight: 600,
  },
  number: {
    color: theme.colors.text.quaternary,
    fontSize: '1.125rem',
    fontWeight: 500,
    margin: '0 0 15px',
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 0 20px',
    },
    ['@media screen and  (min-height: 1080px) and (min-width: 1600px)']: {
      margin: '0 0 30px',
    },
  },
  loading: {
    '&:after': {
      content: '" "',
      display: 'block',
      height: '3px',
      width: '1px',
    }
  }
}));

export {
  useStyles,
};
