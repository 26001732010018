import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Grid from '@material-ui/core/Grid';

import { ListCell } from './ListCell';
import { useStyles } from './styles';

const ListView = ({ items, columns, isModalOpen, loadListNextPage, hasMoreItems, onItemClick }) => {
  const classes = useStyles({ isModalOpen, onItemClick });

  const renderListItems = item => (
    <Grid
      container
      key={item.id}
      component="li"
      classes={{ root: classes.listItem }}
      spacing={2}
      onClick={() => onItemClick(item.id)}
    >
      {columns.map(({ field, valueGetter, width }) => {
          const value = item[field];
          const parsedValue = valueGetter ? valueGetter(value, item) : value;

          return <ListCell item key={field} width={width} value={parsedValue}/>;
        },
      )}
    </Grid>
  );

  return (
    <Grid container className={classes.listViewContainer} direction="column">
      <Grid container classes={{ root: classes.listHeader }} spacing={2}>
        {columns.map(({ label, field, width }) => <ListCell item key={field} width={width} value={label}/>)}
      </Grid>
      <div className={classes.listWrapper}>
        <InfiniteScroll
          dataLength={items.length}
          next={loadListNextPage}
          hasMore={hasMoreItems}
          height="100%"
          loader={<></>}
        >
          <ul>
            {items.map(renderListItems)}
          </ul>
        </InfiniteScroll>
      </div>
    </Grid>
  );
};

ListView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    width: PropTypes.string,
    valueGetter: PropTypes.func,
  })).isRequired,
  onItemClick: PropTypes.func.isRequired,
  loadListNextPage: PropTypes.func.isRequired,
};

export {
  ListView,
};