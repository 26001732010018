import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    ['@media (pointer: coarse)']: {
      padding: '6px 0',
    },
    padding: '6px 0',
  },
  thumb: {
    marginTop: '-7px',
    width: '17px',
    height: '17px',
    background: props => props.audioPlayer ? '#000' : theme.colors.secondary,
    '&:hover, &:focus': {
      boxShadow: 'none',
    },
  },
  track: {
    background: props => props.audioPlayer ? '#000' : theme.colors.thirty,
  },
  rail: {
    background: 'rgba(0, 0, 0, 0.1)',
    opacity: 1,
  },
  svgIcon: {
    fontSize: props => props.audioPlayer ? '0.6em' : '1em',
    color: props => props.audioPlayer ? '#000' : 'none',
  },
}));

export {
  useStyles,
};