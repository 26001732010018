import { StoreUtils } from '../../utils';

const prefix = '[Product]';
const { createAction } = StoreUtils;

const TYPES = {
  FETCH_PRODUCT: `${prefix} fetch-product`,
  FETCH_PRODUCT_SUCCESS: `${prefix} fetch-product_success`,
  FETCH_PRODUCT_FAIL: `${prefix} fetch-product_fail`,
  SWITCH_TAB: `${prefix} switch-tab`,
  SET_PREVIOUS_TAB: `${prefix} set-previous-tab`,
  TOGGLE_SIDEBAR_SCREEN: `${prefix} toggle-sidebar-state`,
};

export const productActions = {
  ...TYPES,
  fetchProduct: createAction(TYPES.FETCH_PRODUCT, 'productId'),
  fetchProductSuccess: createAction(TYPES.FETCH_PRODUCT_SUCCESS, 'product'),
  fetchProductFail: createAction(TYPES.FETCH_PRODUCT_FAIL, 'error'),
  switchTab: createAction(TYPES.SWITCH_TAB, 'tab'),
  setPreviousTab: createAction(TYPES.SET_PREVIOUS_TAB, 'tab'),
  toggleSidebarState: createAction(TYPES.TOGGLE_SIDEBAR_SCREEN, 'isSidebarDisabled'),
};
