import { UI_ROUTES } from '../../constants/routes';

const navBarItems = [
  { label: 'Zusammenfassung', path: UI_ROUTES.summary },
  { label: 'Video Player', path: UI_ROUTES.videoPlayer },
  { label: 'Ersatzteile', path: UI_ROUTES.spareParts },
]

export {
  navBarItems,
};
