import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { AspectRatioBox } from '../../components/AspectRatioBox';
import { TipsVideoPlayer } from '../../components/TipsVideoPlayer';

import { selectVolume } from '../../redux/player/selectors';

import { playerActions } from '../../redux/player/actions';

const TipsVideoView = ({ videoSrc }) => {
  const dispatch = useDispatch();
  const volume = useSelector(selectVolume);

  const onPlayerDispose = useCallback(
    (volumeLevel) => {
      dispatch(playerActions.setVolume(volumeLevel));
    },
    [dispatch],
  );

  const props = {
    videoSrc,
    onPlayerDispose,
    volume,
  };

  return (
    <AspectRatioBox>
      <TipsVideoPlayer {...props}/>
    </AspectRatioBox>
  );
};

TipsVideoView.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export {
  TipsVideoView,
};
