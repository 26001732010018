import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { IndexNumber } from '../../IndexNumber';

import { useStyles } from './styles';
import { FormatUtils } from '../../../utils/FormatUtils';
import { selectSidebarState } from '../../../redux/product/selectors';

const Chapter = ({ chapter, chapterIndex, selected }) => {
  const isSidebarDisabled = useSelector(selectSidebarState);
  const classes = useStyles({ isSidebarDisabled });
  const { startTime, endTime, thumbnail, name } = chapter;

  const memoizedChapterDuration = useMemo(() => FormatUtils.formatChapterDuration(startTime, endTime), [startTime, endTime]);

  return (
    <div className={clsx(classes.chapterWrapper, { [classes.selected]: selected })}>
      <IndexNumber mini selected={selected} disabled={isSidebarDisabled} value={chapterIndex + 1} />
      <div className={classes.chapterThumbnailWrapper}>
        <img className={classes.chapterThumbnail} src={thumbnail} alt={name} />
      </div>
      <Grid container direction="column" justify="center" classes={{ root: classes.chapterInfoWrapper }}>
        <p className={classes.chapterName}>{name}</p>
        <span className={classes.chapterDuration}>{memoizedChapterDuration}</span>
      </Grid>
    </div>
  );
};

Chapter.propTypes = {
  chapter: PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
  }),
  chapterIndex: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
};

export {
  Chapter,
};