import { tipsActions } from './actions';

export const initState = {
  topics: [],
  tips: {},
  ui: {
    expandedTopics: [],
  },
};

export default function tipReducer(state = initState, { type, payload }) {
  switch (type) {
    case tipsActions.LIST_TOPICS_REFRESH: {
      return {
        ...state,
        topics: payload.topics,
      };
    }
    case tipsActions.LIST_TIPS_REFRESH: {
      return {
        ...state,
        tips: {
          ...state.tips,
          [payload.topicId]: payload.tips,
        },
      };
    }
    case tipsActions.EXPAND_TOPIC: {
      return {
        ...state,
        ui: {
          ...state.ui,
          expandedTopics: [...state.ui.expandedTopics, payload.topic],
        },
      };
    }
    case tipsActions.COLLAPSE_TOPIC: {
      return {
        ...state,
        ui: {
          ...state.ui,
          expandedTopics: state.ui.expandedTopics.filter(item => item !== payload.topic),
        },
      };
    }
    default: {
      return state;
    }
  }
}
