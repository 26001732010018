import { generatePath } from 'react-router-dom';
import qs from 'qs';

import { BaseService } from '../BaseService';

import { API_ROUTES, API_SUB_ROUTES } from '../../constants/routes';

class PdfService extends BaseService {
  constructor(url) {
    super(url);

    this.get = this.get.bind(this);
  }

  async get(id, params = { withConstructionTime: false }, options = {}) {
    try {

      const url = generatePath(API_SUB_ROUTES.pdf, { id });

      const response = await this.agent.get(url, {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      });

      const result = this.processResponse(response);
      return Promise.resolve(result);

    } catch (error) {
      const result = this.processError(error, options);
      return Promise.reject(result);
    }
  }

}

const ServiceInstance = new PdfService(API_ROUTES.videos);

export { ServiceInstance as PdfService };
