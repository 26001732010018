import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  selected: {
    background: theme.colors.primary,
    color: '#fff',
  },
  listViewContainer: {
    padding: '0 0 0 20px',
  },
  listWrapper: {
    overflow: 'auto',
 //   padding: '0 20px 0 0',
    '& ul': {
      margin: 0,
    },
    // [theme.breakpoints.up('extraXl')]: {
    //   padding: '0 110px 0 0',
    // },
    position: 'relative',
    zIndex: 1,
  },
  listContainer: {
    height: '100%',
    padding: '0 20px 0 0 !important',
    [theme.breakpoints.up('extraXl')]: {
      padding: '0 110px 0 0 !important',
    },
  },
  listHeader: {
    width: 'calc(100% - 2.56px)',
    padding: '0 20px 15px 0',
    '& > div:first-child': {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('extraXl')]: {
      padding: '0 110px 15px 0',
    },
  },
  listItem: {
    background: 'none',
    minHeight: '40px',
    [theme.breakpoints.up('lg')]: {
      minHeight: '50px',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: '60px',
    },
    '& > div:first-child': {
      paddingLeft: '0',
    },
    '&:hover': {
      cursor: 'default',
    },
  },
  sublistCell: {
    fontSize: '1.25rem !important',
    fontWeight: '400 !important',
  },
  pdfColumnContent: {
    textAlign: 'center',
    maxWidth: '65px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '70px',
    },
    [theme.breakpoints.up('extraLg')]: {
      maxWidth: '75px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '87px',
    },
  },
  buttonsWrapper: {
    '& button': {
      width: '25px',
      height: '25px',
      margin: '0 3px',
      [theme.breakpoints.up('lg')]: {
        width: '30px',
        height: '30px',
        margin: '0 5px',
      },
      [theme.breakpoints.up('extraLg')]: {
        width: '35px',
        height: '35px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '40px',
        height: '40px',
      },
      [theme.breakpoints.up('extraXl')]: {
        width: '50px',
        height: '50px',
      },
    },
    '& button:last-child': {
      margin: 0,
    }
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '26px',
    height: '26px',
    backgroundColor: '#fc8181',
    border: '1px solid #ffffff',
    color: '#fff',
    borderRadius: '19px',
    fontSize: '0.938rem',
    fontWeight: '700',
    boxShadow: '0 5px 6px rgba(208, 118, 97, 0.2)',
    lineHeight: 1,
    [theme.breakpoints.up('extraLg')]: {
      width: '30px',
      height: '30px',
      fontSize: '1.125rem',
    },
    [theme.breakpoints.up('extraXl')]: {
      width: '34px',
      height: '34px',
      fontSize: '1.125rem',
    },
  },
  indicatorWrapper: {
    maxWidth: '100px',
    width: '100%',
  },
  disabled: {
    opacity: 0.5,
  },
}));

const useCellStyles = makeStyles(theme => ({
  listCell: {
    position: 'relative',
    fontWeight: 700,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.375rem',
    },
  },
}));


export {
  useStyles,
  useCellStyles,
};