import { chaptersActions } from './actions';

const initState = {
  chapter: 0,
  previousChapter: 0,
  chapters: [],
  loading: false,
};

export default function chaptersReducer(state = initState, { type, payload }) {
  switch (type) {
    case chaptersActions.CHANGE_CURRENT_CHAPTER: {
      return {
        ...state,
        chapter: payload.chapter,
      };
    }
    case chaptersActions.RELOAD_CHAPTERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case chaptersActions.REFRESH_CHAPTERS: {
      return {
        ...state,
        chapters: payload.chapters,
        loading: false,
      };
    }
    case chaptersActions.REFRESH_CHAPTERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case chaptersActions.SET_PREVIOUS_CHAPTER: {
      return {
        ...state,
        previousChapter: payload.chapter,
      };
    }
    default: {
      return state;
    }
  }
}

export {
  initState,
};
