import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'block',
    flex: '1 0 auto',
    maxHeight: 'calc(100vh - 100px)',
    background: theme.colors.background.primary,
    padding: '30px 20px',
    borderRadius: '25px 25px 0 0',
    [theme.breakpoints.up('lg')]: {
      padding: '30px 40px',
    },
    ['@media (pointer: coarse) and (max-height: 900px)']: {
      maxHeight: 'calc(100vh - 80px)',
    },
    ['@media (pointer: fine) and (min-aspect-ratio: 19/9)']: {
      maxHeight: 'calc(100vh - 80px)',
    },
  },
  contentWrapper: {
    height: '100%',
  },
}));

export {
  useStyles,
};