import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    width: '26%',
    minWidth: '400px',
    maxWidth: '700px',
    height: '100%',
    padding: '20px',
    paddingTop: '39px',
    [theme.breakpoints.up('extraLg')]: {
      padding: '40px',
      minWidth: '450px',
    },
  },
  controlButton: {
    padding: '0',
    margin: '0 25px 0 0',
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 35px 0 0',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'unset',
    },
    '&:focus': {
      outline: 'none',
    },
    '& svg': {
      fontSize: '2.5rem',
      color: theme.colors.primary,
    },
  },
  iconCloseButton: {
    color: theme.colors.primary,
    width: '32px',
    height: '32px',
    fontSize: '32px',
    padding: '0px',
    '&:hover': {
      background: 'none',
    },
  },
  svgCloseIcon: {
    fontSize: '1em',
  },
  list: {
    margin: '40px 0 0',
    padding: '0',
    [theme.breakpoints.up('extraLg')]: {
      margin: '60px 0 0',
    },
  },
  listItem: {
    padding: 0,
    borderBottom: '1px solid rgba(120, 124, 132, 0.2)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  listItemLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '21px 0',
    color: theme.colors.text.primary,
    fontSize: '1.375rem',
    textDecoration: 'none',
  },
  listItemLinkDisabled: {
    opacity: 0.4,
    '&:hover': {
      cursor: 'default',
    },
  },
  listItemIcon: {
    minWidth: '28px',
    margin: '0 15px 0 0',
    color: theme.colors.primary,
    '& svg, & svg path': {
      fill: theme.colors.primary,
    },
  },
  link: {
    margin: 'auto 0 0',
    textAlign: 'center',
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export {
  useStyles,
};
