import React from 'react';
import PropTypes from 'prop-types';
import TabsList from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { matchPath, useLocation } from 'react-router-dom';
import { UI_ROUTES } from '../../../../constants/routes';

import { tabs } from './assets';
import { useTabsStyles, useTabStyles } from './styles';
import clsx from 'clsx';
import { ButtonBase } from '@material-ui/core';

const Tabs = ({ currentTab, switchTab, disabled }) => {
  const { disabled: tabsDisabledClass, ...tabsClasses } = useTabsStyles();
  const tabClasses = useTabStyles({tabs});
  const location = useLocation();

  const matchSummaryPath = matchPath(location.pathname, {
    path: UI_ROUTES.summary,
  });

  return (
    <div>
      <TabsList
        centered
        classes={{
          ...tabsClasses,
          root: clsx(tabsClasses.root, { [tabsDisabledClass]: disabled }),
        }}
        value={matchSummaryPath ? false : currentTab}
        onChange={switchTab}
        TabIndicatorProps={{ children: <span /> }}
      >
        {tabs.map(({ label, value }) =>
          <Tab
            classes={tabClasses}
            key={value}
            disabled={disabled}
            // eslint-disable-next-line react/display-name
            component={React.forwardRef((props, ref) =>
              <ButtonBase disableRipple {...props} ref={ref}>
                <span className={tabClasses.wrapped} data-label={label}>{label}</span>
              </ButtonBase>
            )}
            disableRipple
            label={label}
            value={value}
          />,
        )}
      </TabsList>
    </div>
  );
};


Tabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  switchTab: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export {
  Tabs,
};

