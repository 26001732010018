import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0 0 7px 0',
    fontSize: '1.15rem',
    fontWeight: 'bold',
    padding: '0 8px',
  },
  button: {
    color: 'inherit',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
    '&:hover': {
      background: 'none',
    },
  },
  iconButton: {
    width: '30px',
    height: '30px',
    fontSize: '30px',
    padding: '0',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      width: '35px',
      height: '35px',
      fontSize: '35px',
    },
    [theme.breakpoints.up('extraXl')]: {
      width: '40px',
      height: '40px',
      fontSize: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '45px',
      height: '45px',
      fontSize: '45px',
    },
  },
  svgIcon: {
    fontSize: '1em',
    color: theme.colors.thirty,
    '& path': {
      fill: theme.colors.thirty,
    },
  },
  iconButtonDisabled: {
    '& path': {
      fill: '#FFF',
    },
  },
  list: {
    padding: 0,
  },
  listItem: {
    background: 'none',
    color: '#000',
    height: '30px',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      height: '40px',
    },
  },
  listItemDisabled: {
    color: 'rgba(0, 0, 0, .35)',
  },
  listItemSelected: {
    backgroundColor: `${theme.colors.thirty}1a !important`,
    borderRadius: '5px',
    color: theme.colors.thirty,
    '& path': {
      fill: theme.colors.thirty,
    },
  },
}));

export { useStyles };
