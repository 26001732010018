import { UI_ROUTES } from '../../constants/routes';

import { ReactComponent as UnarchiveBoxIcon } from '../../assets/icons/unarchive-box.svg';
import { ReactComponent as VideoCameraIcon } from '../../assets/icons/video-camera.svg';
import { ReactComponent as MagicWandIcon } from '../../assets/icons/magic-wand.svg';

const burgerMenuItems = [
  { label: 'Weitere Produkte und Medien', path: UI_ROUTES.moreProductsAndMedia, icon: UnarchiveBoxIcon, disabled: true },
  { label: 'Weitere Aufbauvideos', path: UI_ROUTES.moreAssemblyVideo, icon: VideoCameraIcon },
  { label: 'Tour', path: UI_ROUTES.tour, icon: MagicWandIcon, disabled: true },
];

export {
  burgerMenuItems,
};
