import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

const SelectButton = ({ value, selected, disabled, size, icon: Icon, setSelectValue }) => {
  const classes = useStyles();

  return (
    <Button
      disableRipple
      disabled={disabled}
      classes={{
        root: clsx(classes.selectButton, {
          [classes.selected]: selected,
          [classes.disabled]: disabled,
          [classes.big]: size === 'big',
        }),
      }}
      value={value}
      onClick={() => setSelectValue(value)}
    >
      <Icon/>
    </Button>
  );
};

SelectButton.propTypes = {
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
  setSelectValue: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['big']),
};

export {
  SelectButton,
};