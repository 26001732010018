import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';

import { ButtonsPopover } from '../ButtonsPopover';
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
import { ReactComponent as CheckLineIcon } from '../../assets/icons/check-line.svg';
import { perspectiveMenuItems, POPOVER_NAME } from './assets';
import { useStyles } from './styles';
import { SvgPlayerButton } from '../Buttons';

const PerspectiveButton = ({ availablePerspectives, videoTracks, open, onChange, onOpen, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const classes = useStyles();

  useEffect(() => {
    setSelectedIndex(videoTracks.findIndex(track => track.selected));
  }, [videoTracks]);
  
  const handleListItemClick = (index, perspective) => () => {
    if(index !== selectedIndex) {
      setSelectedIndex(index);
      onChange(perspective);
      onClose();
    }  
  };
  
  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    onOpen(POPOVER_NAME);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <SvgPlayerButton
        onClick={handleClick} 
        icon={CameraIcon} 
        viewBox="0 0 50 50"  
        buttonClasses={{ root: classes.iconButton, disabled: classes.iconButtonDisabled }}
        svgClasses={{root: classes.svgIcon}}
        disabled={availablePerspectives && Object.values(availablePerspectives).filter(elem => elem).length === 1}
      />
      <ButtonsPopover open={open} onClose={handleClose} anchorEl={anchorEl}>
        <p className={classes.title}>Perspektive</p>
        <List component="ul" classes={{root: classes.list}}>
          {perspectiveMenuItems.map(({label, perspective}) => {
            const currentTrack = videoTracks.findIndex( track => track.perspective === perspective);

            return (
              <ListItem
                selected={selectedIndex === currentTrack}
                key={perspective}
                disabled={availablePerspectives && !availablePerspectives[perspective]}
                classes={{root: classes.listItem, disabled: classes.listItemDisabled, selected: classes.listItemSelected}}
              >
                <Button
                  variant="text"
                  color="default"
                  disableRipple
                  disableFocusRipple
                  endIcon={selectedIndex === currentTrack ? <CheckLineIcon/> : null}
                  onClick={handleListItemClick(currentTrack, perspective)}
                  classes={{root: classes.button}}
                  disabled={availablePerspectives && !availablePerspectives[perspective]}
                >
                  {label}
                </Button>
              </ListItem>
            )}
          )}
        </List>
      </ButtonsPopover>
    </>
  )
}

PerspectiveButton.propTypes = {
  availablePerspectives: PropTypes.object.isRequired,
  videoTracks: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export {
  PerspectiveButton,
}
