import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CloseTabButton } from '../CloseTabButton';
import { ProductTabContent } from '../ProductTabContent';
import { SectionTitle } from '../../SectionTitle';
import { PeopleList } from '../../PeopleList';

import { listColumns } from './assets';

const People = ({ peopleList, pdfTimestamps, chapterTimestamps, renderEscButton, productId, selectedItemRef, checkIsItemSelected, ...rest }) => {
  const items = useMemo(() => {
    return pdfTimestamps.map((pdf, i) => {
      const chapterIndex = chapterTimestamps.findIndex(chapter => pdf.startTime >= chapter.startTime && pdf.startTime < chapter.endTime);
      return {
        ...peopleList[chapterIndex],
        pdfPage: i + 1,
      }
    })
  }, [peopleList, pdfTimestamps, chapterTimestamps]);

  const title = <SectionTitle title="Personen und Details"/>;
  const controls = (
    <>
      {renderEscButton && <CloseTabButton productId={productId}/>}
    </>
  );

  return (
    <ProductTabContent title={title} controls={controls}>
      <PeopleList
        selectedItemRef={selectedItemRef}
        items={items}
        columns={listColumns}
        checkIsItemSelected={checkIsItemSelected}
        {...rest}
      />
    </ProductTabContent>
  );
};

People.propTypes = {
  peopleList: PropTypes.arrayOf(PropTypes.shape({
    chapterId: PropTypes.number.isRequired,
    numberOfDocuments: PropTypes.number.isRequired,
    numberOfPeople: PropTypes.number.isRequired,
    difficultyLevel: PropTypes.string.isRequired,
    orderDependency: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  chapterTimestamps: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
  })),
  pdfTimestamps: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
  })),
  productId: PropTypes.string.isRequired,
  renderEscButton: PropTypes.bool,
  selectedItemRef: PropTypes.func,
  checkIsItemSelected: PropTypes.func,
};

export {
  People,
};
