import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import { SparePartsUtils } from '../../utils/SparePartsUtils';

import { OrderButton } from '../Buttons';
import { SparePartsList } from './SparePartsList';
import { ListCell } from './SparePartsListCell';
import { SparePartsDetailsModal } from './SparePartsDetailsModal';

import { useStyles } from './styles';
import { useStyles as useListStyles } from '../ListView/styles';
import { listColumns } from './assets';

const SparePartsForm = ({
  values,
  sizeFilter,
}) => {
  const [isModalOpen, setModalState] = useState(false);
  const [selectedPartId, setSelectedPart] = useState('');
  const sharedListClasses = useListStyles();
  const classes = useStyles({ isModalOpen });

  const initialValues = useMemo(() => SparePartsUtils.generateInitialFormValue(values), [values]);
  const selectedPart = [...values.small, ...values.big].find(({ id }) => id === selectedPartId);

  const closeModal = () => setModalState(false);

  const onPartClick = (value) => {
    setSelectedPart(value);
    setModalState(true);
  };

  const onSubmit = (values) => {
    console.log('submit', values);
  };

  return (
    <Grid container className={sharedListClasses.listViewContainer} direction="column">
      <Grid
        container
        classes={{ root: clsx(sharedListClasses.listHeader, classes.listHeader) }}
        justify="space-between"
        spacing={2}
      >
        {listColumns.map(({ label, ...columnProps }, index) =>
          <ListCell item key={index} {...columnProps}>{label}</ListCell>)}
      </Grid>
      <div className={sharedListClasses.listWrapper}>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
          {({ values: formValues, setFieldValue }) => {
            const itemsCount = SparePartsUtils.getOrderItemsCount(formValues.parts);

            return (
              <Form className={classes.form}>
                <FieldArray name="parts">
                  <SparePartsList
                    prefix="parts"
                    values={formValues}
                    sizeFilter={sizeFilter}
                    onPartClick={onPartClick}
                    setFieldValue={setFieldValue}
                  />
                </FieldArray>
                <div className={classes.buttonWrapper}>
                  <OrderButton type="submit" itemsCount={itemsCount} disabled={!itemsCount} />
                </div>
              </Form>
            );
          }}
        </Formik>
        {selectedPart && <SparePartsDetailsModal
          isOpen={isModalOpen}
          part={selectedPart}
          closeModal={closeModal}
        />}
      </div>
    </Grid>
  );
};

SparePartsForm.propTypes = {
  values: PropTypes.shape({
    small: PropTypes.arrayOf(PropTypes.object).isRequired,
    big: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  sizeFilter: PropTypes.string.isRequired,
};

export {
  SparePartsForm,
};