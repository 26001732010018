import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tips } from '../../components/ProductTabs/Tips';

import { tipsActions } from '../../redux/tips/actions';
import { selectTipsTotalCount, selectTopics, selectTips } from '../../redux/tips/selectors';

const SummaryTipsView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const totalTipsCount = useSelector(selectTipsTotalCount);
  const topics = useSelector(selectTopics);
  const tips = useSelector(selectTips);
  const [expandedTopics, setTopic] = useState([]);

  useEffect(() => {
    dispatch(tipsActions.listTopicsLoad(id));
  }, []);

  const toggleTopic = (topicId) => {
    if (expandedTopics.includes(topicId)) {
      setTopic(state => state.filter(item => item !== topicId));
      return;
    }

    dispatch(tipsActions.listTipsLoad(id, { filters: { topic: topicId } }));
    setTopic(state => [...state, topicId]);
  };

  return (
    <Tips
      totalTipsCount={totalTipsCount}
      expandedTopics={expandedTopics}
      toggleTopic={toggleTopic}
      topics={topics}
      tips={tips}
      productId={id}
    />
  );
};

export {
  SummaryTipsView,
};
