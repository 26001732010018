import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';

import { ListCell } from './TipsListCell';
import { MediaButton } from '../Buttons';
import { TextTip } from '../TextTip';
import { VideoTip } from '../VideoTip';
import { AudioTip } from '../AudioTip';

import { useStyles as useListStyles } from '../ListView/styles';
import { useStyles } from './styles';

import { TIPS_TYPE } from '../../constants/tips';
import { mediaList } from './assets';
import { TipsUtils } from '../../utils/TipsUtils';

const listItemProps = {
  container: true,
  spacing: 1,
  wrap: 'nowrap',
  justify: 'space-between',
};

const TipsSublist = ({ tips, columns }) => {
  const sharedListClasses = useListStyles();
  const classes = useStyles();
  const defaultColumnWidth = `${(100 / columns.length)}%`;
  const [selectedTip, setSelectedTip] = useState({});
  const [tipType, setTipType] = useState('');

  const sortedTips = TipsUtils.sortTipsArray(tips);

  const openTip = (item, tipType) => {
    setSelectedTip(item);
    setTipType(tipType);
  };

  const closeTip = () => {
    setSelectedTip({});
    setTipType('');
  };

  const getColumnValue = (field, item) => {
    if (!field) return '';

    if (field === 'category') {
      return item[field].name;
    }

    if (field === 'chapter') {
      return <div>{item.chapters[0].name}</div>;
    }

    if (field === 'pdf') {
      return <div className={classes.pdfColumnContent}>{item.videoDocument.page}</div>;
    }

    if (field === 'media') {
      return (
        <Grid className={classes.buttonsWrapper} container wrap="nowrap">
          {mediaList.map(({ icon, viewBox, tipType }) => (
            <MediaButton
              key={tipType}
              icon={icon}
              viewBox={viewBox}
              disabled={tipType === TIPS_TYPE.VIDEO ? !item.video_src : !item[tipType]}
              onClick={() => openTip(item, tipType)}
            />),
          )}
        </Grid>
      );
    }

    return item[field];
  };

  const renderSubListItems = (item) => (
    <Grid
      key={uuidv4()}
      component="li"
      classes={{ root: clsx(sharedListClasses.listItem, classes.listItem) }}
      {...listItemProps}
    >
      {columns.map(({ field, width, ...props }) => {
          const children = getColumnValue(field, item);

          return (
            <ListCell
              item
              key={uuidv4()}
              width={width || defaultColumnWidth}
              listCellClassName={classes.sublistCell}
              {...props}
            >{children}</ListCell>
          );
        },
      )}
    </Grid>
  );

  return (
    <>
      <ul>{sortedTips.map(renderSubListItems)}</ul>
      {tipType === TIPS_TYPE.TEXT && <TextTip text={selectedTip.text} onClose={closeTip}/>}
      {tipType === TIPS_TYPE.VIDEO && <VideoTip name={selectedTip.name} videoSrc={selectedTip.video_src} onClose={closeTip}/>}
      {tipType === TIPS_TYPE.AUDIO && <AudioTip audio={selectedTip.audio} onClose={closeTip}/>}
    </>
  );
};

TipsSublist.propTypes = {
  tips: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    videoId: PropTypes.number.isRequired,
    audio: PropTypes.string,
    video_src: PropTypes.string,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    chapters: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    videoDocument: PropTypes.shape({
      id: PropTypes.number.isRequired,
      page: PropTypes.number.isRequired,
    }).isRequired,
  })).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string.isRequired,
    width: PropTypes.string,
    align: PropTypes.oneOf(['center', 'flex-end']),
  })).isRequired,
};

export {
  TipsSublist,
};