import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'static',
    boxShadow: 'none',
    background: '#fff',
    padding: '0 !important',
  },
  toolbar: {
    display: 'flex',
    minHeight: 'auto',
    padding: '25px',
    background: theme.colors.background.header,
    borderRadius: '25px 25px 0 0',
    [theme.breakpoints.up('extraLg')]: {
      padding: '25px 76px 25px 0',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '20px 40px',
    },
    ['@media (pointer: coarse) and (max-height: 900px)']: {
      padding: '10px 25px',
      fontSize: '.9rem',
    },
    ['@media (pointer: fine) and (min-aspect-ratio: 19/9)']: {
      padding: '10px 25px',
      fontSize: '.9rem',
    },
  },
  contextMenuWrapper: {
    [theme.breakpoints.up('extraLg')]: {
      marginLeft: 'auto',
    },
  },
  tabsWrapper: {
    [theme.breakpoints.up('extraLg')]: {
      width: '30%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '27.2%',
      margin: '0 40px 0 0',
    },
  },
  productName: {
    margin: '0 auto',
    color: theme.colors.text.secondary,
    fontSize: '1.5rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('extraLg')]: {
      margin: 0,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.625rem',
    },
    [theme.breakpoints.up('extraXl')]: {
      fontSize: '1.875rem',
    },
  },
}));


export {
  useStyles,
};