import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { generatePath, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

import { Icon } from '../Icon';

import { useStyles } from './styles';
import { burgerMenuItems } from './assets';
import { playerActions } from '../../redux/player/actions';
import { WB_URL } from '../../constants/routes';


const BurgerMenu = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
    dispatch(playerActions.toggleBurgerMenuState(true));
  };

  const closeMenu = () => {
    setMenuOpen(false);
    dispatch(playerActions.toggleBurgerMenuState(false));
  };

  const onMenuItemClick = disabled => (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    closeMenu();
  };

  const menuItems = useMemo(
    () =>
      burgerMenuItems.map(({ path, ...item }) => ({
        ...item,
        path: generatePath(path, { id }),
      })),
    [id],
  );

  return (
    <>
      <IconButton classes={{ root: classes.controlButton }} onClick={openMenu}>
        <MenuIcon />
      </IconButton>
      <Drawer
        classes={{ paper: classes.drawer }}
        anchor="left"
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <IconButton classes={{ root: classes.iconCloseButton }} onClick={closeMenu}>
          <CloseIcon classes={{ root: classes.svgCloseIcon }} />
        </IconButton>
        <List classes={{ root: classes.list }}>
          {menuItems.map(({ label, path, icon: IconComponent, disabled }) => (
            <ListItem key={path} classes={{ root: classes.listItem }}>
              <NavLink
                to={path}
                className={clsx(classes.listItemLink, { [classes.listItemLinkDisabled]: disabled })}
                onClick={onMenuItemClick(disabled)}
              >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <Icon component={IconComponent} />
                </ListItemIcon>
                {label}
              </NavLink>
            </ListItem>
          ))}
        </List>
        <a href={WB_URL} target='_blank' rel='noreferrer' className={classes.link}>Powered by Watch & Build</a>
      </Drawer>
    </>
  );
};

BurgerMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export {
  BurgerMenu,
};