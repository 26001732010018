import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { CloseButton } from '../../Buttons/CloseButton/CloseButton';
import { Modal } from '../../Modal';
import { AspectRatioBox } from '../../AspectRatioBox';

import { useStyles } from './styles';
import { partProperties } from './assets';

const PartsDetailsModal = ({
  isOpen,
  part,
  closeModal,
  isInformationTabOpen,
  informationTabControl,
  informationTabContent,
}) => {
  const classes = useStyles({ informationTabControl });

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Grid container wrap="nowrap" classes={{ root: classes.modalContent }}>
        <div className={classes.leftColumn}>
          <AspectRatioBox width={1} height={1}>
            <div className={classes.thumbnailHolder}>
              <img src={part.thumbnail} alt={part.name}/>
            </div>
          </AspectRatioBox>
        </div>
        <div className={classes.controlButtons}>
          {!isInformationTabOpen && informationTabControl}
          <CloseButton onClick={closeModal}/>
        </div>
        <Grid container justify="center" direction="column" wrap="nowrap">
          <div className={classes.partProperty}>
            <h3 className={classes.partName}>
              {part.name}
              {isInformationTabOpen && <ReportProblemOutlinedIcon/>}
            </h3>
          </div>
          {isInformationTabOpen
            ? informationTabContent
            : partProperties.map(({ field, label }) => (
              <div key={field} className={classes.partProperty}>
                <span className={classes.partPropertyLabel}>{label}</span>
                <span>{part[field]}</span>
              </div>
            ))}
        </Grid>
      </Grid>
    </Modal>
  );
};

PartsDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  part: PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    quantity: PropTypes.number,
    partNumber: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  isInformationTabOpen: PropTypes.bool,
  informationTabControl: PropTypes.elementType,
  informationTabContent: PropTypes.elementType,
};

export {
  PartsDetailsModal,
};
