import React from 'react';
import PropsTypes from 'prop-types';
import { matchPath, useLocation, useHistory, useParams, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { Tabs } from './Tabs';
import { ContextMenu } from './ContextMenu';
import { useStyles } from './style';

import { selectProduct, selectCurrentTab } from '../../../redux/product/selectors';
import { productActions } from '../../../redux/product/actions';
import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../../constants/routes';

const Header = ({ menuItems, tipsTotalCount }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector(selectProduct);
  const currentTab = useSelector(selectCurrentTab);
  const { id } = useParams();
  const matchRoutes = matchPath(pathname, {
    path: [`${UI_ROUTES.videoPlayer}${CONTEXT_MENU_ROUTES.people}`, UI_ROUTES.spareParts],
    exact: true,
  });

  const matchSummary = matchPath(pathname, {
    path: `${UI_ROUTES.summary}`,
  });

  const switchTab = (e, tab) => {
    dispatch(productActions.switchTab(tab));

    if (matchSummary) {
      history.push(generatePath(UI_ROUTES.videoPlayer, { id }));
    }
  };

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.tabsWrapper}>
          <Tabs
            currentTab={currentTab}
            switchTab={switchTab}
            disabled={!!matchRoutes}
          />
        </div>
        <h2 className={classes.productName}>{product.name} {product.productNumber} {product.program}</h2>
        <div className={classes.contextMenuWrapper}>
          <ContextMenu menuItems={menuItems} tipsTotalCount={tipsTotalCount}/>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  menuItems: PropsTypes.arrayOf(PropsTypes.shape({
    label: PropsTypes.string,
    path: PropsTypes.string,
  })),
  tipsTotalCount: PropsTypes.number.isRequired,
};

export { Header };
