import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import { People } from '../../components/ProductTabs/People';

import { pdfActions } from '../../redux/pdf/actions';
import { playerActions } from '../../redux/player/actions';
import { productActions } from '../../redux/product/actions';
import { selectPeopleList, selectChapterTimestamps } from '../../redux/chapters/selectors';
import { selectPdfTimestamps } from '../../redux/pdf/selectors';

import { UI_ROUTES } from '../../constants/routes';
import { TABS } from '../../constants/tabs';
import { chaptersActions } from '../../redux/chapters/actions';
import { useParams } from 'react-router-dom';
import { VideoSidebarUtils } from '../../utils/VideoSidebarUtils';

const SummaryPeopleView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const peopleList = useSelector(selectPeopleList);
  const pdfTimestamps = useSelector(selectPdfTimestamps);
  const chapterTimestamps = useSelector(selectChapterTimestamps);

  const onIndexClick = useCallback((index) => () => {
    const newPdf = pdfTimestamps[index];
    const newChapter = VideoSidebarUtils.findNewChapter(chapterTimestamps, newPdf);

    dispatch(productActions.switchTab(TABS.PDF));
    dispatch(pdfActions.changeCurrentPage(index));
    dispatch(pdfActions.setPreviousPdfPage(index));
    dispatch(chaptersActions.changeCurrentChapter(newChapter));
    dispatch(chaptersActions.setPreviousChapter(0));
    dispatch(playerActions.resetCurrentTime());
    history.push(generatePath(UI_ROUTES.videoPlayer, { id }));
  }, [dispatch, history, pdfTimestamps, chapterTimestamps]);

  return (
    <People 
      productId={id}
      peopleList={peopleList}
      pdfTimestamps={pdfTimestamps}
      chapterTimestamps={chapterTimestamps}
      onIndexClick={onIndexClick} 
    />
  );
};

export {
  SummaryPeopleView,
};
