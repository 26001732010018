import {PARTS_SIZES, PARTS_SIZE_LABELS} from "../../constants/parts";

class FormatUtils {
  static formatTimestamp(timestamp) {
    return new Date(timestamp * 1000).toISOString().substr(11, 8);
  }

  static formatTimestampToAudio(timestamp) {
    return new Date(timestamp * 1000).toISOString().substr(15, 4);
  }

  static formatChapter(chapter) {
    return ('0' + chapter).slice(-2);
  }

  static formatChapterDuration(startTime, endTime) {
    const duration = endTime - startTime;
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;

    return `${('0' + minutes).slice(-2)} min ${seconds} sek`;
  }

  static formatListItemsTotalCount(totalCount) {
    return totalCount ? `${totalCount} ${totalCount === 1 ? 'Eintrag' : 'Einträge'}` : '';
  }

  static formatPartsListTotalCount(totalCount, filter) {
    if (!totalCount) {
      return '';
    }
    let label = totalCount === 1 ? 'Eintrag' : 'Einträge';

    if (filter === PARTS_SIZES.SMALL) {
      label = PARTS_SIZE_LABELS[PARTS_SIZES.SMALL];
    }

    if (filter === PARTS_SIZES.BIG) {
      label = PARTS_SIZE_LABELS[PARTS_SIZES.BIG];
    }

    return `${totalCount} ${label}`;
  }

  static formatTime(seconds) {
    return `${Math.floor(seconds / 3600) > 0 ? Math.floor(seconds / 3600) + 'h' : ''} ${Math.floor(seconds % 3600 / 60)} min`;
  }
}

export { FormatUtils };