import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  durationDisplay: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#BEC4D1',
    fontSize: '1rem',
    fontWeight: '500',
  },
}))

export {
  useStyles,
};
