import React from 'react';

const RepeatIcon = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.01503 19.037V20.972C6.01554 21.0673 5.98876 21.1608 5.93787 21.2415C5.88697 21.3221 5.81408 21.3865 5.72778 21.4271C5.64148 21.4677 5.54538 21.4827 5.45081 21.4705C5.35624 21.4582 5.26714 21.4192 5.19403 21.358L1.06603 17.919C0.986157 17.8532 0.928646 17.7643 0.901426 17.6645C0.874206 17.5647 0.878617 17.4589 0.914052 17.3617C0.949487 17.2645 1.0142 17.1807 1.09927 17.1218C1.18434 17.0629 1.28558 17.0319 1.38903 17.033H16.033C16.5635 17.033 17.0722 16.8223 17.4472 16.4472C17.8223 16.0721 18.033 15.5634 18.033 15.033V7.01597H20.033V15.03C20.033 16.0927 19.6109 17.1119 18.8594 17.8633C18.108 18.6148 17.0888 19.037 16.026 19.037H6.01503ZM14.029 3.00397V1.07297C14.0285 0.977607 14.0553 0.884092 14.1062 0.80345C14.1571 0.722808 14.23 0.658402 14.3163 0.617829C14.4026 0.577255 14.4987 0.562205 14.5933 0.574455C14.6878 0.586705 14.7769 0.625744 14.85 0.686966L18.977 4.12597C19.0568 4.19167 19.1143 4.28048 19.1416 4.3802C19.1688 4.47991 19.1645 4.58562 19.1292 4.68277C19.0939 4.77992 19.0293 4.86374 18.9444 4.9227C18.8595 4.98165 18.7584 5.01284 18.655 5.01197H4.01103C3.4806 5.01197 2.97189 5.22268 2.59682 5.59775C2.22175 5.97283 2.01103 6.48153 2.01103 7.01197V15.026H0.00403144V7.01597C0.00337431 6.48934 0.106533 5.96775 0.30761 5.48102C0.508686 4.99429 0.803735 4.55197 1.17589 4.17936C1.54803 3.80674 1.98999 3.51114 2.47646 3.30946C2.96294 3.10778 3.4844 3.00397 4.01103 3.00397H14.029Z" fill="black"/>
    </svg>
  )
}

export {
  RepeatIcon,
};
