import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useStyles } from './styles';
import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../constants/routes';
import { SearchBox } from '../SearchBox';
import { navBarItems } from './assets';
import { NavBar } from '../NavBar';
import { BurgerMenu } from '../BurgerMenu';
import { HeaderButton } from '../Buttons';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { useSelector } from 'react-redux';
import { selectZoomViewState } from '../../redux/pdf/selectors';

const MainHeader = ({ id, companyLogo, onHomeClick, closeZoomView, refreshPlayer }) => {
  const classes = useStyles();
  const isZoomView = useSelector(selectZoomViewState);
  const isVideoPlayerPage = !!useRouteMatch({
    path: UI_ROUTES.videoPlayer,
    exact: true,
  });
  const history = useHistory();

  const menuItems = useMemo(
    () =>
      navBarItems.map(({ label, path }) => ({
        label,
        path: generatePath(path, { id }),
      })),
    [id],
  );

  const isActive = (buttonRoute) => (match, location) => {
    const routes = Object.values(CONTEXT_MENU_ROUTES);
    if (location.pathname === buttonRoute || routes.includes(location.pathname.slice(buttonRoute.length))) {
      return true;
    }
    return false;
  };

  const onHomeButtonClick = useCallback(() => {
    if (isZoomView && closeZoomView) {
      closeZoomView();
    }

    if (isVideoPlayerPage) {
      refreshPlayer();
    } else {
      history.push(generatePath(UI_ROUTES.videoPlayer, { id }));
    }
    onHomeClick();
  }, [history, isVideoPlayerPage, isZoomView, id]);

  return (
    <Grid
      container
      className={classes.root}
      justify={'space-between'}
      alignItems="center"
    >
      <Grid item xs={4} md={4} lg={4} xl={4}>
        <Grid container justify="flex-start" alignItems="center">
          <BurgerMenu id={id}/>
          <HeaderButton icon={HomeRoundedIcon} onClick={onHomeButtonClick}/>
          <div className={classes.logoHolder}>
            <img src={companyLogo} alt="logo"/>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={5} md={5} lg={5} xl={5} classes={{ root: classes.itemRootNavbar }}>
        <Grid container justify="center">
          <NavBar menuItems={menuItems} isActive={isActive}/>
        </Grid>
      </Grid>
      <Grid item xs={3} md={3} lg={3} xl={3} classes={{ root: classes.itemRootSearch }}>
        <Grid container justify="flex-end">
          <SearchBox/>
        </Grid>
      </Grid>
    </Grid>
  );
};

MainHeader.propTypes = {
  id: PropTypes.string.isRequired,
  companyLogo: PropTypes.string.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  closeZoomView: PropTypes.func,
  refreshPlayer: PropTypes.func.isRequired,
};

export { MainHeader };
