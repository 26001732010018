import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';
import { ReactComponent as BasketIcon } from '../../../assets/icons/basket.svg';

const OrderButton = ({ type, itemsCount }) => {
  const classes = useStyles();

  return (
    <IconButton type={type} classes={{ root: classes.button, disabled: classes.disabled }} disabled>
      <SvgIcon component={BasketIcon} viewBox="0 0 22 22" className={classes.svgIcon} />
      <span className={classes.buttonLabel}>Bestellen</span>
      <span className={classes.itemsAmount}> {!!itemsCount && `(${itemsCount})`}</span>
    </IconButton>
  );
};

OrderButton.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

export {
  OrderButton,
};