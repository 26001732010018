import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import SubtitlesIcon from '@material-ui/icons/Subtitles';

import { ButtonsPopover } from '../ButtonsPopover';
import { PlayerButton, SwitchButton } from '../Buttons';
import { TEXT_TRACK_MODES } from '../../constants/player';
import { useStyles } from './styles';
import { POPOVER_NAME } from './assets';

const SubtitlesButton = ({ textTracks, subtitle, open, onChange, onOpen, onClose, disabled, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [switchState, setSwitchState] = useState({});
  const classes = useStyles();

  useEffect(() => {
    const initialState = {};
    textTracks.forEach(track => initialState[track.label] = track.mode === TEXT_TRACK_MODES.showing);
    setSwitchState(initialState);
  }, [textTracks, setSwitchState]);

  const onSwitchChange = (e) => {
    setSwitchState(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(key => newState[key] = key === e.target.name && !prevState[key]);
      onChange(newState);
      return newState;
    });
    onClose();
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    onOpen(POPOVER_NAME);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <PlayerButton icon={SubtitlesIcon} onClick={handleClick} disabled={disabled}/>
      <ButtonsPopover open={open} onClose={handleClose} anchorEl={anchorEl} subtitle={subtitle} {...rest}>
          <FormGroup>
            <p className={classes.title}>Untertitel</p>
            {textTracks.map(track => (
              <FormControlLabel 
                control={<SwitchButton checked={switchState[track.label]} onChange={onSwitchChange} name={track.label} />}
                classes={{
                  root: classes.root,
                  labelPlacementStart: classes.labelPlacementStart,
                }}
                label={track.label}
                labelPlacement='start'
                key={track.label}
              />
            ))}
          </FormGroup>
      </ButtonsPopover>
    </>
  )
}

SubtitlesButton.propTypes = {
  textTracks: PropTypes.array.isRequired,
  subtitle: PropTypes.bool,
  disabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export {
  SubtitlesButton,
};
