class VideoSidebarUtils {
  static findNewPdfPage(allPdfs, currentChapter, prevPdfIndex) {
    if (prevPdfIndex !== null && allPdfs[prevPdfIndex].endTime > currentChapter.startTime && allPdfs[prevPdfIndex].endTime <= currentChapter.endTime) {
      return prevPdfIndex;
    }
    return allPdfs.findIndex(pdf => currentChapter.startTime < pdf.endTime && currentChapter.endTime >= pdf.endTime);
  }

  static findNewChapter(allChapters, currentPdf) {
    return allChapters.findIndex(chapter => currentPdf.endTime > chapter.startTime && currentPdf.endTime <= chapter.endTime);
  }

  static findFirstPdfInChapter(allPdfs, currentChapter) {
    const newPdfIndex = allPdfs.findIndex(pdf => currentChapter.startTime < pdf.endTime && currentChapter.endTime >= pdf.endTime);

    if (newPdfIndex === -1) {
      return allPdfs.length - 1;
    }

    return newPdfIndex;
  }
}

export {
  VideoSidebarUtils,
};
