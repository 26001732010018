import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const HeaderButton = ({ icon: Icon, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <IconButton 
      data-testid='header-button' 
      onClick={onClick} 
      classes={{ root: classes.iconButton }}
      disableRipple
      disableFocusRipple 
      {...rest}
    >
      <Icon classes={{ root: classes.svgIcon }} />
    </IconButton>
  )
}

HeaderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
};

export {
  HeaderButton,
};
