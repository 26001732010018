import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectVolume } from '../../redux/player/selectors';

import { playerActions } from '../../redux/player/actions';
import { TipsAudioPlayer } from '../../components/TipsAudioPlayer';

const TipsAudioView = ({ audio }) => {
  const dispatch = useDispatch();
  const storedVolume = useSelector(selectVolume);

  const onPlayerDispose = useCallback(
    (volumeLevel) => {
      dispatch(playerActions.setVolume(volumeLevel ?? 1));
    },
    [dispatch],
  );

  const props = {
    onPlayerDispose,
    storedVolume,
    audio, 
  }

  return (
    <TipsAudioPlayer {...props}/>
  );
};

TipsAudioView.propTypes = {
  audio: PropTypes.string.isRequired,
};

export {
  TipsAudioView,
};
