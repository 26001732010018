import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    background: '#fff',
    maxWidth: '75px',
    height: '35px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '5px',
    [theme.breakpoints.up('extraLg')]: {
      borderRadius: '10px',
      height: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '85px',
      height: '50px',
    },
  },
  button: {
    height: '100%',
    background: 'none',
    color: theme.colors.primary,
    padding: 0,
    borderRadius: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& svg, & svg path': {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.up('lg')]: {
      '& svg': {
        fontSize: '1.7rem',
      },
    },
  },
  input: {
    border: 'none',
    maxWidth: '20px',
    background: 'none',
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.3rem',
      maxWidth: '25px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '30px',
    },
  },
  icon: {
    width: '25px',
    height: '25px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '5px',
    background: '#fff',
    [theme.breakpoints.up('extraLg')]: {
      width: '36px',
      height: '36px',
      borderRadius: '10px',
    },
  },
  checkedIcon: {
    padding: '0 3px',
    color: '#fff',
    background: theme.colors.primary,
    border: '1px solid transparent',
  },
}));

export {
  useStyles,
};
