import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { PDFZoomSideBySide } from '../PDFZoomSideBySide';
import { PDFZoomFullscreen } from '../PDFZoomFullscreen';

import { useStyles } from './styles';

import { pdfActions } from '../../redux/pdf/actions';

const PDFZoom = ({
  currentPdfIndex,
  changeCurrentPage,
  pdfsLength,
  allPdfs,
  isFullscreenZoom,
  setFullscreenZoom,
}) => {

  const dispatch = useDispatch();
  const classes = useStyles({ fullWidth: isFullscreenZoom });

  const onExitClick = useCallback(() => {
    dispatch(pdfActions.toggleZoomViewState(false));
  }, [dispatch, isFullscreenZoom]);

  const handleZoomIn = useCallback((pageIndex) => {
    changeCurrentPage(pageIndex);
    setFullscreenZoom(true);
  }, []);

  const handleZoomOut = useCallback(() => {
    setFullscreenZoom(false);
  }, []);

  const props = {
    currentPdfIndex,
    changeCurrentPage,
    pdfsLength,
    allPdfs,
  };

  return (
    <Grid className={classes.root} container justify="space-between" alignItems="center" wrap="nowrap">
      {isFullscreenZoom
        ? <PDFZoomFullscreen{...props} handleZoomOut={handleZoomOut} />
        : <PDFZoomSideBySide {...props} handleZoomIn={handleZoomIn} onExitClick={onExitClick} />
      }
    </Grid>
  );
};

PDFZoom.propTypes = {
  currentPdfIndex: PropTypes.number.isRequired,
  pdfsLength: PropTypes.number.isRequired,
  allPdfs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.number,
    source: PropTypes.string,
  })).isRequired,
  isFullscreenZoom: PropTypes.bool.isRequired,
  setFullscreenZoom: PropTypes.func.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
};

export {
  PDFZoom,
};