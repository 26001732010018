import { partsActions } from './actions';

export const initState = {
  list: [],
  page: 1,
  limit: 10,
  totalItems: 0,
  allItems: 0,
  bigParts: 0,
  smallParts: 0,
  loading: false,
};

export default function partsReducer(state = initState, { type, payload }) {
  switch (type) {
    case partsActions.LIST_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case partsActions.LIST_REFRESH: {
      const { page, ...meta } = payload.meta;
      return {
        ...state,
        page: payload.listData.page,
        limit: payload.listData.limit,
        list: payload.listData.items,
        totalItems: page.totalItems,
        ...meta,
        loading: false,
      };
    }
    case partsActions.LIST_UPDATE: {
      return {
        ...state,
        page: payload.listData.page,
        limit: payload.listData.limit,
        list: [...state.list, ...payload.listData.items],
        totalItems: payload.meta.page.totalItems,
      };
    }
    case partsActions.LIST_LOAD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
