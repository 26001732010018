import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import { Loading } from '../../Loading';

import { useStyles } from './styles';

const ProductTabContent = ({ title, controls, children, loading }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" classes={{ root: classes.container }}>
      <Grid container justify="space-between" alignItems="center" classes={{ root: classes.header }}>
        {title}
        <Grid container justify="flex-end" wrap="nowrap" classes={{ root: classes.controlsContainer }}>
          {controls}
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Loading loading={loading}>
          {children}
        </Loading>
      </div>
    </Grid>
  );
};

ProductTabContent.propTypes = {
  title: PropTypes.node.isRequired,
  controls: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  loading: PropTypes.bool,
};

ProductTabContent.defaultProps = {
  loading: false,
};

export {
  ProductTabContent,
};