import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

import { SvgPlayerButton as IconButton, RoundButton, BackButton } from '../Buttons';

import { ReactComponent as ZoomOutIcon } from '../../assets/icons/zoom-out.svg';

import { useStyles } from '../PDFZoom/styles';
import { useFullscreenStyles } from './styles';
const PDFZoomFullscreen = ({
  currentPdfIndex,
  changeCurrentPage,
  pdfsLength,
  allPdfs,
  handleZoomOut,
}) => {
  const sharedClasses = useStyles();
  const classes = useFullscreenStyles();

  const currentPage = allPdfs[currentPdfIndex];

  return (
    <>
      <Grid className={classes.pdfFullscreen} container justify="space-between" wrap="nowrap">
        <RoundButton
          icon={ChevronLeftIcon}
          disabled={currentPdfIndex <= 0}
          onClick={() => changeCurrentPage(currentPdfIndex - 1)}
        />
        <Grid className={classes.pageWrapper} container justify="center" alignItems="center" wrap="nowrap">
          <div className={sharedClasses.pageNumber}>{currentPdfIndex + 1}</div>
          <div className={classes.imgHolder}>
            <img src={currentPage.source} alt={currentPage.name} />
          </div>
          <IconButton
            buttonClasses={{ root: clsx(classes.zoomInButton, sharedClasses.zoomInButton) }}
            onClick={handleZoomOut}
            icon={ZoomOutIcon} viewBox="0 0 26 26"
          />
        </Grid>
        <RoundButton
          icon={ChevronRightIcon}
          disabled={currentPdfIndex + 1 >= pdfsLength}
          onClick={() => changeCurrentPage(currentPdfIndex + 1)}
        />
      </Grid>
      <BackButton position={sharedClasses.exitButton} onClick={handleZoomOut} />
    </>
  );
};

PDFZoomFullscreen.propTypes = {
  currentPdfIndex: PropTypes.number.isRequired,
  pdfsLength: PropTypes.number.isRequired,
  allPdfs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.number,
    source: PropTypes.string,
  })).isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
};

export {
  PDFZoomFullscreen,
};