import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100px',
    padding: '0 20px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 40px',
    },
    background: '#fff',
    ['@media (pointer: coarse) and (max-height: 900px)']: {
      minHeight: '80px',
    },
    ['@media (pointer: fine) and (min-aspect-ratio: 19/9)']: {
      minHeight: '80px',
    },
    ['@media (pointer: fine) and (max-height: 700px)']: {
      minHeight: '80px',
    },
  },
  itemRootNavbar: {
    flexGrow: 1,
    maxWidth: 'none',
  },
  itemRootSearch: {
    flexShrink: 1,
  },
  logoHolder: {
    height: '33px',
    '& img': {
      width: 'auto',
      height: '100%',
    }
  }
}));

export {
    useStyles,
};
