import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

const SelectTextButton = ({ value, selected, text, setSelectValue }) => {
  const classes = useStyles();

  return (
    <Button
      disableRipple
      classes={{ root: clsx(classes.selectButton, { [classes.selected]: selected }), label: classes.label }}
      value={value}
      onClick={() => setSelectValue(value)}
    >
      {text}
    </Button>
  );
};

SelectTextButton.propTypes = {
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  setSelectValue: PropTypes.func.isRequired,
};

export {
  SelectTextButton,
};
