import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    width: 'auto',
    background: props => props.background ? props.background : theme.colors.background.chapter,
    borderRadius: '10px',
    overflow: 'hidden',
  },
}));

export {
  useStyles,
};