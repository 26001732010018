import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    borderRadius: '20px',
    padding: '0 10px',
    fontWeight: 500,
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '20px',
    },
    [theme.breakpoints.only('md')]: {
      height: '25px',
    },
    [theme.breakpoints.up('extraXl')]: {
      height: '40px',
    },
  }, 
  label: {
    margin: 0,
  },
  current: {
    color: theme.colors.thirty,
  },
  divider: {
    fontSize: '1.3rem',
  },
}));

export { useStyles };
