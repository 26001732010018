const PAGE_TYPES = {
  CURRENT_PAGE: 'CURRENT_PAGE',
  THUMBNAIL_PAGE: 'THUMBNAIL_PAGE',
};

const THUMBNAILS_NUMBER = 3;

export {
  PAGE_TYPES,
  THUMBNAILS_NUMBER,
};