import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  iconButton: {
    width: '30px',
    height: '30px',
    fontSize: '20px',
    padding: 0,
    borderRadius: '12px',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    background: '#ffffff',
    [theme.breakpoints.up('extraLg')]: {
      width: '40px',
      height: '40px',
      fontSize: '30px',
    }
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  svgIcon: {
    fontSize: '1em',
  },
}));

export {
  useStyles,
};