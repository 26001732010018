import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { Fade, Popper } from '@material-ui/core';
import { WINDOW_EVENTS } from '../../constants/player';

const ButtonsPopover = ({children, open, onClose, anchorEl, centered, subtitle, right, audioPlayer, ...props}) => {

  const classes = useStyles({centered, subtitle, right, audioPlayer});
  let placement = 'top-start';

  if (centered) {
    placement = 'top';
  }

  if (right) {
    placement = 'top-end';
  }

  useEffect(() => {
    if(open) {
      window.addEventListener(WINDOW_EVENTS.click, onClose);
    } else {
      window.removeEventListener(WINDOW_EVENTS.click, onClose);
    }
    return () => {
      window.removeEventListener(WINDOW_EVENTS.click, onClose);
    }
  }, [open, onClose])

  return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            style={{zIndex: 1300}}
            disablePortal
            transition
            data-testid='buttons-popup'
            {...props}
          >
            <Fade in={open}>
              <div className={classes.root} onClick={e => e.stopPropagation()}>
                <div className={classes.wrapper}>{children}</div>
                <div className={classes.tail}></div>
              </div>
            </Fade>
        </Popper>
  )
}

ButtonsPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  centered: PropTypes.bool,
  right: PropTypes.bool,
  audioPlayer: PropTypes.bool,
  subtitle: PropTypes.bool,
}

export {
  ButtonsPopover,
};
