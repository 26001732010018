import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'

import { ListCurrentInfoView } from '../../ListCurrentInfo';
import { CloseTabButton } from '../CloseTabButton';
import { ProductTabContent } from '../ProductTabContent';
import { SectionTitle } from '../../SectionTitle';
import { Select } from '../../Select';
import { ConstructionTimeGlobalInfo } from '../../ConstructionTimeGlobalInfo';

import { listColumns, profiLevelsButtons } from './assets';

import { PROFI_LEVELS_VIEW_MODE, PROGRESS_DETAILS_LABEL, PROGRESS_INFO_LABEL } from '../../../constants/constructionTime';
import { GENERAL_INFO_LABEL, PRODUCT_LABEL } from '../../../constants/constructionTime';
import { TABS } from '../../../constants/tabs';

import { useStyles } from './styles';
import { FormatUtils } from '../../../utils/FormatUtils';
import { ConstructionTimeUtils } from '../../../utils/ConstructionTimeUtils';

const ConstructionTime = ({
  currentPdf,
  currentChapter,
  chapterConstructionTimeList,
  pdfConstructionTimeList,
  currentTab,
  currentChapterNumber,
  currentPageNumber,
  chapterTimestamps,
  pdfTimestamps,
  productId,
  onChapterChange,
  onRestoreData,
  onPdfChange,
}) => {
  const [viewMode, setViewMode] = useState(PROFI_LEVELS_VIEW_MODE.BEGINNER);
  const savedData = useRef({});
  const classes = useStyles();

  useEffect(() => {
    const newChapter = chapterTimestamps.findIndex( chapter => pdfTimestamps[currentPageNumber].startTime >= chapter.startTime && pdfTimestamps[currentPageNumber].endTime <= chapter.endTime );
    const newPdf = pdfTimestamps.findIndex( pdf => pdf.startTime >= chapterTimestamps[currentChapterNumber].startTime && pdf.endTime <= chapterTimestamps[currentChapterNumber].endTime);

    switch (currentTab) {
      case TABS.PDF:
        if ( ~newChapter && newChapter != currentChapterNumber) {
          onChapterChange(newChapter);

        }
      break;
      case TABS.VIDEO_KAPITEL:
        if ( ~newPdf && newPdf != currentPageNumber) {
          onPdfChange(newPdf);
        }
      break;
    }

  }, [currentPageNumber, chapterTimestamps, pdfTimestamps, currentChapterNumber, currentTab, onChapterChange, onPdfChange]);

  useEffect(() => {

    if (currentChapterNumber !== undefined && savedData.current.chapterNumber === undefined) {
      savedData.current.chapterNumber = currentChapterNumber
    }

    if (currentPageNumber !== undefined && savedData.current.pageNumber === undefined) {
      savedData.current.pageNumber = currentPageNumber
    }

  }, [currentChapterNumber, currentPageNumber, savedData]);

  useEffect(() => {
    return () => {
      onRestoreData(savedData.current.chapterNumber, savedData.current.pageNumber);
    }
  }, []);

  const generalInfo = useMemo(() => {
    if(chapterConstructionTimeList.length > 0 && pdfConstructionTimeList.length > 0) {
      let result = [
        ConstructionTimeUtils.createGeneralInfoObject(
          PRODUCT_LABEL,
          GENERAL_INFO_LABEL.productLabel,
          FormatUtils.formatTime(ConstructionTimeUtils.calculateDuration(chapterConstructionTimeList, viewMode)),
        )
      ];
      if(currentTab === TABS.PDF) {
        result.push(
          ConstructionTimeUtils.createGeneralInfoObject(
            currentChapter?.name,
            GENERAL_INFO_LABEL.chapterLabel,
            FormatUtils.formatTime(ConstructionTimeUtils.getDuration(chapterConstructionTimeList, currentChapterNumber, viewMode)),
          ),
          ConstructionTimeUtils.createGeneralInfoObject(
            `Seite ${currentPageNumber + 1}`,
            GENERAL_INFO_LABEL.pdfLabel,
            FormatUtils.formatTime(ConstructionTimeUtils.getDuration(pdfConstructionTimeList, currentPageNumber, viewMode)),
          )
        )
      } else {
        result.push(
          ConstructionTimeUtils.createGeneralInfoObject(
            currentChapter?.name,
            GENERAL_INFO_LABEL.chapterLabel,
            FormatUtils.formatTime(ConstructionTimeUtils.getDuration(chapterConstructionTimeList, currentChapterNumber, viewMode)),
          ),
        )
      }
      return result;
    }
  }, [currentTab, viewMode, chapterConstructionTimeList, pdfConstructionTimeList, currentPageNumber, currentChapterNumber, currentChapter, currentPdf]);

  const progressDetails = useMemo(() => {
    if(chapterConstructionTimeList.length > 0 && pdfConstructionTimeList.length > 0) {
      if(currentTab === TABS.PDF) {
        const newCurrentChapterNumber = chapterTimestamps.findIndex(chapter => pdfTimestamps[currentPageNumber].startTime >= chapter.startTime && pdfTimestamps[currentPageNumber].endTime <= chapter.endTime);
        const newCurrentChapter = chapterTimestamps[newCurrentChapterNumber];
        return (
          [
            ConstructionTimeUtils.createProgressDetailsObject(
              PROGRESS_DETAILS_LABEL.productLabel,
              ConstructionTimeUtils.calculateProductProgress(pdfConstructionTimeList, currentPageNumber, viewMode),
              FormatUtils.formatTime(ConstructionTimeUtils.calculateProductPassedTime(pdfConstructionTimeList, currentPageNumber, viewMode)),
              FormatUtils.formatTime(ConstructionTimeUtils.calculateProductRestTime(pdfConstructionTimeList, currentPageNumber, viewMode, chapterConstructionTimeList)),
            ),
            ConstructionTimeUtils.createProgressDetailsObject(
              PROGRESS_DETAILS_LABEL.chapterLabel,
              ConstructionTimeUtils.calculateChapterProgress(pdfConstructionTimeList, newCurrentChapter, pdfTimestamps, currentPageNumber, viewMode, chapterConstructionTimeList, newCurrentChapterNumber),
              FormatUtils.formatTime(ConstructionTimeUtils.calculateChapterPassedTime(pdfConstructionTimeList, newCurrentChapter, pdfTimestamps, currentPageNumber, viewMode)),
              FormatUtils.formatTime(ConstructionTimeUtils.calculateChapterRestTime(pdfConstructionTimeList, newCurrentChapter, pdfTimestamps, currentPageNumber, viewMode)),
            ),
            ConstructionTimeUtils.createProgressDetailsObject(
              PROGRESS_DETAILS_LABEL.pdfLabel,
              PROGRESS_INFO_LABEL.pdfProgressLabel,
              null,
              FormatUtils.formatTime(ConstructionTimeUtils.getDuration(pdfConstructionTimeList, currentPageNumber, viewMode)),
            ),
          ]
        )
      } else {
        return (
          [
            ConstructionTimeUtils.createProgressDetailsObject(
              PROGRESS_DETAILS_LABEL.productLabel,
              ConstructionTimeUtils.calculateProductProgress(chapterConstructionTimeList, currentChapterNumber, viewMode),
              FormatUtils.formatTime(ConstructionTimeUtils.calculateProductPassedTime(chapterConstructionTimeList, currentChapterNumber, viewMode)),
              FormatUtils.formatTime(ConstructionTimeUtils.calculateProductRestTime(chapterConstructionTimeList, currentChapterNumber, viewMode, chapterConstructionTimeList)),
            ),
            ConstructionTimeUtils.createProgressDetailsObject(
              PROGRESS_DETAILS_LABEL.chapterLabel,
              PROGRESS_INFO_LABEL.chapterProgressLabel,
              null,
              FormatUtils.formatTime(ConstructionTimeUtils.getDuration(chapterConstructionTimeList, currentChapterNumber, viewMode)),
            ),
          ]
        )
      }
    }
    return [];
  }, [currentTab, viewMode, chapterConstructionTimeList, pdfConstructionTimeList, currentPageNumber, currentChapterNumber, currentChapter, currentPdf]);

  const title = <SectionTitle title="Aufbauzeit"/>;
  const controls = (
    <>
      <Select items={profiLevelsButtons} selectedValue={viewMode} setSelectValue={setViewMode} background='#FFF'/>
      <CloseTabButton productId={productId}/>
    </>
  );

  return (
    <ProductTabContent title={title} controls={controls}>
      <div className={classes.mainContent}>
        <Grid container classes={{root: classes.topBar}}>
          <div className={classes.imgContainer}>
              <img src={currentChapter?.thumbnail} alt='Chapter thumbnail' className={classes.img}/>
          </div>
          <ConstructionTimeGlobalInfo globalInfo={generalInfo}/>
        </Grid>
        <ListCurrentInfoView columns={listColumns} items={progressDetails}/>
      </div>
    </ProductTabContent>
  )
}

ConstructionTime.propTypes = {
  currentPageNumber: PropTypes.number.isRequired,
  currentChapterNumber: PropTypes.number.isRequired,
  pdfConstructionTimeList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    time: PropTypes.string,
    duration: PropTypes.number.isRequired,
  }))),
  chapterConstructionTimeList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    time: PropTypes.string,
    duration: PropTypes.number.isRequired,
  }))),
  currentTab: PropTypes.string.isRequired,
  currentPdf: PropTypes.object.isRequired,
  currentChapter: PropTypes.object.isRequired,
  chapterTimestamps: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
  })),
  pdfTimestamps: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
  })),
  onChapterChange: PropTypes.func.isRequired,
  onRestoreData: PropTypes.func.isRequired,
  onPdfChange: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
}

export {
  ConstructionTime,
};
