import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';


import { useCellStyles as useSharedCellStyles } from '../ListView/styles';
import { useCellStyles } from './styles';

const ListCell = ({ children, width, maxWidth, align, listCellClassName }) => {
  const sharedClasses = useSharedCellStyles({ width, maxWidth, align });
  const classes = useCellStyles();

  return <Grid item classes={{ root: clsx(sharedClasses.listCell, classes.listCell, listCellClassName) }}>{children}</Grid>;
};

ListCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  listCellClassName: PropTypes.string,
  align: PropTypes.oneOf(['center', 'flex-end']),
};

export {
  ListCell,
};