import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chapterNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '35px',
    height: '35px',
    color: theme.colors.text.quaternary,
    background: theme.colors.background.tableBullet,
    borderRadius: '50%',
    fontSize: '1rem',
    fontWeight: 500,
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      minWidth: '40px',
      height: '40px',
    },
  },
  blue: {
    background: theme.colors.background.quaternary,
    color: '#fff',
    '&$selected':{
        color: '#fff',
        fontWeight: 600,
        background: 'rgba(255, 255, 255, .2)',
        opacity: 1,
    }
  },
  mini: {
    minWidth: '20px',
    height: '20px',
    [theme.breakpoints.up('lg')]: {
      minWidth: '25px',
      height: '25px',
    },
    [theme.breakpoints.up('extraLg')]: {
      minWidth: '35px',
      height: '35px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '40px',
      height: '40px',
    },
  },
  selected: {
    color: '#000',
    fontWeight: 600,
    background: 'rgba(255, 255, 255, .7)',
    opacity: 1,
  },
  disabled: {
    opacity: 0.5,
  },
}));

export {
  useStyles,
};
