const voiceOverLabels = {
  male: 'Männlich',
  female: 'Weiblich',
};

const voiceOverLanguage = {
  en: 'en',
  de: 'de',
  fr: 'fr',
};

const voiceOverMenuItems = [
  {langCode: voiceOverLanguage.en, gendersLabel: [voiceOverLabels.male, voiceOverLabels.female]},
  {langCode: voiceOverLanguage.de, gendersLabel: [voiceOverLabels.male, voiceOverLabels.female]},
  {langCode: voiceOverLanguage.fr, gendersLabel: [voiceOverLabels.male, voiceOverLabels.female]}
];

const POPOVER_NAME = 'voiceOver';

const setInitState = () => {
  const initState = {};

    voiceOverMenuItems.map(({langCode, gendersLabel}) => {
      initState[langCode] = {};
        
      gendersLabel.map((gender) => {
        initState[langCode][gender] = false;
      }); 
      })

  return initState;
};

export {
  voiceOverLabels,
  voiceOverMenuItems,
  POPOVER_NAME,
  setInitState,
};
