import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, matchPath } from 'react-router-dom';
import { UI_ROUTES } from '../../constants/routes';

import { AssemblyVideosView } from '../../containers/AssemblyVideosView';
import { PageContainer } from '../../components/PageContainer';
import { playerActions } from '../../redux/player/actions';

import { productActions } from '../../redux/product/actions';
import { selectCurrentTab } from '../../redux/product/selectors';

const AssemblyVideos = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentTab = useSelector(selectCurrentTab);

  useEffect(() => {
    dispatch(productActions.setPreviousTab(currentTab));
  }, []);

  useEffect(() => {
    const unblock = history.block((location) => {

      const match = matchPath(location.pathname, {
        path: UI_ROUTES.videoPlayer,
        exact: true,
        strict: true,
      });

      if (match && match.params.id !== id) {
        dispatch(playerActions.resetPlayerState());
      }
    });

    return () => {
      unblock();
    };
  }, []);

  return <PageContainer>
    <AssemblyVideosView/>
  </PageContainer>;
};

export {
  AssemblyVideos,
};