import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { chaptersActions } from '../../redux/chapters/actions';
import {
  selectChapterLoadingState,
  selectCurrentChapter,
} from '../../redux/chapters/selectors';
import { pdfActions } from '../../redux/pdf/actions';
import { selectCurrentPage, selectPdfTimestampsLoadingState } from '../../redux/pdf/selectors';
import { selectCurrentTab } from '../../redux/product/selectors';
import { tipsActions } from '../../redux/tips/actions';
import { TABS } from '../../constants/tabs';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../constants/routes';
import { Loading } from '../../components/Loading';

const Player = ({ children }) => {
  const [isVideoDataLoading, setVideoDataLoading] = useState(true);
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const chaptersLoading = useSelector(selectChapterLoadingState);
  const pdfTimestampsLoading = useSelector(selectPdfTimestampsLoadingState);
  const currentTab = useSelector(selectCurrentTab);
  const currentPdf = useSelector(selectCurrentPage);
  const currentChapter = useSelector(selectCurrentChapter);
  const { id } = useParams();
  const { pathname } = useLocation();
  const matchTipsRoutes = matchPath(pathname,
    {
      path: `${UI_ROUTES.videoPlayer}${CONTEXT_MENU_ROUTES.tips}`,
      exact: true,
    });
  const loadTips = !isVideoDataLoading && !matchTipsRoutes;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setVideoDataLoading(chaptersLoading || pdfTimestampsLoading);
  }, [chaptersLoading, pdfTimestampsLoading]);

  useEffect(() => {
      dispatch(chaptersActions.reloadChapters(id, { withConstructionTime: true }));
      dispatch(pdfActions.reloadPdfTimestamps(id, { withConstructionTime: true }));
  }, [id]);

  useEffect(() => {
    if (loadTips && currentTab === TABS.PDF) {
      dispatch(tipsActions.listTopicsLoad(id, { filters: { pdf: currentPdf.id } }));
    }
  }, [loadTips, currentTab, currentPdf]);

  useEffect(() => {
    if (loadTips && currentTab === TABS.VIDEO_KAPITEL) {
      dispatch(tipsActions.listTopicsLoad(id, { filters: { chapter: currentChapter.id } }));
    }
  }, [loadTips, currentTab, currentChapter]);


  return <Loading loading={isVideoDataLoading}>{children}</Loading>;
};

Player.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export {
  Player,
};