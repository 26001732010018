import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { generatePath, NavLink, useHistory, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { Icon } from '../Icon';

import { summaryMenuItems, scrollOptions } from './assets';
import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../constants/routes';

import { useStyles } from './styles';
import { KEYS_CODE, WINDOW_EVENTS } from '../../constants/player';

const SummaryMenu = ({ productId }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const selectedTab = useRef(null);

  const menuItems = useMemo(() =>
    summaryMenuItems.map(({ path, ...item }) => ({
        ...item,
        path: generatePath(`${UI_ROUTES.summary}${path}`, { id: productId }),
      }
    )), [productId]);

  useEffect(() => {
    const selectedTabElement = selectedTab?.current;
    if (selectedTabElement) {
      selectedTabElement.scrollIntoView(scrollOptions);
    }
  }, [selectedTab, pathname]);

  const onLinkClick = disabled => (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  const onKeyDown = useCallback((e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      let href;
      switch (e.code) {
        case KEYS_CODE.arrowUp:
          if(selectedTab?.current?.parentElement?.previousElementSibling) {
            href = new URL(selectedTab.current.parentElement.previousElementSibling.children[0]).pathname;
          }
          break;
        case KEYS_CODE.arrowDown:
          if(selectedTab?.current?.parentElement?.nextElementSibling) {
            href = new URL(selectedTab.current.parentElement.nextElementSibling.children[0]).pathname;
          }
          break;
      }
      const updatePage = href === generatePath(`${UI_ROUTES.summary}${CONTEXT_MENU_ROUTES.updates}`, { id: productId });
      if(href && !updatePage) history.push(href)
    }
  }, [selectedTab, pathname, productId]);

  useEffect(() => {
    window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    return () => {
      window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
  }, [onKeyDown]);

  return (
    <div className={classes.menuWrapper}>
      <nav>
        <List classes={{ root: classes.list }}>
          {menuItems.map(({ label, path, icon: IconComponent, viewBox, disabled }, index) => (
            <ListItem
              key={path}
              classes={{ root: clsx(classes.listItem, { [classes.disabled]: disabled }) }}
            >
              <NavLink
                to={path}
                exact
                className={classes.listItemLink}
                activeClassName={classes.listItemLinkActive}
                onClick={onLinkClick(disabled)}
                ref={pathname === path ? selectedTab : null}
              >
                <div className={classes.listItemNumber}>{index + 1}</div>
                <ListItemIcon classes={{ root: classes.iconWrapper }}>
                  <Icon component={IconComponent} viewBox={viewBox}/>
                </ListItemIcon>
                {label}
              </NavLink>
            </ListItem>
          ))}
        </List>
      </nav>
    </div>
  );
};

SummaryMenu.propTypes = {
  productId: PropTypes.string.isRequired,
};

export {
  SummaryMenu,
};