import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '-15px',
    left: '0',
    right: '0',
    height: '5px',
    ['@media (pointer: coarse)']: {
      padding: '13px 0',
    },
  },
  thumb: {
    zIndex: 4,
    marginTop: '-6px',
    width: '17px',
    height: '17px',
    border: props => `4px solid ${props.audioSlider ? '#8185FC' : '#FFF'}`,
    background: props => props.audioSlider ? '#FFF' : theme.colors.thirty,
  },
  track: {
    zIndex: 3,
    height: '5px',
    background: props => props.audioSlider ? '#8185FC' : theme.colors.thirty,
    borderRadius: props => props.audioSlider ? '5px 0 0 5px' : 'none',
  },
  rail: {
    zIndex: 1,
    height: '5px',
    background: props => props.audioSlider ? '#C9D1E2' : 'rgb(255, 255, 255)',
    borderRadius: props => props.audioSlider ? '5px' : 'none',
    opacity: 1,
  },
  buffered: {
    position: 'absolute',
    top: '-2px',
    left: '0',
    zIndex: 2,
    height: '5px',
    width: props => `${(props.buffered * 100) / props.duration}%`,
    background: '#e7eaf0',
    borderRadius: '0 5px 5px 0'
  }
}));

export {
    useStyles,
};
