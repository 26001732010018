import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider'
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

import { ButtonsPopover } from '../ButtonsPopover';
import { ToggleButton } from '../Buttons';
import { useStyles } from './styles';
import { KEYS_CODE, WINDOW_EVENTS } from '../../constants/player';
import { POPOVER_NAME } from './assets';

const VolumeButton = ({volume, open, audioPlayer, onOpen, onClose, onChange, ...rest}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({open, audioPlayer});

  const muted = useMemo(() => volume <= 0, [volume]);

  const onKeyDown = useCallback((e) => {
    if (!e.ctrlKey && !e.metaKey && open) {
      e.preventDefault();
      switch (e.code) {
        case KEYS_CODE.arrowUp:
          onChange(null, volume + 0.05 >= 1 ? 1 : volume + 0.05);
          break;
        case KEYS_CODE.arrowDown:
          onChange(null, volume - 0.05 <= 0 ? 0 : volume - 0.05);
          break;
      }
    }
  }, [volume, onChange, open])

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    onOpen(POPOVER_NAME);
  };

  const handleClose = () => {
    onClose()
  };

  useEffect(() => {
    window.addEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    return () => {
      window.removeEventListener(WINDOW_EVENTS.keydown, onKeyDown);
    }
  }, [onKeyDown])

  return (
    <>
      <ToggleButton
        data-testid='volume-button'
        iconOnTrue={VolumeOffIcon} 
        iconOnFalse={VolumeUpIcon} 
        flag={muted} 
        onClick={handleClick}
        svgClass={classes.svgIcon}
      />
      <ButtonsPopover open={open} onClose={handleClose} anchorEl={anchorEl} centered {...rest} audioPlayer={audioPlayer} data-testid='volume-popup'>
        <Slider
          data-testid='volume-slider'
          step={0.01}
          min={0}
          max={1}
          value={volume}
          onChange={onChange}
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            track: classes.track,
            rail: classes.rail,
          }}
        />
      </ButtonsPopover>
    </>
  )
}

VolumeButton.propTypes = {
  volume: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  audioPlayer: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,  
}

export {
  VolumeButton,
};
