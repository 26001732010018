import { sparePartsActions } from './actions';
import { PARTS_SIZES } from '../../constants/parts';

export const initState = {
  values: {
    [PARTS_SIZES.SMALL]: [],
    [PARTS_SIZES.BIG]: [],
  },
  bigParts: 0,
  smallParts: 0,
  loading: false,
};

export default function sparePartsReducer(state = initState, { type, payload }) {
  switch (type) {
    case sparePartsActions.LIST_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case sparePartsActions.LIST_REFRESH: {
      return {
        ...state,
        values: {
          ...payload.values,
        },
        smallParts: payload.smallParts,
        bigParts: payload.bigParts,
        loading: false,
      };
    }
    case sparePartsActions.LIST_LOAD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
