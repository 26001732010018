import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SliderControl from '@material-ui/core/Slider';
import { useSelector } from 'react-redux';

import { selectSidebarState } from '../../../redux/product/selectors';

import { useStyles } from './style';

const Slider = ({ currentPdfIndex, pdfsLength, isSliderActive, changeCurrentPage, setSliderState }) => {
  const isSidebarDisabled = useSelector(selectSidebarState);
  const classes = useStyles({ isSidebarDisabled });

  const handleSliderChange = (event, newPage) => {
    if (currentPdfIndex !== newPage) {
      if (!isSliderActive) {
        setSliderState(true);
      }

      changeCurrentPage(newPage);
    }
  };

  const handleSliderChangeCommitted = () => {
    setSliderState(false);
  };

  const onRangeValueClick = useCallback((page) => (e) => {
    if (isSidebarDisabled) {
      e.preventDefault();
      return;
    }

    changeCurrentPage(page);
  }, [changeCurrentPage, isSidebarDisabled]);

  return (
    <div className={classes.sliderWrapper}>
      <span className={classes.sliderRangeValue} onClick={onRangeValueClick(0)}>1</span>
      <SliderControl
        classes={{
          root: classes.slider,
          rail: classes.sliderRail,
          track: classes.sliderTrack,
          thumb: classes.sliderThumb,
          disabled: classes.sliderDisabled,
        }}
        min={0}
        value={currentPdfIndex}
        max={pdfsLength - 1}
        disabled={isSidebarDisabled}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
      />
      <span className={classes.sliderRangeValue} onClick={onRangeValueClick(pdfsLength - 1)}>{pdfsLength}</span>
    </div>
  );
};

Slider.propTypes = {
  currentPdfIndex: PropTypes.number.isRequired,
  pdfsLength: PropTypes.number.isRequired,
  isSliderActive: PropTypes.bool.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  setSliderState: PropTypes.func.isRequired,
};

export {
  Slider,
};