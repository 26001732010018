import React from 'react';


import { ListImage } from '../ListImage';

const listColumns = [
  {
    field: 'thumbnail',
    label: 'Bild',
    width: '11%',
    // eslint-disable-next-line react/display-name
    valueGetter: (value) => <ListImage imgSrc={value} alt={'part name'}/>,
  },
  { field: 'partNumber', label: 'Bezeichnung', width: '12%' },
  { field: 'name', label: 'Name', width: '30%' },
  {
    formField: true,
    field: 'orderReason',
    value: 'missing',
    label: 'fehlt',
    width: '7%',
    align: 'center',
    fieldType: 'CHECKBOX',
  },
  {
    formField: true,
    field: 'orderReason',
    value: 'damaged',
    label: 'beschädigt',
    width: '9%',
    align: 'center',
    fieldType: 'CHECKBOX',
  },
  {
    formField: true,
    field: 'orderReason',
    value: 'additionallyNeeded',
    label: `zusätzlich\nbenötigt`,
    align: 'center',
    width: '8%',
    fieldType: 'CHECKBOX',
  },
  {
    formField: true,
    field: 'partQuantity',
    value: 0,
    label: 'Anzahl',
    align: 'center',
    width: '11%',
    fieldType: 'INPUT_NUMBER',
  },
  {
    label: '',
    width: '7%',
    fieldType: 'DELETE_CONTROL',
    align: 'center',
  },
];

export {
  listColumns,
};
