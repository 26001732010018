import { StoreUtils } from '../../utils/StoreUtils';

const prefix = '[Player]';
const { createAction } = StoreUtils;

const TYPES = {
	SET_CURRENT_TIME: `${prefix} set-current-time`,
	SET_VOLUME: `${prefix} set-volume`,
	SET_AUDIO_TRACK_ID: `${prefix} set-audio-track-id`,
  SET_TEXT_TRACK_ID: `${prefix} set-text-track-id`,
	SET_VIDEO_TRACK_ID: `${prefix} set-video-track-id`,
	TOGGLE_FULLSCREEN_STATE: `${prefix} toggle-fullscreen-state`,
	TOGGLE_BURGER_MENU_STATE: `${prefix} toggle-burger-menu-state`,
	RESET_PLAYER_STATE: `${prefix} reset-player-state`,
	REFRESH_PLAYER: `${prefix} refresh-player`,
	RESET_CURRENT_TIME: `${prefix} reset-current-time`,
};

export const playerActions = {
	...TYPES,
	setCurrentTime: createAction(TYPES.SET_CURRENT_TIME, 'currentTime'),
  setAudioTrackId: createAction(TYPES.SET_AUDIO_TRACK_ID, 'audioTrackId'),
  setTextTrackId: createAction(TYPES.SET_TEXT_TRACK_ID, 'textTrackId'),
	setVideoTrackId: createAction(TYPES.SET_VIDEO_TRACK_ID, 'videoTrackId'),
	setVolume: createAction(TYPES.SET_VOLUME, 'volumeLevel'),
	toggleFullscreenState: createAction(TYPES.TOGGLE_FULLSCREEN_STATE, 'isPlayerFullscreen'),
	toggleBurgerMenuState: createAction(TYPES.TOGGLE_BURGER_MENU_STATE, 'isBurgerMenuOpen'),
	resetPlayerState: createAction(TYPES.RESET_PLAYER_STATE),
	refreshPlayer: createAction(TYPES.REFRESH_PLAYER, 'refreshState'),
	resetCurrentTime: createAction(TYPES.RESET_CURRENT_TIME),
};
