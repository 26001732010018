import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { SelectButton } from '../Buttons/SelectButton';

import { useStyles } from '../Select/styles';
import { sizesList } from './assets';

import { PARTS_SIZES } from '../../constants/parts';

const PartsSizeSelect = ({ selectedValue, disabled, setSelectValue, partsCount }) => {
  const classes = useStyles();

  return (
    <Grid container classes={{ root: classes.selectContainer }}>
      {sizesList.map(({ value, size, icon: Icon }) =>
        <SelectButton
          key={value}
          size={size}
          selected={selectedValue === value}
          value={value}
          icon={Icon}
          disabled={disabled || (value === PARTS_SIZES.ALL ? false : !partsCount[value])}
          setSelectValue={setSelectValue}
        >
        </SelectButton>,
      )}
    </Grid>
  );
};

PartsSizeSelect.propTypes = {
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string.isRequired,
  partsCount: PropTypes.shape({
    [PARTS_SIZES.BIG]: PropTypes.number,
    [PARTS_SIZES.SMALL]: PropTypes.number,
  }).isRequired,
  setSelectValue: PropTypes.func.isRequired,
};

export {
  PartsSizeSelect,
};