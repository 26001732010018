import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    padding: 0,
  },
  listItem: {
    position: 'relative',
    minHeight: '48px',
    padding: '0 20px 0 0',
    margin: '0 0 0 10px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 20px 0 0',
      margin: '0 0 0 20px',
    },
    [theme.breakpoints.up('extraLg')]: {
      padding: '0 30px 0 0',
      margin: '0 0 0 25px',
    },
    [theme.breakpoints.up('extraXl')]: {
      padding: '0 30px 0 0',
      margin: '0 0 0 25px',
    },
    '&:last-child': {
      padding: 0,
    },
    '&:first-child': {
      margin: '0 0 0 60px',
    },
  },
  listItemLink: {
    position: 'relative',
    fontSize: '1.125rem',
    fontWeight: 500,
    color: `${theme.colors.text.secondary}99`,
    textDecoration: 'none',
    '&::after': {
      content: 'attr(data-label)',
      display: 'block',
      fontWeight: 600,
      color: 'transparent',
      overflow: 'hidden',
      visibility: 'hidden',
      height: 0,
    }
  },
  active: {
    color: theme.colors.text.secondary,
    fontWeight: 600,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: '-15px',
      display: 'block',
      width: '28px',
      height: '5px',
      background: theme.colors.primary,
      borderRadius: '10px',
      transform: 'translateX(-50%)',
    },
  },
  disabled: {
    opacity: 0.4,
    '&:hover': {
      cursor: 'default',
    },
  },
  indicator: {
    position: 'absolute',
    right: '0',
    top: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    backgroundColor: '#fc8181',
    border: '1px solid #ffffff',
    borderRadius: '19px',
    fontSize: '0.938rem',
    fontWeight: '700',
    boxShadow: '0 5px 6px rgba(208, 118, 97, 0.2)',
    lineHeight: 1,
    [theme.breakpoints.up('extraLg')]: {
      width: '26px',
      height: '26px',
    },
  },
}));

export {
  useStyles,
};
