import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import { useCellStyles } from '../ListView/styles';
import { useStyles } from './styles';

const ListCell = ({ children, width, align }) => {
  const sharedListCellClasses = useCellStyles({ width, align });
  const classes = useStyles({ width });

  return (
    <Grid
      item
      classes={{
        root: clsx(sharedListCellClasses.listCell, classes.listCell),
      }}
    >
      {children}
    </Grid>
  );
};

ListCell.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  align: PropTypes.string,
};

export {
  ListCell,
};