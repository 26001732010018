import React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './styles';

const SearchBox = () => {
  const classes = useStyles();
  return (
    <Input
      classes={{ root: classes.inputBase, input: classes.input }}
      disabled
      placeholder="Wonach suchen Sie?"
      variant="outlined"
      startAdornment={
        <InputAdornment classes={{ root: classes.adornment }}>
          <SearchIcon />
        </InputAdornment>
      }
      disableUnderline
    />
  );
};

export { 
  SearchBox, 
};
