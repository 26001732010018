import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TABS } from '../../constants/tabs';
import { chaptersActions } from '../../redux/chapters/actions';
import { pdfActions } from '../../redux/pdf/actions';
import { playerActions } from '../../redux/player/actions';

import { MobileModal } from '../MobileModal';
import { MainHeader } from '../MainHeader';

import { productActions } from '../../redux/product/actions';

import { useStyles } from './style';
import { selectCompanyLogo } from '../../redux/product/selectors';

const PageContainer = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const companyLogo = useSelector(selectCompanyLogo);

  useEffect(() => {
    dispatch(productActions.fetchProduct(id));
  }, []);

  const goBack = useCallback((e) => {
    if(e.currentTarget !== e.target ) return;
    history.goBack();
  }, [])

  const onHomeButtonClick = useCallback(() => {
    dispatch(playerActions.resetPlayerState());
    dispatch(pdfActions.changeCurrentPage(0));
    dispatch(chaptersActions.changeCurrentChapter(0));
    dispatch(productActions.switchTab(TABS.PDF));
  }, [dispatch]);

  const refreshPlayer = useCallback(() => {
    dispatch(playerActions.refreshPlayer(true));
  }, [dispatch]);

  const matches = useMediaQuery(theme => `${theme.breakpoints.down('md')} and (orientation: portrait)`);

  return (
    <Grid className={classes.root}>
      <MainHeader id={id} companyLogo={companyLogo} onHomeClick={onHomeButtonClick} refreshPlayer={refreshPlayer}/>
        <Grid container className={classes.container} onClick={goBack}>
          <Grid container className={classes.contentWrapper}>
            {children}
          </Grid>
        </Grid>
      {matches && <MobileModal />}
    </Grid>
  );
};

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideSecondHeader: PropTypes.bool,
  pageBaseUrl: PropTypes.string,
};

export {
  PageContainer,
};