import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const Icon = ({ component: Icon, viewBox }) =>
  <SvgIcon component={Icon} viewBox={viewBox} fontSize="large" />;

Icon.propTypes = {
  component: PropTypes.elementType.isRequired,
  viewBox: PropTypes.string,
};

Icon.defaultProps = {
  viewBox: '0 0 32 32',
};

export {
  Icon,
};

