import { createSelector } from 'reselect';

const tools = ({ Tools }) => Tools;

const selectToolsList = createSelector(
  [tools],
  (tools) => ({
    items: tools.list,
    page: tools.page,
    hasMoreItems: (tools.page * tools.limit) < tools.totalItems,
    totalItems: tools.totalItems,
  }),
);

const selectLoadingState = createSelector(
  [tools],
  (tools) => tools.loading,
);

export {
  selectToolsList,
  selectLoadingState,
};
