import React  from 'react';
import {  useSelector } from 'react-redux';

import { selectProduct } from '../../redux/product/selectors';
import { ProductInfo } from '../../components/ProductTabs/ProductInfo';

const SummaryProductInfoView = () => {
  const product = useSelector(selectProduct);

  return <ProductInfo product={product} />;
};

export {
  SummaryProductInfoView,
};
