import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, matchPath, generatePath, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ProductPagesContainer } from '../../../components/ProductPagesContainer';
import { Player } from '../../../containers/Player';
import { TabsView } from '../../../containers/TabsView/TabsView';
import { Routes } from '../../../components/Routes';

import { CONTEXT_MENU_ROUTES, UI_ROUTES } from '../../../constants/routes';
import { productActions } from '../../../redux/product/actions';
import { usePageMode } from '../../../hooks/usePageMode';

const VideoPlayerPage = ({ routes }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isFullscreen = usePageMode();
  const { id } = useParams();

  useEffect(() => {
    const matchPeopleRoute = matchPath(pathname, {
      path: generatePath(`${UI_ROUTES.videoPlayer}${CONTEXT_MENU_ROUTES.people}`, { id }),
      exact: true,
    });

    dispatch(productActions.toggleSidebarState(!!matchPeopleRoute));
  }, [dispatch, pathname]);

  return (
    <ProductPagesContainer routes={routes} pageBaseUrl={UI_ROUTES.videoPlayer}>
      <ProductPagesContainer.Sidebar isFullscreen={isFullscreen}>
        <TabsView/>
      </ProductPagesContainer.Sidebar>
      <ProductPagesContainer.MainContent isFullscreen={isFullscreen}>
        <Player productId={id}><Routes routes={routes}/></Player>
      </ProductPagesContainer.MainContent>
    </ProductPagesContainer>
  );
};

VideoPlayerPage.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    components: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  })).isRequired,
};

export { VideoPlayerPage };