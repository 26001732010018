const CONTEXT_MENU_ROUTES = {
  parts: '/parts',
  tools: '/tools',
  people: '/people',
  constructionTime: '/construction-time',
  tips: '/tips',
  updates: '/updates',
};

const UI_ROUTES = {
  videoPlayer: '/product/:id',
  summary: '/product/:id/summary',
  spareParts: '/product/:id/spare-parts',
  moreProductsAndMedia: '/product/:id/more-products-and-media',
  moreAssemblyVideo: '/product/:id/more-assembly-videos',
  tour: '/product/:id/tour',
};

const API_ROUTES = {
  videos: '/videos',
  products: '/products',
};

const API_SUB_ROUTES = {
  tools: '/:id/tools',
  parts: '/:id/parts',
  chapters: '/:id/chapters',
  pdf: '/:id/documents',
  topics: '/:id/tip-topics',
  tips: '/:id/tips',
};

const API_URL = 'https://api-prod.watchandbuild.cloud:3000';

const PRODUCT_URL = 'https://mygermania.com/de/aufbauvideos.html';

const WB_URL = 'https://www.watchandbuild.com';

export {
  UI_ROUTES,
  CONTEXT_MENU_ROUTES,
  API_ROUTES,
  API_URL,
  API_SUB_ROUTES,
  PRODUCT_URL,
  WB_URL,
};