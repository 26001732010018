import { all, takeLatest, put, call } from 'redux-saga/effects';

import { PdfService } from '../../services/PdfService';
import { appActions } from '../app/actions';
import { pdfActions } from './actions';

function* pdfTimestampsReload({ payload: { productId, requestData } }) {
  try {
    const { data } = yield call(PdfService.get, productId, requestData);

    yield put(pdfActions.refreshPdfTimestamps(data));
  } catch (error) {
    yield put(appActions.setError(error));
  }
}

export default function* pdfSaga() {
  yield all([
    takeLatest(pdfActions.RELOAD_PDF_TIMESTAMPS, pdfTimestampsReload),
  ]);
}

export {
  pdfTimestampsReload,
};