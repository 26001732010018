import { useState, useEffect } from 'react';

import { iosDevices } from '../constants/device';

const useDiviceDetect = () => {
  const [isIos, setIsIos] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    setIsIos(iosDevices.includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document));
    // eslint-disable-next-line
    setIsSafari(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)));
    setIsChrome(!!window.chrome || !!(~window.navigator.userAgent.indexOf("Chrome")));
  }, [])

  return {
    isIos, 
    isSafari,
    isChrome,
  }
};

export {
  useDiviceDetect,
};
