class TipsUtils {
  static sortTipsArray(tips) {
    return tips.sort((tip1, tip2) => {
      return tip1.videoDocument.page >= tip2.videoDocument.page ? 1 : -1;
    });
  }

  static buildPayloadForRefreshTips(productId, requestData, topic) {
    const filters = { topic };
    const pdf = requestData.filters.pdf;
    const chapter = requestData.filters.chapter;

    if (pdf) filters.pdf = pdf;
    if (chapter) filters.chapter = chapter;

    return {
      payload: {
        productId,
        requestData: { filters },
      },
    };
  }
}

export {
  TipsUtils,
};
