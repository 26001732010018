import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute !important',
    boxShadow: 'none',
  },
  paper: {
    position: 'relative',
    width: 'calc(95% - 260px)',
    minWidth: '600px',
    maxWidth: '1655px',
    maxHeight: '1020px',
    padding: '20px',
    borderRadius: '20px',
    overflow: 'hidden',
    ['@media screen and  (max-height: 755px) and (min-width: 1281px) and (max-width: 1600px)']: {
      width: 'calc(75% - 260px)',
    },
    ['@media screen and  (max-height: 560px) and (min-width: 1212px)']: {
      padding: '15px',
      width: 'calc(75% - 260px)',
    },
    [`@media only screen
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape)`]: {
      width: 'calc(70% - 260px)',
    },
    [`@media only screen and (min-device-width: 375px) 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape)`]: {
      width: 'calc(75% - 260px)',
    },
    [`@media only screen and (min-device-width: 813px) 
    and (max-device-width: 1023px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape)`]: {
      width: 'calc(67% - 260px)',
    },
  },
  backDrop: {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  header: {
    fontSize: '2.118rem',
    margin: 0,
    marginBottom: '20px',
  },
}));

export {
  useStyles,
};