import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px',
    padding: '0',
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    background: '#F7F7FE',
    [theme.breakpoints.up('extraLg')]: {
      width: '40px',
      height: '40px',
      borderRadius: '10px',
    },
    [theme.breakpoints.up('extraXl')]: {
      width: '50px',
      height: '50px',
    },
  },
  disabled: {
    opacity: 0.5,
    backgroundColor: '#F7F7FE !important',
  },
  icon: {
    fontSize: '1.3rem',
    '&, & path': {
      fill: theme.colors.primary,
    },
    [theme.breakpoints.up('extraLg')]: {
      fontSize: '1.5rem',
    },
  },
}));

export {
  useStyles,
};