import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';

import { useStyles } from './styles';

const ProgressSlider = ({ currentTime, duration, buffered, audioSlider, onChange }) => {
  const classes = useStyles({ buffered, duration, audioSlider });

  return (
    <div >
      <Slider
        data-testid='progress-slider' 
        min={0}
        max={duration}
        value={currentTime}
        step={0.01}
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail,
        }}
        onChange={onChange}
      />
      {buffered === undefined && <span className={classes.buffered}></span>}
    </div>
  );
};

ProgressSlider.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  buffered: PropTypes.number,
  audioSlider: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export { 
  ProgressSlider, 
};
