import { makeStyles } from '@material-ui/core/styles';

const useTabsStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  indicator: {
    height: '5px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '28px',
      background: theme.colors.primary,
      borderRadius: '10px',
    },
  },
  disabled: {
    '& $indicator': {
      display: 'none',
    },
  },
}));

const useTabStyles = makeStyles((theme) => ({
  root: {
    minWidth: 'unset',
    padding: '0',
    margin: '0 30px 0 0',
    color: `${theme.colors.text.secondary}99`,
    fontSize: '1.125rem',
    fontWeight: 500,
    textTransform: 'none',
    [theme.breakpoints.up('extraLg')]: {
      margin: '0 40px 0 0',
    },
    [theme.breakpoints.up('xl')]: {
      margin: '0 60px 0 0',
    },
    '&:last-child': {
      margin: 0,
    },
  },
  selected: {
    color: theme.colors.text.secondary,
    fontWeight: 600,
  },
  disabled: {
    opacity: 1,
  },
  textColorInherit: {
    '&$selected$disabled': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 500,
    },
    '&$disabled': {
      opacity: 0.4,
    },
  },
  wrapped: {
    '&::after': {
      content: 'attr(data-label)',
      display: 'block',
      fontWeight: 600,
      color: 'transparent',
      overflow: 'hidden',
      visibility: 'hidden',
      height: 0,
    }
  }
}));

export {
  useTabsStyles,
  useTabStyles,
};