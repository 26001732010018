import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { ProductTabContent } from '../ProductTabContent';
import { SectionTitle } from '../../SectionTitle';
import { AspectRatioBox } from '../../AspectRatioBox';

import { useStyles } from './styles';

const ProductInfo = ({ product }) => {
  const title = <SectionTitle title="Produktinfo" />;
  const classes = useStyles();

  return (
    <ProductTabContent title={title}>
      <Grid container wrap="nowrap" classes={{ root: classes.productInfoContainer }}>
        <Grid container wrap="nowrap" alignItems="center" classes={{ root: classes.productInfoContent }}>
          <div className={classes.leftColumn}>
            <AspectRatioBox width={1} height={1}>
              <div className={classes.thumbnailHolder}>
                <img src={product.thumbnail} alt={product.name} />
              </div>
            </AspectRatioBox>
          </div>
          <Grid container direction="column" wrap="nowrap" classes={{ root: classes.rightColumn }}>
            <span className={classes.productProgram}>{product.program}</span>
            <p className={classes.productName}>{product.name} {product.productNumber}</p>
            <p className={classes.productDescription}>{product.description}</p>
          </Grid>
        </Grid>
      </Grid>
    </ProductTabContent>
  );
};

ProductInfo.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    productNumber: PropTypes.string,
    program: PropTypes.string,
    description: PropTypes.string,
    thumbnail: PropTypes.string,
  }).isRequired,
};

export {
  ProductInfo,
};
