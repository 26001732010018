import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    background: theme.colors.primary,
    color: '#fff',
    padding: '10px 15px',
    fontSize: '1.563rem',
    borderRadius: '15px',
    maxWidth: '170px',
    width: '100%',
    '&:hover': {
      background: theme.colors.primary,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '190px',
    },
    [theme.breakpoints.up('extraLg')]: {
      padding: '15px 20px',
      maxWidth: '200px',
      minHeight: '52px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '235px',
      padding: '16px 25px',
      minHeight: '58px',
    },
  },
  disabled: {
    backgroundColor: `${theme.colors.secondary}66 !important`,
    color: '#fff !important',
  },
  buttonLabel: {
    fontWeight: 700,
    display: 'inline-block',
    lineHeight: '0.75',
  },
  itemsAmount: {
    lineHeight: 1,
    fontWeight: 500,
  },
  svgIcon: {
    fontSize: '1.3rem',
    '& svg, & svg path': {
      fill: theme.colors.primary,
    },
  },
}));

export {
  useStyles,
};
