import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: '#000',
    width: '50px',
    height: '50px',
    padding: '7px',
    borderRadius: '10px',
    background: '#f2f2f2',
    [theme.breakpoints.down('lg')]: {
      width: '40px',
      height: '40px',
    },
    '&:hover': {
      background: '#f2f2f2',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  svgIcon: {
    fontSize: '1.5rem',
  },
  text: {
    fontWeight: 'normal',
    fontSize: '0.875rem',
  },
}));

export {
    useStyles,
};