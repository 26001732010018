import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { useCellStyles } from './styles';

const ListCell = ({ value, width, maxWidth, align, onClick }) => {
  const classes = useCellStyles({ width, maxWidth, align });

  return <Grid item classes={{ root: classes.listCell }} onClick={onClick}>{value}</Grid>;
};

ListCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  align: PropTypes.oneOf(['center', 'flex-end']),
  onClick: PropTypes.func,
};

export {
  ListCell,
};