import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputBase: {
    width: '450px',
    background: theme.colors.background.chapter,
    border: '1px solid #e7e9f2',
    borderRadius: '10px',
    padding: '7px 0',
    color: '#787c84',
  },
  input: {
    '&::placeholder': {
      color: '#5f656e',
      lineHeight: '1.4rem',
    },
  },
  adornment: {
    padding: '0 15px',
  },
}));

export {
  useStyles,
};
