import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listViewContainer: {
    position: 'relative',
    flexGrow: 0,
    flexBasis: '100%',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    height: '100%',
    '&:after': {
      content: props => props.isModalOpen ? '" "' : '',
      position: 'absolute',
      top: '0',
      left: '20px',
      right: '20px',
      bottom: '0',
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
    },
  },
  listWrapper: {
    height: 'calc(100% - 45px)',
    [theme.breakpoints.up('extraLg')]: {
      height: 'calc(100% - 70px)',
    },
    '& ul': {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0',
      listStyle: 'none',
      margin: '0 20px',
    },
  },
  listHeader: {
    width: 'calc(100% - 2.56px)',
    margin: 0,
    padding: '0 20px 5px',
    fontWeight: 600,
    '& > div': {
      fontSize: '1.125rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 700,
      '&:first-child': {
        paddingLeft: '20px',
      },
    },
    [theme.breakpoints.up('extraLg')]: {
      padding: '10px 20px 15px',
    },
  },
  listItem: {
    width: '100%',
    height: '100%',
    minHeight: '70px',
    margin: '0 0 15px',
    background: theme.colors.background.primary,
    borderRadius: '20px',
    cursor: props => props.onItemClick ? 'pointer' : 'auto',
    '& > div:first-child': {
      paddingLeft: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '90px',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: '110px',
    },
  },
}));

const useCellStyles = makeStyles(theme => ({
  listCell: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => props.align || 'flex-start',
    flexBasis: props => props.width || 'auto',
    width: props => props.width || 'auto',
    maxWidth: props => props.maxWidth || 'unset',
    textAlign: props => props.align === 'center' ? 'center' : 'left',
    fontSize: '1.25rem',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.375rem',
    },
    [theme.breakpoints.up('extraLg')]: {
      fontSize: '1.563rem',
    },
  },
}));

export {
  useCellStyles,
  useStyles,
};