import { all, fork } from 'redux-saga/effects';

import toolsSaga from './tools/saga';
import productSaga from './product/saga';
import chaptersSaga from './chapters/saga';
import pdfSaga from './pdf/saga';
import partsSaga from './parts/saga';
import tipsSaga from './tips/saga';
import productsSaga from './products/saga';
import sparePartsSaga from './spareParts/saga';

const sagas = [
  fork(toolsSaga),
  fork(productSaga),
  fork(chaptersSaga),
  fork(pdfSaga),
  fork(partsSaga),
  fork(tipsSaga),
  fork(productsSaga),
  fork(sparePartsSaga),
];

export default function* rootSaga() {
  yield all(sagas);
}