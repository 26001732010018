import { makeStyles } from '@material-ui/core/styles';

const useFullscreenStyles = makeStyles((theme) => ({
  pdfFullscreen: {
    height: '100%',
    '& > button': {
      position: 'fixed',
      top: 'calc((100vh + 100px) / 2)',
      zIndex: 2,
      ['@media (pointer: coarse) and (max-height: 900px)']: {
        top: 'calc((100vh + 80px) / 2)',
      },
      ['@media (pointer: fine) and (min-aspect-ratio: 19/9)']: {
        top: 'calc((100vh + 80px) / 2)',
      },
      '&:first-child': {
        left: '3%',
        transform: 'translate3d(0, -50%, 0)',
        '-webkit-transform': 'translate3d(0, -50%, 0)',
      },
      '&:last-child': {
        right: '3%',
        transform: 'translate3d(0, -50%, 0)',
        '-webkit-transform': 'translate3d(0, -50%, 0)',
      },
      ['@media (pointer: fine) and (min-aspect-ratio: 17/10) and (min-height: 576px) and (min-width: 1124px) and (max-width: 1439px)']: {
        '&:first-child': {
          left: 'calc((100vw - 92vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
        '&:last-child': {
          right: 'calc((100vw - 92vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
      },
      ['@media (pointer: fine) and (min-aspect-ratio: 17/9) and (min-height: 576px)  and (min-width: 1124px) and (max-width: 1439px)']: {
        '&:first-child': {
          left: 'calc((100vw - 90vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
        '&:last-child': {
          right: 'calc((100vw - 90vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
      },
      ['@media (pointer: fine) and (min-aspect-ratio: 17/8) and (min-height: 576px) and (min-width: 1124px) and (max-width: 1439px)']: {
        '&:first-child': {
          left: 'calc((100vw - 88vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
        '&:last-child': {
          right: 'calc((100vw - 88vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
      },
      ['@media (pointer: fine) and (min-aspect-ratio: 18/9) and (min-height: 576px) and (min-width: 1124px) and (max-width: 1439px)']: {
        '&:first-child': {
          left: 'calc((100vw - 86vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
        '&:last-child': {
          right: 'calc((100vw - 86vw) / 2 + 2.3%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
      },
      '@media (min-width: 1920px)': {
        '&:first-child': {
          left: 'calc((100vw - 1920px) / 2 + 2.5%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
        '&:last-child': {
          right: 'calc((100vw - 1920px) / 2 + 2.5%)',
          transform: 'translate3d(0, -50%, 0)',
          '-webkit-transform': 'translate3d(0, -50%, 0)',
        },
      },

      ['@media screen and  (max-height: 755px) and (min-width: 1281px) and (max-width: 1600px)']: {
        '&:first-child': {
          left: '11%',
        },
        '&:last-child': {
          right: '11%'
        },
      },
      ['@media screen and  (max-height: 560px) and (min-width: 1212px)']: {
        '&:first-child': {
          left: '11%',
        },
        '&:last-child': {
          right: '11%'
        },
      },
    },
    '& *': {
      userSelect: 'none',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
    }
  },
  pageWrapper: {
    position: 'relative',
    height: '100%',
  },
  imgHolder: {
    position: 'relative',
    maxWidth: '60%',
    width: '100%',
    height: '100%',
    margin: '0 50px 0',
    border: `10px solid ${theme.colors.outline}`,
    borderRadius: '30px',
    overflow: 'hidden',
    '& img': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '70%',
    }
  },
  zoomInButton: {
    position: 'fixed',
    background: theme.colors.background.quaternary,
    bottom: '35px',
  },
}));

export {
  useFullscreenStyles,
};