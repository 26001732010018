import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'calc(50% - 20px)',
    maxHeight: '100%',
    marginLeft: '20px',
    backgroundColor: theme.colors.background.chapter,
    borderRadius: '20px',
    padding: '40px',
    fontSize: '1.6rem',
    '& > div': {
      marginBottom: '25px',
      [theme.breakpoints.up('xl')]: {
        marginBottom: '18px',
      },
      [theme.breakpoints.up('extraXl')]: {
        marginBottom: '30px',
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: '14px',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '5px',
      },
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '28px',
      fontSize: '1.35rem',
    },
    [theme.breakpoints.up('extraXl')]: {
      padding: '40px',
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '22px',
      fontSize: '1.35rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '10px',
      fontSize: '1.125rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      fontSize: '1.075rem',
    },
  },
  label: {
    color: '#747880',
    fontSize: '1.15rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '5px',
    }
  },
  name: {
    fontWeight: 'bold',
  },
  duration: {
    fontWeight: 'bold',
  },
}));

export {
  useStyles,
};
