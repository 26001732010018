import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Player } from '../../components/Player';

import { playerActions } from '../../redux/player/actions';
import { chaptersActions } from '../../redux/chapters/actions';
import { selectChapters } from '../../redux/chapters/selectors';
import {
  selectCurrentPageNumber,
  selectPdfTimestamps,
  selectZoomViewState,
} from '../../redux/pdf/selectors';
import { pdfActions } from '../../redux/pdf/actions';
import {
  selectRefreshState,
  selectAudioTrackId,
  selectFullscreenState,
  selectTextTrackId,
  selectVideoTrackId,
  selectCurrentTime,
  selectVolume,
  selectBurgerMenuState,
} from '../../redux/player/selectors';
import { selectSubtitles, selectVideos } from '../../redux/product/selectors';

const PlayerView = () => {
  const dispatch = useDispatch();
  const { currentChapter, chaptersLength, allChapters } = useSelector(selectChapters);
  const page = useSelector(selectCurrentPageNumber);
  const timestamps = useSelector(selectPdfTimestamps);
  const isPlayerFullscreen = useSelector(selectFullscreenState);
  const isZoomView = useSelector(selectZoomViewState);
  const volume = useSelector(selectVolume);
  const videos = useSelector(selectVideos);
  const subtitles = useSelector(selectSubtitles);
  const lastCurrentTime = useSelector(selectCurrentTime);
  const lastTextTrackId = useSelector(selectTextTrackId);
  const lastVideoTrackId = useSelector(selectVideoTrackId);
  const lastAudioTrackId = useSelector(selectAudioTrackId);
  const refreshState = useSelector(selectRefreshState);
  const isBurgerMenuOpen = useSelector(selectBurgerMenuState);

  const onPlayerDispose = useCallback(
    (currentTime, volumeLevel, currentTextTrackId, currentVideoTrackId, currentAudioTrackId) => {
      dispatch(playerActions.setCurrentTime(currentTime));
      dispatch(playerActions.setVolume(volumeLevel));
      dispatch(playerActions.setTextTrackId(currentTextTrackId || ''));
      dispatch(playerActions.setVideoTrackId(currentVideoTrackId || ''));
      dispatch(playerActions.setAudioTrackId(currentAudioTrackId || ''));
    },
    [dispatch],
  );

  const deleteTextTrackId = useCallback(
    () => {
      dispatch(playerActions.setTextTrackId(''));
    },
    [dispatch],
  );

  const deleteVideoTrackId = useCallback(
    () => {
      dispatch(playerActions.setVideoTrackId(''));
    },
    [dispatch],
  );

  const deleteAudioTrackId = useCallback(
    () => {
      dispatch(playerActions.setAudioTrackId(''));
    },
    [dispatch],
  );

  const onChapterChange = useCallback(
    (chapter) => {
      dispatch(chaptersActions.changeCurrentChapter(chapter));
    },
    [dispatch],
  );

  const onPdfChange = useCallback(
    (page) => {
      dispatch(pdfActions.changeCurrentPage(page));
    },
    [dispatch],
  );

  const onFullscreenChange = useCallback(
    (state) => {
      dispatch(playerActions.toggleFullscreenState(state));
    },
    [dispatch],
  );

  const refreshPlayer = useCallback(() => {
    dispatch(playerActions.refreshPlayer(false));
  }, [dispatch]);

  const props = {
    onPlayerDispose,
    onChapterChange,
    onPdfChange,
    onFullscreenChange,
    deleteTextTrackId,
    deleteVideoTrackId,
    deleteAudioTrackId,
    refreshPlayer,
    currentChapter,
    chaptersLength,
    allChapters,
    page,
    timestamps,
    isPlayerFullscreen,
    isZoomView,
    videos,
    subtitles,
    volume,
    lastCurrentTime,
    lastTextTrackId,
    lastVideoTrackId,
    lastAudioTrackId,
    refreshState,
    isBurgerMenuOpen,
  };

  if (videos.length === 0) return null; 

  return <Player {...props}/>;
};

export {
  PlayerView,
};
